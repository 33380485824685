import classNames from "classnames";
import React from "react";
classNames;
export interface IColor {
    name: string;
    url: string;
    isActive: boolean;
    imageAltText: string;
    imageUrl: string;
}

export const ColorSelection = ({ colors, colorText }: { colors: IColor[]; colorText: string }) => {
    const activeColor = colors.find((color) => color.isActive);

    return (
        <>
            <p className="ColorText">
                {colorText} <strong>{activeColor.name}</strong>
            </p>
            <ul className="ColorList">
                {colors.map((color) => {
                    return (
                        <li
                            key={color.name}
                            className={classNames("ColorList__Item", { "ColorList__Item--isActive": color.isActive })}
                        >
                            <a href={color.url}>
                                <img src={color.imageUrl} alt={color.imageAltText}></img>
                            </a>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};
