import * as React from "react";
import { View } from "./CollectionSearch";
import classNames from "classnames";
import { AssetHelper } from "../../ReactComponents/assetHelper";
import { CollectionSearchPageTranslations } from "./CollectionSearchPageTranslations.csharp";
const Fade = require("react-reveal/Fade");

export interface ObjectCardProps {
    title: string;
    medium: string;
    yearLabel: string;
    imageUrl: string;
    invNo: string;
    primaryMedia: string;
    view: View;
    url: string;
    linkToObjectPage: string;
    isVirtualObject: boolean;
    translations: CollectionSearchPageTranslations;
    classification: string;
    classificationEn: string;
    isVirtualObjectList: boolean;
}

function buildImageUrl(primaryMedia) {
    let imageUrl = AssetHelper.images.placeholder;
    if (primaryMedia !== null && primaryMedia.split("/").length > 4) {
        var id = primaryMedia.split("/")[4];
        imageUrl = `https://emuseum.oslo.kommune.no/apis/iiif/image/v2/${id}/full/,400/0/default.jpg`;
    }
    return imageUrl;
}

function getTitleForVirtualObject(classification: string[], virtualMotif: string, title: string) {
    if (classification[0].toLowerCase().includes("grafikk") && virtualMotif.toLowerCase().includes("motiv")) {
        return classification[0] + virtualMotif + title;
    } else if (classification[0].toLowerCase().includes("graphic")) {
        return classification[1] + virtualMotif + title;
    } else {
        return title;
    }
}

export const CollectionSearchObjectCard = (props: ObjectCardProps) => {
    const {
        invNo,
        primaryMedia,
        title,
        medium,
        yearLabel,
        isVirtualObject,
        linkToObjectPage,
        translations,
        classification,
        classificationEn,
        isVirtualObjectList,
    } = props;
    let classView =
        props.view == View.Gridview
            ? "CollectionSearchPage__ObjectCard__Gridview"
            : "CollectionSearchPage__ObjectCard__Listview";

    if (isVirtualObjectList) classView = "CollectionSearchPage__ObjectCard__Gridview";

    return (
        <Fade duration={800} delay={10} distance={"60px"} bottom>
            <div className={classNames(classView)}>
                <a href={`${linkToObjectPage}${invNo}`}>
                    <div className="CollectionSearchPage__ObjectCard__image__container">
                        <img src={buildImageUrl(primaryMedia)} alt={title} />
                    </div>
                    <div className="CollectionSearchPage__ObjectCard__text__container">
                        <h3 className="CollectionSearchPage__ObjectCard__title">
                            {!isVirtualObject
                                ? title
                                : getTitleForVirtualObject(
                                      [classification, classificationEn],
                                      translations.virtualMotif,
                                      title
                                  )}
                        </h3>
                        <p className="CollectionSearchPage__ObjectCard__text">{medium}</p>
                        <p className="CollectionSearchPage__ObjectCard__text">{yearLabel}</p>
                    </div>
                </a>
            </div>
        </Fade>
    );
};
