import { graphQlRequestBodyString } from "../../Product/graphQlRequestBodyString";
export const initCart = async (
    currentLanguage = '',
    accessToken,
    graphQlEndpoint,
    cartId,
    setCartId: (setCartId: string) => void,
    addCartIdEndpoint,
    endpointGuestCartNoId,
    endpointMemberCartItems,
    setTickets: (setTickets: any) => void,
    setCart: (setCart: any) => void,
    setIsLoading?: (setIsLoading: boolean) => void,
    setNumberOfProducts?: (setNumberOfProducts: any) => void
) => {
    const guestHeaders = {
        "content-type": "application/json",
        "store": currentLanguage,
    };
    const memberHeaders = {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "store": currentLanguage,
    };

    const guestHeadersRest = {
        "content-type": "application/json",
    };
    const memberHeadersRest = {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
    };

    let tempCartId;

    setIsLoading && setIsLoading(true);

    (async () => {
        const endpoint = graphQlEndpoint;
        let graphqlQuery;
        if (accessToken) {
            graphqlQuery = {
                operationName: "Cart",
                query: `{
                            customerCart {
                                id,
                                ${graphQlRequestBodyString}
                            }
                        }`,
                variables: {},
            };
            const options = {
                method: "POST",
                headers: accessToken ? memberHeaders : guestHeaders,
                body: JSON.stringify(graphqlQuery),
            };

            const response = await fetch(endpoint, options);
            const data = await response.json();
            setCartId(data.data.customerCart.id);
            tempCartId = data.data.customerCart.id;
        } else {
            if (cartId === null) {
                graphqlQuery = {
                    operationName: "Cart",
                    query: `mutation {
                                createEmptyCart
                            }`,
                    variables: {},
                };
                const options = {
                    method: "POST",
                    headers: accessToken ? memberHeaders : guestHeaders,
                    body: JSON.stringify(graphqlQuery),
                };

                const response = await fetch(endpoint, options);
                const data = await response.json();
                setCartId(data.data.createEmptyCart);
                tempCartId = data.data.createEmptyCart;
            }
            // If we already had a guest cart when rendering the page, use that one instead.
            else {
                setCartId(cartId);
                tempCartId = cartId;
            }

            // Get tickets
            // Tickets does not exist in graphQl so we need to get them from rest API seperatly
            graphqlQuery = {
                operationName: "Cart",
                query: `{
                            cart(cart_id: "${tempCartId}") {
                                ${graphQlRequestBodyString}
                            }
                        }`,
                variables: {},
            };
        }

        const graphQLProductsOptions = {
            method: "POST",
            headers: accessToken ? memberHeaders : guestHeaders,
            body: JSON.stringify(graphqlQuery),
        };

        const response = await fetch(endpoint, graphQLProductsOptions);
        const data = await response.json();

        // if we're logged in and a cart was created, use the cartId received
        if (cartId === null) {
            // // add cartId to session in backend
            const addCartId = {
                cartId: tempCartId,
            };
            const options2 = {
                method: "POST",
                headers: accessToken ? memberHeadersRest : guestHeadersRest,
                body: JSON.stringify(addCartId),
            };

            const response2 = await fetch(addCartIdEndpoint, options2);
            console.log("Added cartid to be", response2);
        }

        const cartWithoutTickets = accessToken
            ? data.data.customerCart.items.filter((item) => item !== null)
            : data.data.cart.items.filter((item) => item !== null);
        setCart(cartWithoutTickets);

        let resolvedCartItemsEndpoint = accessToken
            ? `${endpointMemberCartItems}`
            : `${endpointGuestCartNoId}/${tempCartId}/items`;

        const restOptions = {
            method: "GET",
            headers: accessToken ? memberHeadersRest : guestHeadersRest,
        };

        // Tickets are fetched from REST API and we resolve auth/not authed variants in options and endpoints
        const restResponse = await fetch(`${resolvedCartItemsEndpoint}`, restOptions);
        const restData = await restResponse.json();
        const ticketsWithoutProducts = restData.filter((item) => item.product_type === "ticket" || item.product_type === "membership_renew" || item.product_type === "membership" || item.product_type === "voucher");
        setTickets(ticketsWithoutProducts);
        setNumberOfProducts && setNumberOfProducts(restData.reduce((partialSum, a) => partialSum + a.qty, 0));
        setIsLoading && setIsLoading(false);
    })();
};


