import * as React from "react";
import {RefObject} from "react";
import {PopupProps} from "./PopupProps.csharp";

export class Popup extends React.Component<PopupProps> {
    private readonly buttonCloseRef: RefObject<HTMLDivElement>;
    private popup: HTMLElement | null;

    constructor(props: Readonly<PopupProps>) {
        super(props);

        this.buttonCloseRef = React.createRef();
        this.dismissPopup = this.dismissPopup.bind(this);
        this.popup = null;
    }

    componentDidMount(): void {
        window.addEventListener('DOMContentLoaded', () => {
            this.popup = document.getElementById(this.props.popupId) as HTMLElement;

            if (!this.popup) return;
            const popup = this.popup;

            this.checkCookie(popup);
            Popup.setPosition(popup);

            this.popup.querySelectorAll("button[type=submit], a.dismiss").forEach(button => {
                button.addEventListener('click', () => {
                    this.dismissPopup();
                });
            });
            
            window.addEventListener('resize', () => {
                Popup.setPosition(popup);
            });
        });
    }

    render() {
        return (
            <div ref={this.buttonCloseRef} className="Popup__close">
                <button onClick={this.dismissPopup}>
                        <span className="visuallyHidden">
                            {this.props.buttonText}
                        </span>
                </button>
            </div>
        );
    }

    private dismissPopup() : void {
        if (this.popup) {
            this.popup.style.display = "none";
            this.setCookie();
        }
    }
    
    private setCookie(): void {
        let expirationDate = new Date;
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        document.cookie = `${this.props.popupId}=closed;expires=${expirationDate.toUTCString()};path=/`;
    }

    private checkCookie(popup: HTMLElement) {
        let matcher = new RegExp(this.props.popupId, "g");
        let cookieIsSet = matcher.test(document.cookie);

        if (!cookieIsSet) {
            popup.style.display = 'block';
        }
    }

    private static setPosition(popup: HTMLElement) {
        const popupInner = popup.querySelector('.Popup__inner') as HTMLDivElement;
        const header = document.querySelector(".NavigationContainer, .NavigationSmall") as HTMLElement;
        if (!popupInner || !header) return;

        let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        let headerHeight = header ? header.offsetHeight : 145;

        if (popupInner.offsetHeight > viewportHeight - headerHeight) {
            popup.style.position = 'absolute';
            popup.style.top = `${headerHeight + 20}px`;
        } else {
            popup.style.position = 'fixed';
            popup.style.top = `${viewportHeight - popupInner.offsetHeight - headerHeight / 2}px`;
        }
    }
}