import React from "react";

export type CategoryUrl = {
    url: string;
    categoryName: string;
};
export interface CategoryListProps {
    categoryUrlList: CategoryUrl[];
    ariaLabelText?: string;
}

export const CategoryList = ({ categoryUrlList, ariaLabelText }: CategoryListProps) => {
    return (
        <nav className="CategoryPage__linkedListContainer" aria-label={ariaLabelText}>
            <ul className="CategoryPage__linkedList">
                {categoryUrlList.map((urlObject) => {
                    if (urlObject.categoryName && urlObject.url)
                        return (
                            <li key={urlObject.categoryName} className="CategoryPage__linkedListItem">
                                <a className="CategoryPage__linkedListItemLink" href={urlObject.url}>{urlObject.categoryName}</a>
                            </li>
                        );
                })}
            </ul>
        </nav>
    );
};
