import React from "react";
import { CartItems } from "../../../Pages/Ecommerce/Shared/CartItems/CartItems";

interface CartProps {
    cartId?: string;
    title: string;
    sizeLabel: string;
    totalLabel: string;
    toCartButtonText: string;
    toCartUrl: string;
    errorMessage: string;
    graphQlEndpoint: string;
    endpointGuestCartItems: string;
    endpointMemberCartItems: string;
    endpointGuestCartNoId: string;
    accessToken: string;
    currencyFormat: string;
    productCount?: number;
    currentLanguage?: string,
    products: {
        id: number;
        uid: string;
        product: {
            sku: string;
            stock_status: string;
            name: string;
            small_image: {
                label: string;
                url: string;
            };
            price_range: {
                maximum_price: {
                    final_price: {
                        currency: string;
                        value: number;
                    };
                };
            };
        };
        prices: {
            row_total_including_tax: {
                value: number;
            };
        };
        configurable_options?: { value_label: string }[];
        quantity: number;
    }[];
    tickets: {
        item_id: number;
        sku: string;
        name: string;
        qty: number;
        price: number;
        product_type: string;
        extension_attributes?: {
            ticket_purchase: {
                admissions: {
                    start_utc_timestamp: number;
                    end_utc_timestamp: number;
                }[];
            };
        };
    }[];
    setCartIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setCart?: React.Dispatch<React.SetStateAction<any[]>>;
    setNumberOfProducts?: React.Dispatch<React.SetStateAction<number>>;
    setTickets: React.Dispatch<React.SetStateAction<any[]>>;
}

export const Cart = ({
    cartId,
    title,
    sizeLabel,
    totalLabel,
    toCartButtonText,
    toCartUrl,
    errorMessage,
    products,
    productCount,
    setCartIsOpen,
    graphQlEndpoint,
    setCart,
    setNumberOfProducts,
    tickets,
    setTickets,
    endpointGuestCartItems,
    endpointMemberCartItems,
    endpointGuestCartNoId,
    accessToken,
    currencyFormat,
    currentLanguage = ''
}: CartProps) => {
    const totalPriceProducts = products.reduce(
        (partialSum, a) => Math.round(partialSum + a.prices.row_total_including_tax.value),
        0
    );

    const totalPriceTickets = tickets.reduce(
        (partialSum, a) =>
            Math.round(
                a.product_type === "voucher" ? partialSum + a.price * a.qty : partialSum + a.price * 1.12 * a.qty
            ),
        0
    );

    const totalPrice = totalPriceProducts + totalPriceTickets;

    const formattedPrice = totalPrice && currencyFormat.replace("price", totalPrice.toString());

    return (
        <div className="Cart">
            <div className="Cart__top-panel">
                <h2 className="Cart__title">
                    {title}
                    <span className="Cart__title-counter">{productCount}</span>
                </h2>
                <button
                    aria-label="Lukk handlevogn"
                    className="Cart__close-button"
                    onClick={() => setCartIsOpen(false)}
                ></button>
            </div>

            <CartItems
                cartId={cartId}
                sizeLabel={sizeLabel}
                errorMessage={errorMessage}
                products={products}
                setCartIsOpen={setCartIsOpen}
                graphQlEndpoint={graphQlEndpoint}
                setCart={setCart}
                setNumberOfProducts={setNumberOfProducts}
                tickets={tickets}
                setTickets={setTickets}
                endpointGuestCartItems={endpointGuestCartItems}
                endpointMemberCartItems={endpointMemberCartItems}
                accessToken={accessToken}
                currencyFormat={currencyFormat}
                currentLanguage={currentLanguage}
            />

            <div className="Cart__bottom-panel">
                <div className="Cart__total-price">
                    <span>{totalLabel}</span>
                    <span>{formattedPrice}</span>
                </div>
                <a className="Cart__link button--primary button--forward button--black" href={toCartUrl}>
                    {toCartButtonText}
                </a>
            </div>
        </div>
    );
};
