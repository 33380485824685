import React, { useState, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import { CountryCode } from "libphonenumber-js";
import { Form } from "../../Shared/Form/Form";
import { checkEmailIsValid } from "../../Shared/utils/checkEmailIsValid";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import cn from "classnames";

export interface IEditPersonalInfo {
    memberData: {
        city: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        postalCode: string;
        streetAddress: string;
    };
    firstNameLabel?: string;
    firstNamePlaceholder?: string;
    firstNameErrorValidationText?: string;
    lastNameLabel?: string;
    lastNamePlaceholder?: string;
    lastNameErrorValidationText?: string;
    emailLabel?: string;
    emailPlaceholder?: string;
    emailErrorValidationText?: string;
    phoneNumberLabel?: string;
    phoneNumberPlaceholder?: string;
    phoneNumberErrorValidationText?: string;
    postalCodeLabel?: string;
    postalCodePlaceholder?: string;
    postalCodeErrorValidationText?: string;
    streetAddressLabel?: string;
    streetAddressPlaceholder?: string;
    streetAddressErrorValidationText?: string;
    cityLabel?: string;
    cityPlaceholder?: string;
    cityErrorValidationText?: string;
    title?: string;
    saveButtonText?: string;
    onFormSubmit?: (val: any) => any;
    isLoading: boolean;
    showErrorMessage: boolean;
    serverErrorMessage?: string;
    endpointSetAddress?: string;
    className?: string;
    newsletterSignup?: any;
    onlyTicketsInCart?: boolean;
    hideNewsletterText?: boolean;
    isCheckout?: boolean;
}

export const EditPersonalInfo = ({
    memberData,
    firstNameLabel,
    firstNamePlaceholder,
    firstNameErrorValidationText,
    lastNameLabel,
    lastNamePlaceholder,
    lastNameErrorValidationText,
    emailLabel,
    emailPlaceholder,
    emailErrorValidationText,
    phoneNumberLabel,
    phoneNumberPlaceholder,
    phoneNumberErrorValidationText,
    postalCodeLabel,
    postalCodePlaceholder,
    postalCodeErrorValidationText,
    streetAddressLabel,
    streetAddressPlaceholder,
    streetAddressErrorValidationText,
    cityLabel,
    cityPlaceholder,
    cityErrorValidationText,
    title,
    saveButtonText,
    onFormSubmit = () => {},
    isLoading = false,
    showErrorMessage = false,
    serverErrorMessage,
    endpointSetAddress,
    className,
    newsletterSignup,
    onlyTicketsInCart,
    hideNewsletterText,
    isCheckout = false,
}: IEditPersonalInfo) => {
    Object.freeze(memberData);
    const [countryCode, setCountryCode] = useState<CountryCode>("NO");
    const [firstNameState, setFirstName] = useState(memberData.firstName);
    const [lastNameState, setLastName] = useState(memberData.lastName);
    const [emailState, setEmail] = useState(memberData.email);
    const [phoneNumberState, setPhoneNumber] = useState(memberData.phoneNumber);
    const [cityState, setCity] = useState(memberData.city);
    const [postalCodeState, setPostalCode] = useState(memberData.postalCode);
    const [streetAddressState, setStreetAddress] = useState(memberData.streetAddress);
    const [firstNameIsValid, setFirstNameIsValid] = useState(memberData.firstName ? true : false);
    const [lastNameIsValid, setLastNameIsValid] = useState(memberData.lastName ? true : false);
    const [emailIsValid, setEmailIsValid] = useState(memberData.email ? true : false);
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(memberData.phoneNumber ? true : false);
    const [cityIsValid, setCityIsValid] = useState(memberData.city ? true : false);
    const [postalCodeIsValid, setPostalCodeIsValid] = useState(memberData.postalCode ? true : false);
    const [streetAddressIsValid, setStreetAddressIsValid] = useState(memberData.streetAddress ? true : false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);
    const [showFirstNameError, setShowFirstNameError] = useState(false);
    const [showLastNameError, setShowLastNameError] = useState(false);
    const [showStreetAddressError, setShowStreetAddressError] = useState(false);
    const [showPostalCodeError, setPostalCodeError] = useState(false);
    const [showCityError, setShowCityError] = useState(false);

    const setNameError = (
        name: string,
        setIsNameValid: Dispatch<SetStateAction<boolean>>,
        setShowError: Dispatch<SetStateAction<boolean>>
    ) => {
        setShowError(true);
        const validCharactersRegex = /^[a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ\- ]+$/;
        const isValid = validCharactersRegex.test(name);
        setIsNameValid(name !== "" && isValid);
    };

    const setInputStringError = (
        inputString: string,
        setIsInputstringValid: Dispatch<SetStateAction<boolean>>,
        setShowError: Dispatch<SetStateAction<boolean>>
    ) => {
        setShowError(true);
        setIsInputstringValid(inputString !== "" && !!inputString.replace(/\s+/g, ""));
    };

    const postalCodeValidation = (postalCode: string,
        setIsPostalCodeValid: Dispatch<SetStateAction<boolean>>,
        setShowError: Dispatch<SetStateAction<boolean>>
    ) => {
        setShowError(true);
        const isValid = /^\d{4}$/.test(postalCode);
        setIsPostalCodeValid(isValid);
    };

    const emailValidation = (email) => {
        setShowEmailError(true);
        setEmailIsValid(checkEmailIsValid(email));
    };

    const phoneValidation = (phone) => {
        setShowPhoneNumberError(true);
        setPhoneNumberIsValid(isValidPhoneNumber(phone ? phone : "", countryCode));
    };

    const disableForm =
        emailIsValid &&
        phoneNumberIsValid &&
        firstNameIsValid &&
        lastNameIsValid &&
        cityIsValid &&
        postalCodeIsValid &&
        streetAddressIsValid
            ? false
            : true;

    const disableFormTickets = emailIsValid ? false : true;

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();

        onFormSubmit({
            firstName: firstNameState,
            lastName: lastNameState,
            email: emailState,
            phoneNumber: phoneNumberState,
            streetAddress: streetAddressState,
            postalCode: postalCodeState,
            city: cityState,
        });
    };

    const onlyTicketInputs = [
        {
            type: "email" as const,
            label: emailLabel,
            isRequired: true,
            value: emailState,
            onChange: (v) => {
                setEmail(v);
                emailValidation(v);
            },
            isBig: true,
            autoComplete: "email",
            placeholder: emailPlaceholder,
            errorValidation: showEmailError && !emailIsValid,
            errorValidationText: emailErrorValidationText,
            showError: showEmailError,
        },
    ];

    const inputs = [
        {
            type: "text" as const,
            label: firstNameLabel,
            isRequired: true,
            value: firstNameState,
            isBig: false,
            autoComplete: "given-name",
            placeholder: firstNamePlaceholder,
            onChange: (v) => {
                setFirstName(v);
                setNameError(v, setFirstNameIsValid, setShowFirstNameError);
            },
            errorValidation: showFirstNameError && !firstNameIsValid,
            errorValidationText: firstNameErrorValidationText,
            showError: showFirstNameError,
        },

        {
            type: "text" as const,
            label: lastNameLabel,
            isRequired: true,
            value: lastNameState,
            onChange: (v) => {
                setLastName(v);
                setNameError(v, setLastNameIsValid, setShowLastNameError);
            },
            isBig: false,
            autoComplete: "family-name",
            placeholder: lastNamePlaceholder,
            errorValidation: showLastNameError && !lastNameIsValid,
            errorValidationText: lastNameErrorValidationText,
            showError: showLastNameError,
        },
        {
            type: "email" as const,
            label: emailLabel,
            isRequired: true,
            value: emailState,
            onChange: (v) => {
                setEmail(v);
                emailValidation(v);
            },
            isBig: true,
            autoComplete: "email",
            placeholder: emailPlaceholder,
            errorValidation: showEmailError && !emailIsValid,
            errorValidationText: emailErrorValidationText,
            showError: showEmailError,
            isDisabled: isCheckout ? false : true,
        },
        {
            type: "tel" as const,
            label: phoneNumberLabel,
            isRequired: true,
            value: phoneNumberState,
            isBig: true,
            placeholder: phoneNumberPlaceholder,
            onChange: (v) => {
                setPhoneNumber(v);
                phoneValidation(v);
            },
            autoComplete: "On",
            setCountryCode: setCountryCode,
            errorValidation: showPhoneNumberError && !phoneNumberIsValid,
            errorValidationText: phoneNumberErrorValidationText,
            showError: showPhoneNumberError,
        },
        {
            type: "text" as const,
            label: streetAddressLabel,
            isRequired: true,
            value: streetAddressState,
            onChange: (v) => {
                setStreetAddress(v);
                setInputStringError(v, setStreetAddressIsValid, setShowStreetAddressError);
            },
            isBig: true,
            autoComplete: "street-address",
            placeholder: streetAddressPlaceholder,
            errorValidation: showStreetAddressError && !streetAddressIsValid,
            errorValidationText: streetAddressErrorValidationText,
            showError: showStreetAddressError,
        },

        {
            type: "text" as const,
            label: postalCodeLabel,
            isRequired: true,
            value: postalCodeState,
            onChange: (v) => {
                setPostalCode(v);
                postalCodeValidation(v, setPostalCodeIsValid, setPostalCodeError);
            },
            isBig: false,
            autoComplete: "postal-code",
            placeholder: postalCodePlaceholder,
            errorValidation: showPostalCodeError && !postalCodeIsValid,
            errorValidationText: postalCodeErrorValidationText,
            showError: showPostalCodeError,
        },
        {
            type: "text" as const,
            label: cityLabel,
            isRequired: true,
            value: cityState,
            onChange: (v) => {
                setCity(v);
                setInputStringError(v, setCityIsValid, setShowCityError);
            },
            isBig: false,
            autoComplete: "home city",
            placeholder: cityPlaceholder,
            errorValidation: showCityError && !cityIsValid,
            errorValidationText: cityErrorValidationText,
            showError: showCityError,
        },
    ];

    return (
        <div className={cn("EditPersonalInfo", className)}>
            <div className="EditPersonalInfo__container">
                {title && <h2 className="EditPersonalInfo__title">{title}</h2>}

                <Form
                    inputs={onlyTicketsInCart ? onlyTicketInputs : inputs}
                    onSubmit={onSubmit}
                    autoComplete={"on"}
                    confirmButtonText={saveButtonText}
                    disabled={onlyTicketsInCart ? disableFormTickets : disableForm}
                    isLoading={isLoading}
                    showErrorMessage={showErrorMessage}
                    errorMessage={serverErrorMessage}
                    className="Form--in-edit-personal-info"
                    hideNewsletterText={hideNewsletterText}
                    newsletterSignup={newsletterSignup}
                />
            </div>
        </div>
    );
};
