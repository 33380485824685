import * as React from "react";
import { ChangeEvent } from "react";
import { ContentSearchHit } from "./Service/ContentSearchHit.csharp";
import { PaginationModel } from "../Base/PaginationModel.csharp";
import { SearchResultsProps } from "./SearchResultsProps.csharp";

interface SearchResultsState {
  newSearchTerm: string;
  hasNext: boolean;
  nextPage: number;
  results: ContentSearchHit[];
}

export class SearchPage extends React.Component<
  SearchResultsProps,
  SearchResultsState
> {
  constructor(props: SearchResultsProps) {
    super(props);

    if (!props.model) {
      this.state = {
        newSearchTerm: "",
        hasNext: false,
        results: [],
        nextPage: 2
      };
    } else {
      this.state = {
        newSearchTerm: props.model.searchTerm,
        hasNext: props.model.result.hasNext,
        results: props.model.result.items,
        nextPage: 2
      };
    }

    this.updateInput = this.updateInput.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
  }

  render() {
    const translations = this.props.translations;
    const results = this.state.results.length ? (
      <div className="SearchResult--results">
        <p>
          {translations.resultsCount1}
          {this.state.results.length} {translations.resultsCount2}
          {this.props.model.result.totalCount} {translations.resultsCount3}
          {this.props.model.searchTerm} {translations.resultsCount4}
        </p>
        <div>
          {this.state.results.map((hit, i) => {
            return (
                <a key={i} href={hit.url}>
                  <h3>{hit.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: hit.excerpt }} />
                </a>
            );
          })}
        </div>
      </div>
    ) : (
      <></>
    );

    const nextPageButton = this.state.hasNext ? (
      <button className="button--secondary button--expand" onClick={this.loadNextPage}>{translations.showMore}</button>
    ) : (
      <></>
    );

    return (
      <div className="grid main-grid">
        <div className="SearchResult--container">
          <form method="GET">
            <label>
              <strong>{translations.whatYouLookFor}</strong>
            </label>
            <div className="SearchResult__input">
              <input
                type="text"
                tabIndex={1}
                name="q"
                value={(this.props.model && this.state.newSearchTerm) || ''}
                onChange={this.updateInput}
                placeholder={translations.typeWordsToSearch}
              />
              <button type="submit"><span className="visuallyHidden">{translations.search}</span></button>
            </div>
          </form>
          {results}
          {nextPageButton}
        </div>
      </div>
    );
  }

  private updateInput(e: ChangeEvent<HTMLInputElement>) {
    this.setState({ newSearchTerm: e.target.value });
  }

  private loadNextPage() {
    const url =
      this.props.searchUrl +
      `?q=${encodeURIComponent(this.state.newSearchTerm)}&pageNumber=${
        this.state.nextPage
      }&pageSize=${this.props.model.result.pageSize}`;

    fetch(url, {
      method: "GET",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(result => result.json())
      .then(result => result as PaginationModel<ContentSearchHit>)
      .then(result => {
        this.setState({
          results: this.state.results.concat(result.items),
          hasNext: result.hasNext,
          nextPage: result.pageNumber + 1
        });
      });
  }
}
