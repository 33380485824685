import cn from "classnames";
import React, { MutableRefObject, useState, useEffect } from "react";

export interface IHamburger {
    className?: string;
    isExpanded: boolean;
    ariaLabel?: string;
    toggleMenu?: () => any;
    forwardRef?: MutableRefObject<null | HTMLButtonElement>;
}

export const Hamburger = ({ className, toggleMenu = () => {}, isExpanded, ariaLabel, forwardRef }: IHamburger) => {

    const onButtonClick = () => {
        toggleMenu();
    };

    useEffect(() => {
        const body = document.body;
        const bodyClass = 'no-scroll';
        if (isExpanded) {
          body.classList.add(bodyClass);
        } else {
          body.classList.remove(bodyClass);
        }
      }, [isExpanded]);

    return (
        <button
            ref={forwardRef}
            aria-label={ariaLabel}
            aria-expanded={isExpanded}
            aria-controls="menu-content"
            aria-haspopup="true"
            onClick={onButtonClick}

            className={cn("Hamburger", className, {
                'Hamburger--active': isExpanded
            })}
        >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </button>
    );
};
