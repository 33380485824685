import * as React from "react";
import { Slider, Ticks, Tracks, Handles, Rail } from "react-compound-slider";
import { Tick, Track, Handle, SliderRail } from "./SliderParts/";
import { CollectionSearchRangeInput } from "./CollectionSearchRangeInput";

export interface CollectionSearchRangeProps {
    domain: [number, number];
    handleYearChange: any;
    filterYearRange: [number, number];
}

export interface CollectionSearchRangeState {
    years: [number, number];
}

export class CollectionSearchRange extends React.Component<CollectionSearchRangeProps, CollectionSearchRangeState> {
    constructor(props: CollectionSearchRangeProps) {
        super(props);

        this.state = {
            years: props.domain,
        };
        this.handleYearsSlideEnd = this.handleYearsSlideEnd.bind(this);
        this.handleYearsChange = this.handleYearsChange.bind(this);
    }

    private handleYearsSlideEnd(years: [number, number]) {
        this.props.handleYearChange(years);
        this.setState({ years: years });
    }

    private handleYearsChange(years: [number, number], fromInput: boolean = false) {
        if (fromInput) {
            this.props.handleYearChange(years);
        }
        this.setState({ years: years });
    }

    componentDidMount() {
        this.setState({ years: this.props.filterYearRange });
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.filterYearRange[0] !== this.props.filterYearRange[0] ||
            prevProps.filterYearRange[1] !== this.props.filterYearRange[1]
        ) {
            this.setState({ years: this.props.filterYearRange });
        }
    }

    render() {
        const { domain } = this.props;
        const [min, max] = domain;
        const { years } = this.state;

        return (
            <div className="CollectionSearchRange">
                <form className="CollectionSearchRange__form">
                    <CollectionSearchRangeInput
                        left={true}
                        minYear={min}
                        maxYear={max}
                        years={years}
                        onChange={this.handleYearsChange}
                    />
                    <h3 className="CollectionSearchRange__separator">-</h3>
                    <CollectionSearchRangeInput
                        left={false}
                        minYear={min}
                        maxYear={max}
                        years={years}
                        onChange={this.handleYearsChange}
                    />
                </form>
                <Slider
                    mode={2}
                    step={1}
                    domain={domain}
                    values={years}
                    onSlideEnd={this.handleYearsSlideEnd}
                    onUpdate={this.handleYearsChange}
                    rootStyle={{ position: "relative" }}
                    className="CollectionSearchRange__slider"
                >
                    <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
                    <Handles>
                        {({ handles, getHandleProps }) => (
                            <div>
                                {handles.map((handle) => (
                                    <Handle
                                        key={handle.id}
                                        handle={handle}
                                        domain={domain}
                                        getHandleProps={getHandleProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Handles>
                    <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                            <div>
                                {tracks.map(({ id, source, target }) => (
                                    <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                                ))}
                            </div>
                        )}
                    </Tracks>
                    <Ticks values={domain}>
                        {({ ticks }) => (
                            <div>
                                {ticks.map((tick) => (
                                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                                ))}
                            </div>
                        )}
                    </Ticks>
                </Slider>
            </div>
        );
    }
}
