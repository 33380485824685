import * as React from "react";
import { LanguageViewModel } from "./LanguageViewModel.csharp";
import classNames from "classnames";
import { LanguageSwitcherProps } from "./LanguageSwitcherProps.csharp";
import { useEffect } from "react";

export interface LanguageSwitcherPropsWithClassName extends LanguageSwitcherProps {
    className?: string;
}

export const LanguageSwitcher = (props: LanguageSwitcherPropsWithClassName) => {
    const { model, isOpeningTop, translations } = props;
    const { availableLanguages, currentLanguage } = model;
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const node = React.useRef<HTMLDivElement>(null);

    const handleClickOutside = (e: any) => {
        if (node.current!.contains(e.target)) {
            return;
        }
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div ref={node} className={classNames("LanguageSwitcher", props.className)}>
            {availableLanguages.map((language) => (
                <Language
                    key={language.code}
                    {...language}
                    code={language.code.toLowerCase() === "no" ? "en" : "no"}
                    lang={language.code.toLowerCase()}
                    isActive={language.code.toLowerCase() === currentLanguage.toLowerCase()}
                />
            ))}
        </div>
    );
};

export class Language extends React.Component<LanguageViewModel & { isActive: boolean, lang: string }> {
    constructor(props: LanguageViewModel & { isActive: boolean, lang: string  }) {
        super(props);
        this.setLanguage = this.setLanguage.bind(this);
    }

    render() {
        const { text, isActive, lang } = this.props;

        const link = (
            <a
                className="LanguageSwitcher__language--link"
                href={this.props.currentPageUrl}
                tabIndex={isActive ? -1 : null}
                lang={lang}
            >
                {text}
            </a>
        );

        return (
            !isActive && (
                <div
                    className={classNames("LanguageSwitcher__language", {
                        "LanguageSwitcher__language--active": isActive,
                    })}
                    onClick={this.setLanguage}
                >
                    {link}
                </div>
            )
        );
    }

    private setLanguage() {
        document.cookie = `epslanguage=${this.props.code};path=/`;
    }
}
