import React, { useState, useEffect, useRef } from "react";
import { ResetPasswordProps } from "./ResetPasswordProps.csharp";
import { NextButton } from "../Shared/NextButton/NextButton";
import { ErrorMessage } from "../Shared/ErrorMessage";
import { Input } from "../Shared/Input/Input";
import { postRequest } from "../Shared/api/post";
export const ResetPasswordPage = (props: ResetPasswordProps) => {
    const [confirmed, setConfirmed] = useState(false);
    const [passwordInput, setPasswordInput] = useState("");
    const [repeatPasswordInput, setRepeatPasswordInput] = useState("");
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [showMatchError, setShowMatchError] = useState(false);
    const [passwordIsMatching, setPasswordIsMatching] = useState(false);
    const [isError, setIsError] = useState(false);
    const ref = useRef<HTMLDivElement>();
    const checkIfPasswordMatch = () => {
        setPasswordIsMatching(passwordInput === repeatPasswordInput);
    };

    const disabled = passwordInput === "" || !passwordIsMatching || !passwordIsValid;

    const resetPassword = async () => {
        try {
            if (!disabled) {
                const data = JSON.stringify({
                    customerId: props.customerId,
                    token: props.token,
                    newPassword: passwordInput,
                });

                const response = await postRequest(props.endpoint, data, null);
                if (!response.ok) throw new Error("Her gikk det galt");
                setConfirmed(true);
            }
        } catch (e) {
            console.log(e);
            setIsError(true);
        }
    };

    const checkPasswordIsValid = () => {
        const lowerCase = new RegExp("^(?=.*[a-z])");
        const upperCase = new RegExp("(?=.*[A-Z])");
        const number = new RegExp("(?=.*[0-9])");
        const specialLetters = new RegExp("(?=.*[!@#$%^&*])");

        setPasswordIsValid(
            passwordInput.length >= 8 &&
                (lowerCase.test(passwordInput) ? 1 : 0) +
                    (upperCase.test(passwordInput) ? 1 : 0) +
                    (number.test(passwordInput) ? 1 : 0) +
                    (specialLetters.test(passwordInput) ? 1 : 0) >=
                    3
        );
    };

    useEffect(() => {
        checkPasswordIsValid();
    }, [passwordInput]);

    useEffect(() => {
        if (passwordInput !== '' && repeatPasswordInput !== '') {
            checkIfPasswordMatch();
        }
    }, [passwordInput, repeatPasswordInput]);

    useEffect(() => {
        if (ref?.current) {
            setTimeout(() => ref.current.classList.add("animateIn"), 750);
        }
    }, [confirmed, ref]);

    return (
        <div className="ResetPassword">
            <div className="ResetPassword__Container">
                <section className="TicketPage__card animateIn">
                    <h1 className="ResetPassword__Title">{props.title}</h1>
                    <p>{props.intro}</p>
                    <form autoComplete="on">
                        <Input
                            type="password"
                            label={props.passwordLabel}
                            onChange={(value: string) => {
                                setPasswordInput(value);
                            }}
                            isRequired={true}
                            value={passwordInput}
                            isBig={true}
                            placeholder={props.passwordPlaceholder}
                            autoComplete={"new-password"}
                            onBlur={() => {
                                if (passwordInput !== "") {
                                    setShowPasswordError(true);
                                }
                            }}
                            errorValidation={showPasswordError && passwordInput !== "" && !passwordIsValid}
                            errorValidationText={props.validationText}
                            showError={showPasswordError}
                        />
                        <Input
                            type="password"
                            label={props.repeatPasswordLabel}
                            onChange={(value: string) => {
                                setRepeatPasswordInput(value);
                            }}
                            isRequired={true}
                            value={repeatPasswordInput}
                            isBig={true}
                            placeholder={props.repeatPasswordPlaceholder}
                            autoComplete={"new-password"}
                            onBlur={() => {
                                setShowMatchError(true);
                            }}
                            errorValidation={showMatchError && !passwordIsMatching}
                            errorValidationText={props.matchValidationText}
                            showError={showMatchError}
                        />
                        <NextButton
                            showTwo={false}
                            mainButtonClick={() => {
                                resetPassword();
                            }}
                            mainButtonText={props.confirmButtonText}
                            disabled={disabled}
                        />
                        <div className="server-error">{isError && <ErrorMessage text={props.errorMessage} />}</div>
                    </form>
                </section>
                {confirmed && (
                    <section className="TicketPage__card" ref={ref}>
                        <h2 className="ResetPassword__Title">{props.confirmedTitle}</h2>
                        <p>{props.confirmedIntro}</p>
                        <div className="button-container">
                            <button
                                className="button--primary button--forward button--red"
                                onClick={() => {
                                    window.location.href = props.memberPageLink;
                                }}
                            >
                                {props.memberPageLinkText}
                            </button>
                        </div>
                    </section>
                )}
            </div>
        </div>
    );
};
