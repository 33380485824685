import React, { SetStateAction, Dispatch } from "react";
import classNames from "classnames";
export interface IRadio {
    title: string;
    code: string;
    disabled?: boolean;
}

export const RadioList = ({
    radioList,
    setValue,
    value,
    className,
}: {
    radioList: IRadio[];
    setValue: Dispatch<SetStateAction<string>>;
    value: string;
    className?: string;
}) => {
    return (
        <div className={classNames("RadioList", className)}>
            {radioList.map((radio) => (
                <div
                    className={classNames("Radio__Container Radio__Container--" + radio.code, {
                        "Radio__Container--chosen": value === radio.code,
                        "Radio__Container--disabled": radio.disabled,
                    })}
                    key={radio.code}
                >
                    <input
                        type="radio"
                        id={radio.code}
                        name={radio.code}
                        value={radio.code}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        checked={value === radio.code}
                        disabled={radio.disabled}
                    />
                    <label htmlFor={radio.code}>{radio.title}</label>
                </div>
            ))}
        </div>
    );
};
