import {ExhibitionsArchiveProps} from "./ExhibitionsArchiveProps.csharp";
import * as React from "react";
import {CardsList} from "../../../Blocks/CardsList/CardsList";
import {CardsListViewModel} from "../../../Blocks/CardsList/CardsListViewModel.csharp";
import {ExhibitionsArchivePaginationModel} from "./ExhibitionsArchivePaginationModel.csharp";
import {RangeSelector} from "../../../ReactComponents/rangeSelector/rangeSelector";

export class ExhibitionsArchive extends React.Component<ExhibitionsArchiveProps, 
    {selectedYears: number[], isLoading: boolean, yearsData: CardsListViewModel[]}> {
    private readonly currentYear: number;
    
    constructor(props: ExhibitionsArchiveProps) {
        super(props);
        this.currentYear = new Date().getFullYear();

        this.state = {
            selectedYears: [props.model.startYear, props.model.endYear],
            isLoading: false,
            yearsData: props.model.data
        };
            
        this.onDateChange = this.onDateChange.bind(this);
    }
    
    render() {
        const {minYear} = this.props.model;
        const {defaultProfile, featuredProfile, translations, yearInterval} = this.props;
        
        const datePicker = <RangeSelector 
                value={this.state.selectedYears}
                maxValue={this.currentYear}
                minValue={minYear}
                step={yearInterval}
                onChange={this.onDateChange}/>;
        
        const cardsLists = this.state.yearsData.map(year => <CardsList
                                                                key={year.title}
                                                                defaultProfile={defaultProfile}
                                                                featuredProfile={featuredProfile}
                                                                translations={translations.cardsList}
                                                                model={year}/>);
        return <section className="ExhibitionsArchive">
            {datePicker}
            {cardsLists.length 
                ? <>{cardsLists}{datePicker}</> 
                : <div className="ExhibitionsArchive__noResults">{translations.noResults}</div>}
        </section>
    }
    
    onDateChange(newValue: number[]) {
        const url = `${this.props.archiveUrl}?archiveId=${this.props.archiveId}&startYear=${newValue[0]}&endYear=${newValue[1]}`;

        this.setState({isLoading: true});

        fetch(url)
            .then(response => response.json())
            .then(response => {
                    const results = response as ExhibitionsArchivePaginationModel;

                    this.setState({
                        yearsData: results.data,
                        isLoading: false,
                        selectedYears: newValue
                    });
                },
                failure => this.setState({isLoading: false}));
    }
}