import React, { ReactNode } from "react";
import classNames from "classnames";

import { Carousel } from "../../Shared/Carousel/Carousel";

interface ICarouselBlock {
    title: string;
    buttonText?: string;
    buttonUrl?: string;
    bgColor: string; //"green" | "blue" | "red" | "white" | "black";
    displayData: { key: string; data: ReactNode }[];
    previousSlideAriaLabel: string;
    nextSlideAriaLabel: string;
    className?: string;
}

export const CarouselBlock = ({
    title,
    buttonText,
    buttonUrl,
    bgColor,
    displayData,
    previousSlideAriaLabel,
    nextSlideAriaLabel,
    className
}: ICarouselBlock) => {
    if (displayData.length === 0) return null;
    return (
        <div className="CarouselBlock__outer">
        <div className={classNames("CarouselBlock", `CarouselBlock--${bgColor}`, className)}>
            <div className="CarouselBlock__Wrapper">
                <div className="CarouselBlock__Title__Container">
                    <h2
                        className={classNames(
                            "CarouselBlock__Title",
                            `CarouselBlock__Title--${bgColor === "green" ? "gold" : "pink"}`
                        )}
                    >
                        {title}
                    </h2>
                </div>
                <div className="CarouselBlock__Items">
                    <Carousel
                        previousSlideAriaLabel={previousSlideAriaLabel}
                        nextSlideAriaLabel={nextSlideAriaLabel}
                        spaceBetweenSlides={20}
                        displayData={displayData.map((item, index) => ({
                            key: "item" + index,
                            data: item.data,
                        }))}
                    />
                </div>

                {buttonUrl && buttonText && (
                    <div className="CarouselBlock__Button">
                        <a className="button--secondary button--white" href={buttonUrl}>
                            {buttonText}
                        </a>
                    </div>
                )}
            </div>
        </div>
        </div>
    );
};
