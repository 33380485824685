export const initDiscounts = async (accessToken: string, paymentInfoGuestEndpoint: string, paymentInfoMemberEndpoint: string, options: any, setCurrentDiscount: any, setDiscount: any, discount: {label: string, value: string},setCurrentVoucher: any, setVoucher: any, voucher: {label: string, value: string} ,setCurrentDiscountCoupon: any, setDiscountCoupon: any, discountCoupon: {label: string, value: string}, setSubTotal: any, grandTotalWithoutShipping: any) => {
    const response = await fetch(
        `${accessToken ? paymentInfoMemberEndpoint : paymentInfoGuestEndpoint}`,
        options
    );
    const data = await response.json();

    const discountTitle = data.totals.total_segments.find((item) => item.code === "discount")?.title;
    const discountCouponTitle = data.totals.total_segments.find((item) => item.code === "discount_coupon")?.title;
    
    setCurrentDiscount(data.totals.coupon_code ? data.totals.coupon_code : discountTitle);
    setCurrentDiscountCoupon(discountCouponTitle)

    const hasDiscounts = data.totals.total_segments.find((item) => item.code === "discount") !== undefined;
    const hasDiscountCoupon = data.totals.total_segments.find((item) => item.code === "discount_coupon") !== undefined;
    
        setDiscount({
            ...discount,
            label: discountTitle,
            value: data.totals.total_segments.find((item) => item.code === "discount")?.value,
        });  
        
        setDiscountCoupon({
            ...discountCoupon,
            label: discountCouponTitle,
            value: data.totals.total_segments.find((item) => item.code === "discount_coupon")?.value,
        });


    const hasVoucher = data.totals.total_segments.find((item) => item.code === "voucher") !== undefined;
    if (hasVoucher) {
        setCurrentVoucher(data.totals.total_segments.find((item) => item.code === "voucher").title);
        setVoucher({
            ...voucher,
            label: data.totals.total_segments.find((item) => item.code === "voucher").title,
            value: data.totals.total_segments.find((item) => item.code === "voucher").value,
        });
    } else {
        setVoucher({
            ...voucher,
            label: "",
            value: "",
        });
    }

    setSubTotal(data.totals.total_segments.find((item) => item.code === "subtotal").value);
    grandTotalWithoutShipping(data);
   
     
};


