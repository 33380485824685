import classNames from "classnames";
import React, { MutableRefObject, useState, useEffect } from "react";

interface ITicketMember {
    header: string;
    row: boolean;
    onChange: any;
    values: string[];
    forwardRef?: MutableRefObject<null | HTMLDivElement>;
}

export const ToggleButton = ({ header, onChange, row, values, forwardRef }: ITicketMember) => {
    const [selected, setSelected] = useState<string>();

    useEffect(() => {
        if (selected) onChange(selected);
    }, [selected]);

    return (
        <div className="ToggleButton__container" ref={forwardRef}>
            <p className="ToggleButton__label">{header}</p>
            <div className={classNames({ "Togglebutton_wrapper--row": row, Togglebutton_wrapper: !row })}>
                {values.map((value) => {
                    return (
                        <button
                            className={classNames("Togglebutton", { "button--selected": selected == value })}
                            onClick={() => setSelected(value)}
                            key={value}
                        >
                            {value}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};
