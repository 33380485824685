import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import cn from "classnames";

interface IContextualMenuItem {
    linkItem: {
        text?: string;
        url?: string;
    };
    isActive: boolean;
    addNumberOfTicketsToText: boolean;
}

export interface IContextualMenu {
    contextualMenuItems: IContextualMenuItem[];
    numberOfTickets: number;
    showMemberCardText: string;
    showMemberCard?: (val: any) => any;
    mobileToggleButtonText?: string;
}

const ContextualMenu = ({
    contextualMenuItems,
    numberOfTickets,
    showMemberCardText,
    showMemberCard = () => {},
    mobileToggleButtonText,
}: IContextualMenu) => {
    const [isExpandedInMobile, setIsExpandedInMobile] = useState(false);

    const handleClick = () => {
        showMemberCard(true);
    };

    const toggleMobileMenu = () => {
        setIsExpandedInMobile(!isExpandedInMobile);
    };

    return (
        <div className="ContextualMenu wrapper">
            <div className="ContextualMenu__inner">
                <div className="ContextualMenu__container">
                    <button
                        className={cn("ContextualMenu__toggle-button", {
                            "ContextualMenu__toggle-button--active": isExpandedInMobile,
                        })}
                        onClick={toggleMobileMenu}
                        aria-expanded={isExpandedInMobile}
                    >
                        {mobileToggleButtonText}

                    </button>
                    <div
                        className={cn("ContextualMenu__mobile-expandable-content", {
                            "ContextualMenu__mobile-expandable-content--expanded-in-mobile": isExpandedInMobile,
                        })}
                    >
                        {contextualMenuItems && (
                            <ul className="ContextualMenu__items">
                                {contextualMenuItems.map((item, index) => (
                                    <li key={index} className="ContextualMenu__item">
                                        <a
                                            href={item.linkItem.url}
                                            className={cn("ContextualMenu__item-link", {
                                                "ContextualMenu__item-link--active": item.isActive,
                                            })}
                                        >
                                            {item.linkItem.text}
                                            {item.addNumberOfTicketsToText && numberOfTickets && numberOfTickets > 0 ? (
                                                <span>({numberOfTickets})</span>
                                            ) : null}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                        {showMemberCardText && (
                            <button
                                className="ContextualMenu__item-link ContextualMenu__show-card-button"
                                onClick={handleClick}
                            >
                                {showMemberCardText}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContextualMenu;
