import React from "react";
import { IMemberTicket } from "../../Shared/MemberTicket/MemberTicket";
import MemberTicket from "../../Shared/MemberTicket/MemberTicket";
import { Carousel } from "../../Shared/Carousel/Carousel";

interface IMemberTickets {
    date?: string;
    title?: string;
    dateLabel: string;
    timeLabel: string;
    numberOfTicketText: string;
    prevButtonText: string;
    nextButtonText: string;
    tickets: IMemberTicket[];
    showFutureOnly?: boolean;
}

const MemberTickets = ({
    date,
    title,
    dateLabel,
    timeLabel,
    numberOfTicketText,
    prevButtonText,
    nextButtonText,
    tickets,
    showFutureOnly = true
}: IMemberTickets) => {
    const removeItemsBeforeToday = (items) => {
        const today = new Date();
        if (items === null || items.length < 1)
            return null;

        return items.filter((item) => new Date(item.startDateTime) >= today);
    };

    const onlyFutureTickets = removeItemsBeforeToday(tickets);

    const ticketsToShow = showFutureOnly ? onlyFutureTickets : tickets;

    return ticketsToShow && ticketsToShow.length > 0 ? (
        <div className="MemberTickets__wrapper">
            <div className="MemberTickets ">
                {title && <h2 className="MemberTickets__title">
                    {date && <div>{date}</div>}
                    {title}
                </h2>}
                {ticketsToShow && ticketsToShow.length > 0 && (
                    <div className="MemberTickets__items">
                        <Carousel
                        isMemberTickets
                            previousSlideAriaLabel={prevButtonText}
                            nextSlideAriaLabel={nextButtonText}
                            spaceBetweenSlides={30}
                            displayData={ticketsToShow.map((ticket, index) => ({
                                key: "ticket" + index,
                                data: (
                                    <MemberTicket
                                        {...ticket}
                                        dateLabel={dateLabel}
                                        timeLabel={timeLabel}
                                        slideNumber={index + 1}
                                        slidesNumber={ticketsToShow.length}
                                        numberOfTicketText={numberOfTicketText}
                                    />
                                ),
                            }))}
                        />
                    </div>
                )}
            </div>
        </div>
    ) : null;
};

export default MemberTickets;
