const CollapseAccordionElement = (el): any => {
    const toggler = el;
    const toggleClass = 'isOpen';
    const panel = toggler.nextElementSibling;
    const parent = toggler.parentElement;
    const isExpanded = toggler.getAttribute("aria-expanded");
    const closeAnotherItems = false;

    const collapseElement = (toggler, panel, parent) => {
        console.log('collapse');
        toggler.setAttribute("aria-expanded", "false");
        panel.setAttribute("aria-hidden", "true");
        parent.classList.remove(toggleClass);
    }

    const expandElement = (toggler, panel, parent) => {
        console.log('expand');
        toggler.setAttribute("aria-expanded", "true");
        panel.setAttribute("aria-hidden", "false");
        parent.classList.add(toggleClass);
    }
    
    const collapseAll = () => {
        const openElements = toggler.parentElement.parentElement.querySelectorAll("." + toggleClass);
        openElements.forEach((el) => {
            const toggler = el.querySelector('[aria-expanded]');
            const panel = el.querySelector('[aria-hidden]');
            collapseElement(toggler, panel, el);
        });
    }

    closeAnotherItems && collapseAll();

    if(isExpanded == 'true'){
        collapseElement(toggler, panel, parent);
    }else{
        expandElement(toggler, panel, parent);
    }
};

global.CollapseAccordionElement = CollapseAccordionElement;
