import * as Stickyfill from "stickyfilljs";

function initializeStickyFill() {
    const elements: NodeListOf<HTMLElement> = document.querySelectorAll('.sticky') || null;
    if (elements) {
        Stickyfill.add(Array.prototype.slice.call(elements));
    }
}

function initializeObjectFitPolyfill() {
    let images = document.querySelectorAll("img");

    images.forEach(img => {
            // @ts-ignore
            img.onload = function () {
                // @ts-ignore
                window.objectFitPolyfill();
            }
        }
    )
}

document.addEventListener('DOMContentLoaded', function () {
    initializeStickyFill();
    initializeObjectFitPolyfill();
}, false);

