import { postRequest } from "./post";
import { Dispatch, SetStateAction } from "react";

export const vippsPayment = async (
    graphQlEndpoint: string,
    endpointVippsInitUrl: string,
    vippsCartId: string,
    cartId: string,
    emailInput: string,
    setShowErrorMessage: Dispatch<SetStateAction<boolean>>,
    vippsCallbackUrl: string,
    clearLoader: () => void,
    accessToken?: string
) => {
    try {
        if (accessToken === null) {
            const querySetEmail = JSON.stringify({
                query: `mutation {
                setGuestEmailOnCart(
                    input: {
                        cart_id: "${cartId}",
                        email: "${emailInput}"
                    })
                    {
                        cart {
                            available_payment_methods {
                                code
                                title
                            }
                        }
                    }
                }
            `,
            });

            const responseSetEmail = await postRequest(graphQlEndpoint, querySetEmail, accessToken);
            const dataSetEmail = await responseSetEmail.json();
            if (!responseSetEmail) throw new Error(dataSetEmail);

            console.log(dataSetEmail.data); // data
            console.log(dataSetEmail.errors); //
            const queryInitPayment = JSON.stringify({
                query: `mutation {
                    vippsInitPayment(
                        input: {
                            cart_id: "${cartId}",
                            fallback_url: "${vippsCallbackUrl}"
                        })
                        {
                            url
                        }
                    }            `,
            });

            const responseInitPayment = await postRequest(graphQlEndpoint, queryInitPayment, accessToken);
            const dataInitPayment = await responseInitPayment.json();
            if (!responseInitPayment.ok) throw new Error(dataInitPayment);
            console.log(dataInitPayment.data); // data
            console.log(dataInitPayment.errors); //

            if (!dataInitPayment?.data?.vippsInitPayment?.url) setShowErrorMessage(true);

            window.location.href = dataInitPayment.data.vippsInitPayment.url;
        }
        else {
            const requestVippsInitBody = {
                cartId: vippsCartId,
                fallback_url: vippsCallbackUrl
            };

            var bearer = "Bearer " + accessToken;
            const responseInitPayment = await fetch(endpointVippsInitUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer
                },
                body: JSON.stringify(requestVippsInitBody)
            });
            if (responseInitPayment.ok) {
                const dataInitPayment = await responseInitPayment.json();
                const urlArray: string[] = dataInitPayment;
                if (urlArray.length === 0) {
                    setShowErrorMessage(true);
                } else {
                    window.location.href = urlArray[0];
                }
            } else {
                const errorData = await responseInitPayment.json();
                throw new Error(errorData);
            }
        }
    } catch (e) {
        console.log(e);

        setShowErrorMessage(true);
        clearLoader();
    }
};
