import React, { useState, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import { MemberProps } from "./MemberProps.csharp";
import MemberName from "./MemberName/MemberName";
import MemberCallToAction from "./MemberCallToAction/MemberCallToAction";
import MembershipRenewal from "./MembershipRenewal/MembershipRenewal";
import { getData } from "./api/get";
import MemberTickets from "../Shared/MemberTickets/MemberTickets";
import MemberLayout from "../Shared/Member/MemberLayout/MemberLayout";
import ContentArea from '../../../Partials/ContentArea/ContentArea';
import moment from "moment";
import "moment/locale/nb";

export const MemberPage = (props: MemberProps) => {
    const [memberCardShown, setMemberCardShown] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [serverError, setServerError] = useState(false);
    const [memberName, setMemberName] = useState("");
    const [memberLastName, setMemberLastName] = useState("");
    const [validUntilDate, setValidUntilDate] = useState("");
    const [qrCode, setQrCode] = useState("");
    const [typeOfMembership, setTypeOfMembership] = useState("");
    const [membershipExpired, setMembershipExpired] = useState(true);

    const numberOfTickets =
        props.memberTickets && props.memberTickets.orderLines ? props.memberTickets.orderLines.length : null;

    useEffect(() => {
        (async () => {
            try {
                const responseMember = await getData(props.endpointMember, props.token, setServerError);
                const responseMembership = await getData(props.endpointMembership, props.token, setServerError);
                const cardNumber = responseMember?.card_list?.cards?.[0]?.card_number;

                setMemberName(responseMember.first_name);
                setMemberLastName(responseMember.last_name);
                setValidUntilDate(responseMembership.valid_until_date);
                setMembershipExpired(moment(responseMembership.valid_until_date, "YYYY-MM-DD").isBefore(moment(), "day"));
                setQrCode(cardNumber || "");
                setTypeOfMembership(responseMembership.membership_code_caption);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                setServerError(true);
                console.log(error);
            }
        })();
    }, []);

    useEffect(() => {
        if (props.membershipRenewal) {
            // @ts-ignore
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: "view_item",
                ecommerce: {
                    currency: "NOK",
                    value: props.membershipRenewal.webUnitPrice.toFixed(2),
                    items: [
                        {
                            item_id: props.membershipRenewal.itemNo,
                            item_name: "Medlemskap Fornyelse",
                            price: props.membershipRenewal.webUnitPrice.toFixed(2),
                            quantity: 1
                        }
                    ]
                }
            });
        }
    }, [props.membershipRenewal]);

    return (
        <MemberLayout
            contextualMenu={{
                ...props.contextualMenu,
                numberOfTickets: numberOfTickets,
                showMemberCardText: membershipExpired ? "" : props.contextualMenu.showMemberCardText
            }}
            memberCard={{
                ...props.memberCard,
                isOpen: memberCardShown,
                showMemberCard: setMemberCardShown,
                nameOfMember: memberName + " " + memberLastName,
                typeOfMembership: typeOfMembership,
                validTo: validUntilDate,
                qrCode: qrCode,
            }}
            isLoading={isLoading}
            serverError={serverError}
            showMemberCard={setMemberCardShown}
            serverErrorMessage={props.serverErrorMessage}
        >
            {!isLoading && !serverError && (
                <>
                    {props.memberName && <MemberName text={props.memberName.welcomeText} name={memberName} />}
                    {props.memberTickets && (
                        <MemberTickets {...props.memberTickets} tickets={props.memberTickets.orderLines} />
                    )}
                    {props.membershipRenewal && (
                        <MembershipRenewal {...props.membershipRenewal} expirationDate={validUntilDate} />
                    )}
                </>
            )}

            {props.memberCallToAction && (
                <MemberCallToAction
                    intro={...props.memberCallToAction.intro}
                    link={...props.memberCallToAction.linkItem}
                    showMemberCardText={membershipExpired ? "" : props.memberCallToAction.showMemberCardText}
                    showMemberCard={setMemberCardShown}
                />
            )}
            <ContentArea blocks={props.blocks.components} />
        </MemberLayout>
    );
};
