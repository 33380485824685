import React, { useState, useEffect } from "react";
import { CarouselBlock } from "../../Pages/Ecommerce/Shared/CarouselBlock/CarouselBlock";
import { ProductCard } from "../../Pages/Ecommerce/Shared/ProductCard";

interface IImage {
    altText: string;
    url: string;
}

interface IProduct {
    productName?: string;
    urlKey: string;
    images: IImage[];
    brand?: string;
    price: {
        format: string;
        originalPrice: string;
        vatPrice: string;
    };
}

interface IProductListBlock {
    maxNumberOfProducts: number;
    productPageLinkUrl: string;
    backgroundColor: string;
    header?: string;
    previousSlideAriaLabel?: string;
    nextSlideAriaLabel?: string;
    products: IProduct[];
    buttonUrl?: string;
    buttonText?: string;
}

export const ProductListBlock = ({
    maxNumberOfProducts,
    productPageLinkUrl,
    backgroundColor,
    header,
    previousSlideAriaLabel,
    nextSlideAriaLabel,
    products,
    buttonText,
    buttonUrl
}: IProductListBlock) => {
    console.log(products);
    console.log(maxNumberOfProducts);
    products = products.slice(0, maxNumberOfProducts);

    return (
        <CarouselBlock
            className="CarouselBlock--in-product-list"
            title={header}
            bgColor={backgroundColor}
            previousSlideAriaLabel={previousSlideAriaLabel}
            nextSlideAriaLabel={nextSlideAriaLabel}
            buttonUrl={buttonUrl}
            buttonText={buttonText}
            displayData={products.map((product) => ({
                key: product.productName,
                data: (
                    <ProductCard
                        className="ProductListBlock__Card ProductCard--in-product-list"
                        product={{
                            url: productPageLinkUrl + product.urlKey,
                            imageUrl: product?.images[0]?.url,
                            imageAlt: product?.images[0]?.altText,
                            title: product.productName,
                            brand: product.brand,
                            originalPrice: product.price.originalPrice,
                            vatPrice: product.price.vatPrice,
                        }}
                        currencyFormat={product.price && product.price.format ? product.price.format : ""}
                    />
                ),
            }))}
        />
    );
};

export default ProductListBlock;
