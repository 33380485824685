import * as React from "react";
import { MotifFilters } from "./MotifFilters.csharp";
import { CollectionSearchPageTranslations } from "./CollectionSearchPageTranslations.csharp";
import { Collapse } from "react-collapse";
import { Animations } from "../../ReactComponents/animations";
import { CollectionSearchRange } from "./CollectionSearchRange";
import classNames from "classnames";

export interface CollectionSearchBarProps {
    isLoading: boolean;
    query: string;
    onChange: any;
    onClick: any;
    filterAllMotifs: MotifFilters;
    filterClick: any;
    showFilter: boolean;
    classificationClick: any;
    filterCurrentClassification: string;
    handleYearChange: any;
    filterYearRange: [number, number];
    handleSelectedMotifsChange: any;
    filterSelectedMotifs: Array<string>;
    filtersFromAdvancedSearch: Array<any>;
    handlefiltersFromAdvancedSearchChange: any;
    linkToAdvancedSearch: string;
    translations: CollectionSearchPageTranslations;
    heading: string;
    isDefaultEditorControlledSearch: boolean;
}

const yearRangeDefault = [1863, 1944];

var onSearchKeyPress = (event, onClick) => {
    if (event.key === "Enter") {
        event.target.blur();
        onClick();
    }
};

export const CollectionSearchBar = (props: CollectionSearchBarProps) => {
    const { translations } = props;

    const classificationsKeyValues = [
        { key: "Malerier", value: translations.classificationPainting },
        { key: "Grafikk", value: translations.classificationGraphic },
        { key: "Tegninger", value: translations.classificationDrawing },
        { key: "Skulpturer", value: translations.classificationSculpture },
        { key: "Fotografier", value: translations.classificationPhoto },
        { key: "", value: translations.classificationAll },
    ];

    const shouldHideRemovableFilters =
        props.filterCurrentClassification.length == 0 &&
        props.filterSelectedMotifs.length == 0 &&
        props.filtersFromAdvancedSearch.length == 0 &&
        props.filterYearRange[0] === yearRangeDefault[0] &&
        props.filterYearRange[1] === yearRangeDefault[1];

    const isCurrentFiltersHidden = shouldHideRemovableFilters || props.isDefaultEditorControlledSearch;

    return (
        <div className="SearchBar__container">
            <div className="SearchBar__container_gray">
                <div
                    className={
                        props.showFilter ? "SearchBar__Input__container flex_column" : "SearchBar__Input__container"
                    }
                >
                    <h1>{props.heading}</h1>
                    <div className="SearchResult__input">
                        <input
                            type="text"
                            tabIndex={1}
                            name="q"
                            value={props.query}
                            onChange={props.onChange}
                            placeholder={translations.searchPlaceholder}
                            onKeyPress={(event) => {
                                onSearchKeyPress(event, props.onClick);
                            }}
                        />
                        <button onClick={props.onClick}>
                            <span className="visuallyHidden">{translations.searchSearchButton}</span>
                        </button>
                    </div>
                </div>
                <Collapse isOpened={props.showFilter} springConfig={Animations.spring}>
                    <div className="filter__classification__container">
                        {classificationsKeyValues.map((object, i) => {
                            return (
                                <a
                                    key={object.key}
                                    id={object.key}
                                    onClick={props.classificationClick}
                                    className={
                                        props.filterCurrentClassification == object.key
                                            ? "button--secondary classification__selected"
                                            : "button--secondary"
                                    }
                                >
                                    {object.value}
                                </a>
                            );
                        })}
                    </div>

                    <div className="SearchRange__container">
                        <CollectionSearchRange
                            domain={[yearRangeDefault[0], yearRangeDefault[1]]}
                            handleYearChange={props.handleYearChange}
                            filterYearRange={props.filterYearRange}
                        />
                    </div>
                    <div className="filter__collapse__more__filters">
                        {props.filterAllMotifs == null ? (
                            <></>
                        ) : (
                            getArrayListWithAll(
                                props.filterAllMotifs,
                                props.handleSelectedMotifsChange,
                                props.filterSelectedMotifs,
                                props.translations
                            )
                        )}
                    </div>
                    <a className="advanced__search__link" href={props.linkToAdvancedSearch}>
                        {translations.advancedSearch}
                    </a>
                </Collapse>
                <div
                    className={
                        props.showFilter
                            ? "SearchBar__filter__arrow_container arrow_container_up"
                            : "SearchBar__filter__arrow_container"
                    }
                >
                    <a onClick={props.filterClick}>{props.showFilter ? "" : translations.filter}</a>
                </div>
            </div>

            {isCurrentFiltersHidden ? null : (
                <div className="filter__classification__container deselectFilterTag">
                    {classificationsKeyValues.map((classification) => {
                        if (
                            classification.key == props.filterCurrentClassification &&
                            props.filterCurrentClassification
                        ) {
                            return (
                                <a
                                    key={classification.key}
                                    id={""}
                                    onClick={props.classificationClick}
                                    className="button--secondary classification__selected"
                                >
                                    {classification.value}
                                </a>
                            );
                        }
                    })}
                    {props.filterYearRange[0] === yearRangeDefault[0] &&
                    props.filterYearRange[1] === yearRangeDefault[1] ? (
                        <></>
                    ) : (
                        <a
                            onClick={() => props.handleYearChange([yearRangeDefault[0], yearRangeDefault[1]])}
                            className="button--secondary classification__selected"
                        >
                            {props.filterYearRange[0].toString()} - {props.filterYearRange[1].toString()}
                        </a>
                    )}
                    {props.filterSelectedMotifs.map((motif, i) => {
                        return (
                            <a
                                key={motif + i}
                                id={motif}
                                onClick={props.handleSelectedMotifsChange}
                                className="button--secondary classification__selected"
                            >
                                {motif}
                            </a>
                        );
                    })}

                    {props.filtersFromAdvancedSearch.map((filter) => {
                        return (
                            <a
                                key={filter.key}
                                id={filter.key}
                                onClick={props.handlefiltersFromAdvancedSearchChange}
                                className="button--secondary classification__selected"
                            >
                                {filter.value}
                            </a>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

function getArrayListWithAll(
    filterAllMotifs: MotifFilters,
    handleSelectedMotifsChange: any,
    filterSelectedMotifs: Array<string>,
    translations: CollectionSearchPageTranslations
) {
    var listParentName = Array<JSX.Element>();

    listParentName.push(
        getParentAndChildrenToMotif(
            filterAllMotifs.munchTemaer,
            handleSelectedMotifsChange,
            filterSelectedMotifs,
            translations
        )
    );
    listParentName.push(
        getParentAndChildrenToMotif(
            filterAllMotifs.menneske,
            handleSelectedMotifsChange,
            filterSelectedMotifs,
            translations
        )
    );
    listParentName.push(
        getParentAndChildrenToMotif(
            filterAllMotifs.natur,
            handleSelectedMotifsChange,
            filterSelectedMotifs,
            translations
        )
    );
    listParentName.push(
        getParentAndChildrenToMotif(
            filterAllMotifs.landskap,
            handleSelectedMotifsChange,
            filterSelectedMotifs,
            translations
        )
    );
    listParentName.push(
        getParentAndChildrenToMotif(
            filterAllMotifs.interioer,
            handleSelectedMotifsChange,
            filterSelectedMotifs,
            translations
        )
    );
    listParentName.push(
        getParentAndChildrenToMotif(
            filterAllMotifs.sted,
            handleSelectedMotifsChange,
            filterSelectedMotifs,
            translations
        )
    );
    listParentName.push(
        getParentAndChildrenToMotif(filterAllMotifs.tid, handleSelectedMotifsChange, filterSelectedMotifs, translations)
    );

    return listParentName;
}

function getParentAndChildrenToMotif(motif, handleSelectedMotifsChange, filterSelectedMotifs, translations) {
    return (
        <ListItem
            key={motif["parentName"]}
            parent={motif["parentName"]}
            motif={motif}
            handleSelectedMotifsChange={handleSelectedMotifsChange}
            filterSelectedMotifs={filterSelectedMotifs}
            translations={translations}
        />
    );
}

function onExpandMotifClick(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    var ul = document.getElementById(event.currentTarget.id + "ul");
    var span = document.getElementById(event.currentTarget.id);

    if (span.className == "arrow__motif") {
        ul.style.display = "block";
        span.className = "more__filters__motifs__li arrow__motif__down";
    } else {
        ul.style.display = "none";
        span.className = "arrow__motif";
    }

    return false;
}

function getTranslatedMotifName(motifName: string, translations: CollectionSearchPageTranslations): string {
    if (translations.motifs === null || Object.keys(translations.motifs).length === 0 || motifName === null) {
        return motifName;
    }

    let washedMotifName = motifName.replace("'", "").replace("/", "");
    washedMotifName = washedMotifName[0].toLowerCase() + washedMotifName.slice(1);

    const match = translations.motifs[washedMotifName];
    if (!match) return motifName;
    return match;
}

const liElement = (
    child,
    handleSelectedMotifsChange,
    filterSelectedMotifs,
    parents: Array<string>,
    translations,
    parent = null
) => {
    const motifName = getTranslatedMotifName(child.parentName, translations);
    const motifNameParent = getTranslatedMotifName(parent, translations);
    return (
        <div className="arrow__li__container">
            <span
                id={parent ? motifNameParent : motifName}
                onClick={onExpandMotifClick}
                className={classNames(child.children == null ? "" : "arrow__motif")}
            ></span>
            <li
                id={parent ? motifNameParent : motifName}
                key={motifName + "li"}
                data-parents={parents.toString()}
                className={classNames(
                    "more__filters__motifs__li",
                    filterSelectedMotifs.indexOf(motifName) !== -1 ? "more__filters__motifs__li__bold" : ""
                )}
                onClick={handleSelectedMotifsChange}
            >
                {motifName}
            </li>
        </div>
    );
};

const ListItem = ({ parent, motif, handleSelectedMotifsChange, filterSelectedMotifs, translations }) => (
    <ul className="more__filters__motifs__ul">
        {liElement(motif, handleSelectedMotifsChange, filterSelectedMotifs, [], translations, parent)}
        <ul id={getTranslatedMotifName(parent, translations) + "ul"} className="more__filters__motifs__ul">
            {motif.children == null
                ? null
                : motif.children.map((child) => (
                      <div key={getTranslatedMotifName(child.parentName, translations) + "div"}>
                          {liElement(child, handleSelectedMotifsChange, filterSelectedMotifs, [parent], translations)}
                          <ul
                              key={getTranslatedMotifName(child.parentName, translations) + "ul"}
                              id={getTranslatedMotifName(child.parentName, translations) + "ul"}
                              className="more__filters__motifs__ul"
                          >
                              {child.children == null
                                  ? null
                                  : child.children.map((child2) => (
                                        <div key={getTranslatedMotifName(child2.parentName, translations) + "div"}>
                                            {liElement(
                                                child2,
                                                handleSelectedMotifsChange,
                                                filterSelectedMotifs,
                                                [parent, child.parentName],
                                                translations
                                            )}
                                            <ul
                                                key={getTranslatedMotifName(child2.parentName, translations) + "ul"}
                                                id={getTranslatedMotifName(child2.parentName, translations) + "ul"}
                                                className="more__filters__motifs__ul"
                                            >
                                                {child2.children == null
                                                    ? null
                                                    : child2.children.map((child3) => (
                                                          <div
                                                              key={
                                                                  getTranslatedMotifName(
                                                                      child3.parentName,
                                                                      translations
                                                                  ) + "div"
                                                              }
                                                          >
                                                              {liElement(
                                                                  child3,
                                                                  handleSelectedMotifsChange,
                                                                  filterSelectedMotifs,
                                                                  [parent, child.parentName, child2.parentName],
                                                                  translations
                                                              )}
                                                              <ul
                                                                  key={
                                                                      getTranslatedMotifName(
                                                                          child3.parentName,
                                                                          translations
                                                                      ) + "ul"
                                                                  }
                                                                  id={
                                                                      getTranslatedMotifName(
                                                                          child3.parentName,
                                                                          translations
                                                                      ) + "ul"
                                                                  }
                                                                  className="more__filters__motifs__ul"
                                                              >
                                                                  {child3.children == null
                                                                      ? null
                                                                      : child3.children.map((child4) => (
                                                                            <div
                                                                                key={
                                                                                    getTranslatedMotifName(
                                                                                        child4.parentName,
                                                                                        translations
                                                                                    ) + "div"
                                                                                }
                                                                            >
                                                                                {liElement(
                                                                                    child4,
                                                                                    handleSelectedMotifsChange,
                                                                                    filterSelectedMotifs,
                                                                                    [
                                                                                        parent,
                                                                                        child.parentName,
                                                                                        child2.parentName,
                                                                                        child3.parentName,
                                                                                    ],
                                                                                    translations
                                                                                )}
                                                                                <ul
                                                                                    key={
                                                                                        getTranslatedMotifName(
                                                                                            child4.parentName,
                                                                                            translations
                                                                                        ) + "ul"
                                                                                    }
                                                                                    id={
                                                                                        getTranslatedMotifName(
                                                                                            child4.parentName,
                                                                                            translations
                                                                                        ) + "ul"
                                                                                    }
                                                                                    className="more__filters__motifs__ul"
                                                                                >
                                                                                    {child4.children == null
                                                                                        ? null
                                                                                        : child4.children.map(
                                                                                              (child5) => {
                                                                                                  liElement(
                                                                                                      child5,
                                                                                                      handleSelectedMotifsChange,
                                                                                                      filterSelectedMotifs,
                                                                                                      [
                                                                                                          parent,
                                                                                                          child.parentName,
                                                                                                          child2.parentName,
                                                                                                          child3.parentName,
                                                                                                          child4.parentName,
                                                                                                      ],
                                                                                                      translations
                                                                                                  );
                                                                                              }
                                                                                          )}
                                                                                </ul>
                                                                            </div>
                                                                        ))}
                                                              </ul>
                                                          </div>
                                                      ))}
                                            </ul>
                                        </div>
                                    ))}
                          </ul>
                      </div>
                  ))}
        </ul>
    </ul>
);
