import React, { useState, Dispatch, SetStateAction, useRef, useEffect } from "react";
import { TicketCalendar } from "../TicketCalendar/TicketCalendar";
import { TicketDaypicker } from "../TicketDaypicker/TicketDaypicker";
import { TicketTimeSlotSelector } from "../TicketTimeSlotSelector/TicketTimeslotSelector";
import { ErrorMessage } from "../../Shared/ErrorMessage";
import classNames from "classnames";
import { ITicket, ITimeSlot } from "../TicketPage";
import { Loader } from "../../Shared/Loader";
import { IEditorialTimeSlotProps } from "../TicketTimeSlotSelector/TicketTimeslotSelector";
interface ITicketDatePicker {
    editorialProps: {
        selectDateTimeTitle: string;
        showCalendarText: string;
        hideCalendarText: string;
        endpoint: string;
    };
    editorialTimeSlotProps: IEditorialTimeSlotProps;
    todayLable: string;
    setSelectedTime: Dispatch<SetStateAction<ITimeSlot | null>>;
    selectedTime: ITimeSlot | null;
    setSelectedDate: Dispatch<SetStateAction<Date | null>>;
    selectedDate: Date | null;
    isExpress: boolean;
    availableTimeSlots: ITimeSlot[];
    ticketData: ITicket[];
    availableDates: Date[];
    accessToken: string;
    datesError: boolean;
    schedulesError: boolean;
    noTimeslotsError: boolean;
    errorMessage: string;
    noTimeslotsErrorMessage: string;
    isLoading: boolean;
    timeSlotLoading: boolean;
    setShowSummary: Dispatch<SetStateAction<boolean>>;
    language: string;
    allAvailableDates: Date[];
}

export const TicketDatePicker = ({
    allAvailableDates,
    editorialProps,
    editorialTimeSlotProps,
    todayLable,
    setSelectedTime,
    selectedTime,
    setSelectedDate,
    selectedDate,
    isExpress,
    availableTimeSlots,
    availableDates,
    datesError,
    schedulesError,
    noTimeslotsError,
    errorMessage,
    noTimeslotsErrorMessage,
    isLoading,
    timeSlotLoading,
    setShowSummary,
    ticketData,
    language,
}: ITicketDatePicker) => {
    const today = new Date();
    const [calendarIsOpen, setCalendarIsOpen] = useState(false);
    const calendarRef = useRef<null | HTMLDivElement>(null);
    const shownDates = allAvailableDates.slice(0, 8);

    useEffect(() => {
        if (calendarRef?.current)
            setTimeout(() => calendarRef.current.scrollIntoView({ behavior: "smooth", block: "start" }), 300);
        setTimeout(() => calendarRef.current.classList.add("animateIn"), 750);
    }, [calendarRef]);

    const toggleCalendar = () => {
        setCalendarIsOpen(!calendarIsOpen);
    };

    const shownDatesContainsSelectedDate =
        selectedDate !== null &&
        allAvailableDates.length > 1 &&
        shownDates.some((date) => date.toJSON().split("T")[0] === selectedDate.toJSON().split("T")[0]);

    return (
        <div className={classNames("TicketPage__card")} ref={calendarRef}>
            {allAvailableDates.length > 1 && (
                <>
                    <h2 className="TicketPage__panelTitle">{editorialProps.selectDateTimeTitle}</h2>
                    {!datesError ? (
                        isLoading ? (
                            <Loader />
                        ) : (
                            <>
                                <TicketDaypicker
                                    allAvailableDates={shownDates}
                                    availableDates={availableDates}
                                    selectedDate={selectedDate && selectedDate}
                                    setSelectedDate={setSelectedDate}
                                    shownDatesContainsSelectedDate={shownDatesContainsSelectedDate}
                                    calendarIsOpen={calendarIsOpen}
                                    lanugage={language}
                                    todayLable={todayLable}
                                />

                                {calendarIsOpen && (
                                    <TicketCalendar
                                        availableDates={availableDates}
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                        today={today}
                                        language={language}
                                    />
                                )}
                                {availableDates.length >= 5 && (
                                    <button className="TicketPage__Daypicker__calendar-button" onClick={toggleCalendar}>
                                        {calendarIsOpen
                                            ? editorialProps.hideCalendarText
                                            : editorialProps.showCalendarText}
                                    </button>
                                )}
                            </>
                        )
                    ) : (
                        <ErrorMessage text={errorMessage} />
                    )}
                </>
            )}
            {selectedDate && (
                <TicketTimeSlotSelector
                    editorialProps={editorialTimeSlotProps}
                    ticketsWarningLimit={editorialTimeSlotProps.ticketsWarningLimit}
                    setSelectedTime={setSelectedTime}
                    selectedTime={selectedTime}
                    availableTimeSlots={availableTimeSlots}
                    schedulesError={schedulesError}
                    noTimeslotsError={noTimeslotsError}
                    errorMessage={errorMessage}
                    isLoading={timeSlotLoading}
                    setShowSummary={setShowSummary}
                    ticketData={ticketData}
                    noTimeslotsErrorMessage={noTimeslotsErrorMessage}
                />
            )}
        </div>
    );
};
