import moment from "moment";

export function formatDate(date: Date, culture = "en-gb"): string {
    moment.locale(mapCultureFromEpiServer(culture));
    return moment(date).format("DD.MM.YYYY");
}

export function mapCultureFromEpiServer(culture: string): string {
    switch (culture)
    {
        case "no":
            return "nb";
        case "en":
            return "en-gb";
        default:
            return culture;
    }
}