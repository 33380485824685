import React, { useState, useEffect, useRef } from "react";
import { ForgotPasswordProps } from "./ForgotPasswordProps.csharp";
import { checkEmailIsValid } from "../Shared/utils/checkEmailIsValid";
import { postRequest } from "../Shared/api/post";
import { Input } from "../Shared/Input/Input";
import { NextButton } from "../Shared/NextButton/NextButton";
import { ErrorMessage } from "../Shared/ErrorMessage";
import { MessageBox } from "../Shared/MessageBox/MessageBox";

export const ForgotPasswordPage = (props: ForgotPasswordProps) => {
    const [email, setEmail] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState("");
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [showMessageBox, setShowMessageBox] = useState(false);
    const ref = useRef<HTMLDivElement>();
    const emailValidation = () => {
        setShowEmailError(true);
        setEmailIsValid(checkEmailIsValid(email));
    };
    const disabled = email === "" || !emailIsValid;

    const submit = async () => {
        try {
            setShowErrorMessage("");
            const endpoint = props.endpointResetPasswordEmail;

            const data = JSON.stringify({
                email: email,
                storeCode: props.language
            });

            const response = await postRequest(endpoint, data, null);
            const result = await response.json();
            if (!response.ok) throw new Error(result);
            if (result.errors && result.errors[0]) {
                setShowErrorMessage(props.emailNotExistingErrorText);
            } else {
                setConfirmed(true);
            }
        } catch (e) {
            console.log(e);
            setShowErrorMessage(props.errorMessage);
        }
    };

    useEffect(() => {
        if (email.length > 0) setEmailIsValid(checkEmailIsValid(email));
    }, [email]);

    useEffect(() => {
        if (ref?.current) {
            setTimeout(() => ref.current.scrollIntoView({ behavior: "smooth", block: "end" }), 300);
            setTimeout(() => ref.current.classList.add("animateIn"), 750);
        }
    }, [confirmed, ref]);
    return (
        <div className="PaymentPage">
            <div className="PaymentPage__Container">
                <section className="TicketPage__card animateIn">
                    <h1 className="PaymentPage__title">{props.title}</h1>
                    <p className="TicketPage__paragraph">{props.intro}</p>
                    <Input
                        type={"email" as const}
                        label={props.emailLabel}
                        isRequired={true}
                        value={email}
                        onBlur={emailValidation}
                        isBig={true}
                        autoComplete={"On"}
                        placeholder={props.emailPlaceholder}
                        onChange={setEmail}
                        errorValidation={showEmailError && !emailIsValid}
                        errorValidationText={props.emailValidationText}
                        showError={showEmailError}
                    />
                    <NextButton
                        showTwo={false}
                        mainButtonClick={submit}
                        mainButtonText={props.confirmButtonText}
                        disabled={disabled}
                    />
                    {showErrorMessage && <ErrorMessage text={showErrorMessage} />}
                </section>
                {confirmed && (
                    <div className="TicketPage__card" ref={ref}>
                        <h2>{props.confirmedTitle}</h2>
                        <p>{props.confirmedIntro}</p>
                        <button
                            onClick={() => {
                                submit();
                                setShowMessageBox(true);
                            }}
                            className="ResendPassword__Button"
                        >
                            {props.resendPasswordText}
                        </button>
                        {showMessageBox && <MessageBox text={props.confirmationText.replace("{0}", email)} />}
                    </div>
                )}
            </div>
        </div>
    );
};
