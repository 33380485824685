import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";

export interface IOpeningHours {
    text ?: string; 
    link ?: {
        url: string;
        text: string
    }
    className?: string;
}

export const OpeningHours = ({ text, link, className }: IOpeningHours) => {
    return (
        <div className={cn("OpeningHours", className)}>
            {text && <p className="OpeningHours__text">{text}</p>}
            {link && link.text && <a href={link.url} className="OpeningHours__link">{link.text}</a>}
        </div>
    );
};
