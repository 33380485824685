import classNames from "classnames";
import moment from "moment";
import React, { useRef, useEffect } from "react";
import { mapCultureFromEpiServer } from "../../../../ReactComponents/DateUtil";
export const TicketDaypicker = (props: {
    allAvailableDates: Date[];
    availableDates: Date[];
    selectedDate: Date;
    setSelectedDate: (date: Date) => void;
    shownDatesContainsSelectedDate: boolean;
    calendarIsOpen: boolean;
    lanugage: string;
    todayLable: string;
}) => {
    const ref = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (
            (!props.shownDatesContainsSelectedDate && props.selectedDate !== null) ||
            (props.selectedDate !== null &&
                props.availableDates
                    .slice(Math.max(props.availableDates.length - 3, 0))
                    .some((date) => date.toJSON().split("T")[0] === props.selectedDate.toJSON().split("T")[0]))
        ) {
            ref.current.scrollLeft = 3000;
        } else {
            ref.current.scrollLeft = 0;
        }
    });

    useEffect(() => {
        moment.locale(mapCultureFromEpiServer(props.lanugage));
    }, [props.lanugage]);

    const days = props.allAvailableDates.map((d, i) => {
        const isSelected = moment(d).isSame(props.selectedDate, "day");
        const isToday = moment(d).isSame(moment(), "day");
        const isDisabled = !props.availableDates.find((day) => moment(d).isSame(day, "day"));
        return (
            <button
                key={d.toString()}
                className={classNames("TicketDaypicker__dayCard", {
                    "TicketDaypicker__dayCard--selected": isSelected,
                    "TicketDaypicker__dayCard--disabled": isDisabled,
                })}
                onClick={() => {
                    if (!isDisabled) props.setSelectedDate(d);
                }}
                aria-label={
                    isToday
                        ? props.todayLable
                        : `${moment(d).format("dddd")} ${moment(d).format("D")} ${moment(d).format("MMMM")}`
                }
            >
                <DayCardText date={d} isSelected={isSelected} isToday={isToday} todayLable={props.todayLable} />
            </button>
        );
    });
    return (
        <div
            ref={ref}
            className={classNames("TicketDaypicker__dayCard__container", {
                "TicketDaypicker__dayCard__container--hidden": props.calendarIsOpen,
            })}
        >
            {days}

            {!props.shownDatesContainsSelectedDate && props.selectedDate !== null && (
                <>
                    <div className="TicketDaypicker__dayCard__divider" />
                    <div className={classNames("TicketDaypicker__dayCard", "TicketDaypicker__dayCard--selected")}>
                        <DayCardText
                            date={props.selectedDate}
                            isSelected={true}
                            isToday={false}
                            todayLable={props.todayLable}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

const DayCardText = (props: { date: Date; isSelected: boolean; isToday: boolean; todayLable: string }) => {
    return (
        <>
            <span
                className={classNames("TicketDaypicker__dayCard__dayLabel", {
                    TicketPage__dayLabelSelected: props.isSelected,
                })}
            >
                {props.isToday ? "" : moment(props.date).format("dddd").substring(0, 3)}
            </span>
            <span
                className={classNames({
                    TicketPage__dateNumber: !props.isToday,
                    TicketPage__today: props.isToday,
                })}
            >
                {props.isToday ? props.todayLable : moment(props.date).format("D")}
            </span>
            <span
                className={classNames("TicketDaypicker__dayCard__dayLabel", {
                    TicketPage__dayLabelSelected: props.isSelected,
                })}
            >
                {props.isToday ? "" : moment(props.date).format("MMMM").substring(0, 3)}
            </span>
        </>
    );
};
