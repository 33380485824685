import { postRequest } from "./post";

export const newsletterSignup = async (endpoint: string, email: string, accessToken?: string) => {
    const query = JSON.stringify({
        query: `mutation {
            subscribeEmailToNewsletter(email: "${email}") {
              status
            }
          }`,
    });

    const response = await postRequest(endpoint, query, accessToken);
    return response;
};
