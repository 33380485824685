import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import { ITicketReceipt } from "../TicketReceiptPage";
import MemberTicket from "../../Shared/MemberTicket/MemberTicket";

interface ITicketReceiptList {
    ticketList: ITicketReceipt[];
    prevButtonText: string;
    nextButtonText: string;
}

export const TicketReceiptList = ({ ticketList, prevButtonText, nextButtonText }: ITicketReceiptList) => {
    const swiperRef = React.useRef<SwiperType>();

    return (
        <div className="TicketReceiptList">
            <ul className="TicketReceiptList__tickets">
                <Swiper
                    centeredSlides={true}
                    slidesPerView={1.4}
                    breakpoints={{
                        640: { slidesPerView: 2, centeredSlides: false },
                    }}
                    spaceBetween={20}
                    className="mySwiper"
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                >
                    {ticketList.map((ticket, index) => (
                        <SwiperSlide key={ticket.qrCode}>
                            <MemberTicket
                                name={ticket.title.toUpperCase()}
                                type={ticket.type}
                                date={ticket.date}
                                time={ticket.time}
                                barcode={ticket.qrCode}
                                dateLabel={ticket.dateLabel}
                                timeLabel={ticket.timeLabel}
                                slideNumber={index + 1}
                                slidesNumber={ticketList.length}
                                numberOfTicketText="Billett {0} av {1}"
                                showNumberOfTicketText={ticketList.length > 1}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </ul>

            {ticketList.length > 2 && (
                <div className="TicketReceiptList__buttons">
                    <button
                        className="button--secondary TicketReceiptList__button TicketReceiptList__button--prev"
                        onClick={() => swiperRef.current?.slidePrev()}
                    >
                        {prevButtonText}
                    </button>
                    <button
                        className="button--secondary TicketReceiptList__button TicketReceiptList__button--next"
                        onClick={() => swiperRef.current?.slideNext()}
                    >
                        {nextButtonText}
                    </button>
                </div>
            )}
        </div>
    );
};
