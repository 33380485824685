import React, { useState, useEffect } from "react";
import TwoColumnsBlock from "../../Shared/TwoColumnsBlock/TwoColumnsBlock";

interface IMemberCallToAction {
    intro: string;
    link: {
        text: string;
        url: string;
    };
    showMemberCardText: string;
    showMemberCard?: (val: any) => any;
}

const MemberCallToAction = ({ intro, link, showMemberCardText, showMemberCard = () => {} }: IMemberCallToAction) => {
    return (
        <TwoColumnsBlock intro={intro} link={link} buttomText={showMemberCardText} onButtonClick={showMemberCard} inOneCoulmn={true} />
    );
};

export default MemberCallToAction;
