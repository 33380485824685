import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { ToggleButton } from "../../Shared/ToggleButton/ToggleButton";
import { NextButton } from "../../Shared/NextButton/NextButton";
import { ITimeSlot } from "../TicketPage";
import { formatTime } from "../utils/formatTime";
import { ITicket } from "../TicketPage";
import { getTickets } from "../api/get";
import { getAvailableDates } from "../api/post";
import { Loader } from "../../Shared/Loader";
interface ITicketMember {
    setDatePickerIsOpen: Dispatch<SetStateAction<boolean>>;
    setShowAmountSelector: Dispatch<SetStateAction<boolean>>;
    setIsExpress: Dispatch<SetStateAction<boolean>>;
    setSelectedTime: Dispatch<SetStateAction<ITimeSlot>>;
    setSelectedDate: Dispatch<SetStateAction<Date>>;
    setTicketData: Dispatch<SetStateAction<ITicket[]>>;
    availableTimeSlots: ITimeSlot[];
    numberOfTickets: number;
    editorialProps: {
        express: boolean;
        entryNowButtonText: string;
        entryLaterButtonText: string;
        selectDateTimeButtonText: string;
        firstAvailableButtonText: string;
        endpoint: string;
        numberOfMemberTicketText: string;
        oneTicket: string;
        twoTickets: string;
        accessToken: string;
    };
    availableDates: Date[];
    setSetupError: any;
    ticketData: ITicket[];
    setAvailableDates: Dispatch<SetStateAction<Date[]>>;
    setDatesError: Dispatch<SetStateAction<boolean>>;
    datePickerEndpoint: string;
    setShowSummary: Dispatch<SetStateAction<boolean>>;
}
export const TicketMember = ({
    setDatePickerIsOpen,
    setShowAmountSelector,
    setIsExpress,
    availableTimeSlots,
    numberOfTickets,
    setSelectedTime,
    setSelectedDate,
    setTicketData,
    setSetupError,
    editorialProps,
    availableDates,
    ticketData,
    setAvailableDates,
    setDatesError,
    datePickerEndpoint,
    setShowSummary,
}: ITicketMember) => {
    const [showNextButton, setShowNextButton] = useState(false);
    const [ticketId, setTicketId] = useState<number>();
    const [setupIsLoading, setSetupIsLoading] = useState(false);
    const [datesIsLoading, setDatesIsLoading] = useState(false);
    useEffect(() => {
        (async () => {
            try {
                const timeout = setTimeout(() => setSetupIsLoading(true), 300);
                const [data, admissions] = await getTickets(
                    editorialProps.endpoint,
                    editorialProps.accessToken,
                    setSetupError
                );
                setTicketId(data[0].package_product_id);
                console.log(data);
                setTicketData((oldData: ITicket[]) => [
                    ...oldData,
                    ...data.filter((ticket) =>
                        oldData.find((oldTicket) => ticket.package_product_id !== oldTicket.package_product_id)
                    ),
                ]);
                clearTimeout(timeout);
                setSetupIsLoading(false);
            } catch (error) {
                setSetupError(true);
                console.log(error);
            }
        })();
    }, []);

    const onChange = (value: string) => {
        // update ticket count based on select
        const index = ticketData.findIndex((ticket) => ticket.package_product_id === ticketId);
        if (index !== -1) {
            ticketData[index].count = Number(value);
        }

        // get available dates based on current selected count
        (async () => {
            try {
                setDatesIsLoading(true);
                setAvailableDates(
                    (
                        await getAvailableDates(
                            ticketData,
                            datePickerEndpoint,
                            editorialProps.accessToken,
                            setDatesError
                        )
                    ).map((d: string) => new Date(d))
                );

                setDatesIsLoading(false);
            } catch (error) {
                setDatesError(true);
            }
        })();

        if (value === editorialProps.oneTicket || value === editorialProps.twoTickets) {
            if (editorialProps.express) {
                setShowNextButton(true);
                setDatePickerIsOpen(false);
                setShowSummary(false);
            } else {
                setDatePickerIsOpen(true);
            }
            setShowAmountSelector(false);
        } else {
            setShowNextButton(false);
            setShowAmountSelector(true);
            setDatePickerIsOpen(false);
            setIsExpress(false);
        }
    };

    const firstAvailableTimeSlot =
        availableTimeSlots &&
        availableTimeSlots.find((timeSlot: ITimeSlot) => timeSlot.remaining_slots >= numberOfTickets);
    const isExpressPageAndHasAvailableTimeSlot = !!(editorialProps.express && firstAvailableTimeSlot);
    const date = availableDates[0];
    return (
        <>
            {setupIsLoading ? (
                <Loader />
            ) : (
                <ToggleButton
                    header={editorialProps.numberOfMemberTicketText}
                    onChange={onChange}
                    row={false}
                    values={[editorialProps.oneTicket, editorialProps.twoTickets]}
                />
            )}

            {datesIsLoading && editorialProps.express && <Loader />}
            {showNextButton && !datesIsLoading && (
                <NextButton
                    showTwo={isExpressPageAndHasAvailableTimeSlot}
                    mainButtonClick={() => {
                        setIsExpress(false);
                        setDatePickerIsOpen(true);
                    }}
                    secondaryButtonclick={() => {
                        setIsExpress(true);
                        setDatePickerIsOpen(false);
                        setSelectedTime(firstAvailableTimeSlot);
                        setSelectedDate(date);
                        setShowSummary(true);
                    }}
                    mainButtonText={
                        isExpressPageAndHasAvailableTimeSlot
                            ? editorialProps.entryLaterButtonText
                            : editorialProps.selectDateTimeButtonText
                    }
                    secondaryButtonText={
                        firstAvailableTimeSlot === availableTimeSlots[0]
                            ? editorialProps.entryNowButtonText
                            : editorialProps.firstAvailableButtonText +
                              formatTime(firstAvailableTimeSlot?.start_utc_timestamp)
                    }
                />
            )}
        </>
    );
};
