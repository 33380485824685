import React from "react";
import { IDetailedPrice } from "../../Product/ProductPage";

interface IReceiptOrderList {
    title: string;
    price: IDetailedPrice;
    size?: string;
    date?: string;
    startTime?: string;
    endTime?: string;
}

interface IDiscount {
    title: string;
    discountAmount: number;
    label: string;
}

export const ReceiptOrderList = ({
    orderList,
    totalText,
    grandTotal,
    vatSumText,
    taxAmount,
    sizeText,
    discounts,
}: {
    orderList: IReceiptOrderList[];
    totalText: string;
    grandTotal: string;
    vatSumText: string;
    taxAmount: string;
    sizeText: string;
    discounts: IDiscount[];
}) => {
    const priceFormat = orderList?.length > 0 && orderList.find((order) => order.price.format)?.price?.format;
    return (
        <section>
            <ul className="ReceiptOrderList">
                {orderList?.length > 0 &&
                    orderList.map((order) => (
                        <li className="ReceiptOrder__Container" key={order.title}>
                            <p className="ReceiptOrder__Item">
                                <span className="ReceiptOrder__Title">{order.title}</span>
                                <span>
                                    {order.date && order.date + ", "}
                                    {order.startTime && order.startTime}
                                    {order.endTime && " - " + order.endTime}
                                </span>
                            </p>
                            <p className="ReceiptOrder__Price">
                                {order.price.format.replace("price", order.price.originalPrice.toString())}
                            </p>
                        </li>
                    ))}
                {discounts?.length > 0 &&
                    discounts.map((discount) => (
                        <li className="ReceiptOrder__Container" key={discount.title}>
                            <p className="Discount__Item">
                                {discount.label} <span>{discount.title}</span>
                            </p>
                            <p className="ReceiptOrder__Price">
                                {priceFormat
                                    ? priceFormat.replace("price", discount.discountAmount.toString())
                                    : discount.discountAmount}
                            </p>
                        </li>
                    ))}
            </ul>

            <div className="ReceiptOrderList__Total">
                <p>{totalText}</p>
                <p className="ReceiptOrderList__TotalPrice">
                    {priceFormat ? priceFormat.replace("price", grandTotal ) : grandTotal}
                    {vatSumText && <span>{vatSumText.replace("{0}", taxAmount)}</span>}
                </p>
            </div>
        </section>
    );
};
