import { ArrowLinkListProps } from "./ArrowLinkListProps.csharp";
import React from "react";
import cn from "classnames";

interface ArrowLinkListExtendedProps extends ArrowLinkListProps {
    className?: string;
  }

export const ArrowLinkList = (props: ArrowLinkListExtendedProps) => {
    return (
        <div className={cn("ArrowLinkList", props.className)}>
            <h2 className="ArrowLinkList__title">{props.title}</h2>
            <ul className="ArrowLinkList__list">
                {props.items.map((item) => (
                    <li key={item.text} className="ArrowLinkList__item">
                        <a href={item.url}>
                            <span className="ArrowLinkList__item-text">{item.text} </span><span className="ArrowLinkList__icon"></span>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};
