import * as React from "react";
import { CardProps } from "../Card/CardProps.csharp";
import { ExhibitionCardViewModel } from "./ExhibitionCardViewModel.csharp";
import { ResponsivePicture } from "../../ReactComponents/responsivePicture/responsivePicture";
import classNames from "classnames";
import { CardVariant } from "../Card/CardVariant.csharp";
import { AudioGuide } from "../../../Features/Blocks/AudioGuide";

export class ExhibitionCard extends React.Component<CardProps> {
    render() {
        const { title, subtitle, location, duration, picture, url, exhibitionType, audioGuideInfoHtml, hasAudioGuide, audioGuideLeadText, hideAudioGuideIcon } =
            this.props.model as ExhibitionCardViewModel;

        let exhibitionTypeStringTranslation = "";
        if (exhibitionType === 0) {
            exhibitionTypeStringTranslation = this.props.translations.permanentExhibitionCollection;
        } else if (exhibitionType === 3) {
            exhibitionTypeStringTranslation = this.props.translations.interactiveExperience;
        } else {
            exhibitionTypeStringTranslation = this.props.translations.permanentExhibition;
        }

        return (
            <div className={classNames("ExhibitionCard")}>
                <a href={url} title={title}>
                    {this.props.variant !== CardVariant.ListItem && picture && (
                        <div className="CardPicture">
                            <ResponsivePicture profile={this.props.profile} model={picture} />
                        </div>
                    )}

                    <h2
                        className={classNames("ExhibitionCard__title", {
                            "ExhibitionCard__title--featured": !!this.props.featured,
                        })}
                    >
                        {title}
                        <span className="ExhibitionCard__subtitle">{subtitle}</span>
                    </h2>
                    <div className="ExhibitionCard__intro">
                        {exhibitionType === 2 && !duration ? null : (
                            <span>
                                {duration || exhibitionTypeStringTranslation} <br />
                            </span>
                        )}
                        <span>{location}</span>
                        {hasAudioGuide && audioGuideInfoHtml ? <AudioGuide html={audioGuideInfoHtml} audioGuideLeadText={audioGuideLeadText} hideAudioGuideIcon={hideAudioGuideIcon}></AudioGuide> : ""}
                    </div>
                </a>
            </div>
        );
    }
}
