export const getTickets = async (endpoint: string, accessToken: string, setError: (setError: boolean) => void) => {
    var response = null;
    var statusCode = 200;
    if (accessToken !== null) {
        var bearer = "Bearer " + accessToken;
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                Authorization: bearer,
            },
        };
        const responseRaw = await fetch(endpoint, requestOptions);
        response = await responseRaw.json();
        if (responseRaw.status !== 200) {
            statusCode = responseRaw.status;
            setError(true);
        }
    } else {
        const responseRaw = await fetch(endpoint);
        response = await responseRaw.json();
        if (responseRaw.status !== 200) {
            statusCode = responseRaw.status;
            setError(true);
        }
    }

    const json = statusCode === 200 ? JSON.parse(response) : response;
    const admissions = json?.admissions;
    const data = json.tickets ? json.tickets.map((ticket) => ({
        package_product_id: json.package_product_id,
        ...ticket,
        ...json.price_config.find((config) => ticket.id === config.id),
        count: ticket.qty.min,
    })) : [];
    return [data, admissions, response];
};
