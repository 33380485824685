import * as React from "react";
import * as ReactDOM from "react-dom";
import * as ReactDOMServer from "react-dom/server";

import { TestComponent } from "../Partials/TestComponent/TestComponent";
import { Navigation } from "../Partials/Navigation/Components/Navigation";
import { LanguageSwitcher } from "../Partials/LanguageSwitcher/LanguageSwitcher";
import { Card } from "../Partials/Card/Card";
import { ExhibitionCard } from "../Partials/ExhibitionCard/ExhibitionCard";
import { ArticleCard } from "../Partials/ArticleCard/ArticleCard";
import { CardsList } from "../Blocks/CardsList/CardsList";
import { ProductListBlock } from "../Blocks/ProductList/ProductListBlock";
import { ImageCarousel } from "../Blocks/ImageCarousel/ImageCarousel";
import { SearchPage } from "../Pages/Search/SearchResults";
import { ExhibitionsOverview } from "../Pages/Exhibitions/ExhibitionsOverviewPage/ExhibitionsOverview";
import { EventCard } from "../Partials/EventCard/EventCard";
import { HappeningDetailsInfo } from "../Pages/Happening/Body/HappeningDetailsInfo";
import { EventCalendar } from "../Pages/Events/EventCalendar/EventCalendar";
import { VideoFigure } from "../Blocks/Video/VideoFigure";
import { ExhibitionsArchive } from "../Pages/Exhibitions/ExhibitionsArchivePage/ExhibitionsArchive";
import { CookiesConsent } from "../Partials/CookiesConsent/cookiesConsent";
import { Popup } from "../Blocks/Popup/Popup";
import { CollectionImageComponent } from "../Pages/CollectionItemPage/CollectionImageComponent";
import { CollectionSearch } from "../Pages/CollectionSearchPage/CollectionSearch";
import { NavigationSmall } from "../Partials/NavigationSmall/Components/NavigationSmall";
import { TicketPage } from "../Pages/Ecommerce/Ticket/TicketPage";
import { TicketReceiptPage } from "../Pages/Ecommerce/TicketReceipt/TicketReceiptPage";
import { LoginPage } from "../Pages/Ecommerce/Login/LoginPage";
import { ArrowLinkList } from "../Blocks/ArrowLinkList/ArrowLinkList";
import { PaymentPage } from "../Pages/Ecommerce/Payment/PaymentPage";
import { CategoryPage } from "../Pages/Ecommerce/Category/CategoryPage";
import { NavigationEcom } from "../Partials/NavigationEcom/Components/NavigationEcom";
import { ProductPage } from "../Pages/Ecommerce/Product/ProductPage";
import { CreateMemberPage } from "../Pages/Ecommerce/CreateMember/CreateMemberPage";
import { ForgotPasswordPage } from "../Pages/Ecommerce/ForgotPassword/ForgotPasswordPage";
import { ResetPasswordPage } from "../Pages/Ecommerce/ResetPassword/ResetPasswordPage";
import { MemberPage } from "../Pages/Ecommerce/Member/MemberPage";
import { MembershipPage } from "../Pages/Ecommerce/Membership/MembershipPage";
import { MemberTicketsPage } from "../Pages/Ecommerce/MemberTickets/MemberTicketsPage";
import { RenewMembershipPage } from "../Pages/Ecommerce/RenewMembership/RenewMembershipPage";
import { CheckoutPage } from "../Pages/Ecommerce/Checkout/CheckoutPage";
import { ComponentMockPage } from "../Pages/Ecommerce/ComponentMock/ComponentMockPage";

const Components: any = {
    ArticleCard: ArticleCard,
    Card: Card,
    CardsList: CardsList,
    CookiesConsent: CookiesConsent,
    ExhibitionCard: ExhibitionCard,
    ExhibitionsOverview: ExhibitionsOverview,
    ExhibitionsArchive: ExhibitionsArchive,
    EventCard: EventCard,
    ProductListBlock: ProductListBlock,
    EventCalendar: EventCalendar,
    HappeningDetailsInfo: HappeningDetailsInfo,
    ImageCarousel: ImageCarousel,
    LanguageSwitcher: LanguageSwitcher,
    Navigation: Navigation,
    SearchPage: SearchPage,
    TestComponent: TestComponent,
    VideoFigure: VideoFigure,
    Popup: Popup,
    CollectionImageComponent: CollectionImageComponent,
    CollectionSearch: CollectionSearch,
    NavigationSmall: NavigationSmall,
    TicketPage: TicketPage,
    TicketReceiptPage: TicketReceiptPage,
    LoginPage: LoginPage,
    PaymentPage: PaymentPage,
    CategoryPage: CategoryPage,
    ArrowLinkList: ArrowLinkList,
    "NavigationEcom": NavigationEcom,
    "ProductPage": ProductPage,
    "CreateMemberPage": CreateMemberPage,
    "ForgotPasswordPage": ForgotPasswordPage,
    "ResetPasswordPage": ResetPasswordPage, 
    "MemberPage": MemberPage,
    "MembershipPage": MembershipPage,
    "MemberTicketsPage": MemberTicketsPage, 
    "RenewMembershipPage": RenewMembershipPage, 
    "CheckoutPage": CheckoutPage,
    ComponentMockPage: ComponentMockPage
};

// // register components as global required by ReactJS.NET
for (const component in Components) {
    global[component] = Components[component];
}

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;
