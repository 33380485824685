import React, { useEffect, useState } from "react";
import { TicketReceiptProps } from "./TicketReceiptProps.csharp";
import { TicketReceiptList } from "./TicketReceiptList/TicketReceiptList";
import { Loader } from "../Shared/Loader";
import { ReceiptOrderList } from "./ReceiptOrderList/ReceiptOrderList";
import GTMPurchaseData from "../Shared/GTM/GTMPurchaseData";

export interface ITicketReceipt {
    type: string;
    title: string;
    dateLabel: string;
    date: string;
    timeLabel: string;
    time: string;
    qrCode: string;
}

export const TicketReceiptPage = (props: TicketReceiptProps) => {
    const [ticketList, setTicketList] = useState<ITicketReceipt[]>(props.ticketReceiptOrderLines);

    useEffect(() => {
        if (!ticketList || ticketList.length === 0) {
            const interval = setInterval(() => {
                fetch(props.getOrderLinesEndpoint)
                    .then((response) => response.json())
                    .then((result) => {
                        setTicketList(result);
                    });
            }, 1000);
            setTimeout(() => clearInterval(interval), 10000);
        }
    }, []);

    useEffect(() => {
        // @ts-ignore
        const dataLayer = window.dataLayer || [];
        dataLayer.push(
            GTMPurchaseData(props.receiptOrderLines, props.grandTotal, props.taxAmount, props.getOrderLinesEndpoint)
        );
    }, []);

    return (
        <div className="TicketPage TicketPage--ticket-receipt-page">
            <div className="TicketPage__Container">
                <div className="TicketPage__card animateIn TicketPage__card--ticket-receipt-page">
                    <h1 className="TicketReceiptPage__Title">{props.ticketReceiptHeader.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: props.ticketReceiptHeader.ingress }} />
                    {props.hubspotLinkText && props.hubspotLink && (
                        <a href={props.hubspotLink}>{props.hubspotLinkText}</a>
                    )}

                    {props.showTicketList && ticketList && ticketList.length !== 0 && (
                        <TicketReceiptList
                            ticketList={ticketList}
                            nextButtonText={props.ticketReceiptHeader.nextButtonText}
                            prevButtonText={props.ticketReceiptHeader.previousButtonText}
                        />
                    )}

                    {props.showOrderSummary && props.receiptOrderLines && props.receiptOrderLines.length > 0 && (
                        <ReceiptOrderList
                            orderList={props.receiptOrderLines}
                            totalText={props.totalText}
                            grandTotal={props.grandTotal}
                            vatSumText={props.vatSumText}
                            taxAmount={props.taxAmount}
                            sizeText={props.sizeText}
                            discounts={props.discounts}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
