import React, { useState, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import { ComponentMockProps } from "./ComponentMockProps.csharp";

export const ComponentMockPage = (props: ComponentMockProps) => {

    return (
        <div>
            <h1>Frontend component mock</h1>
        </div>
    );
};
