import * as React from "react";

export interface ImageProps {
    selectedIndex: number;
    imageInfoUrls: string[];
    handleClose: () => void;
    show: boolean;
}

export default class FullscreenImagesModal extends React.Component<ImageProps> {
    viewer = null;
    setviewInputRef = null;

    constructor(props: ImageProps) {
        super(props);

        this.viewer = null;

        this.setviewInputRef = (element) => {
            this.viewer = element;
        };
    }

    private initOpenseadragon() {
        //Imported here since server build would not allow import to happen at top.
        import("openseadragon").then(
            (OpenSeaDragon) =>
                (this.viewer = new OpenSeaDragon.Viewer({
                    id: this.viewer.id,
                    tileSources: this.props.imageInfoUrls,
                    constrainDuringPan: true,
                    sequenceMode: this.props.imageInfoUrls.length > 0,
                    showSequenceControl: this.props.imageInfoUrls.length > 0,
                    maxZoomPixelRatio: 1.2,
                    showHomeControl: false,
                    showFullPageControl: false,
                    visibilityRatio: 1,
                    gestureSettingsMouse: { scrollToZoom: true },
                    initialPage: this.props.selectedIndex,
                    preload: true,
                    opacity: 1,
                    zoomInButton: "zoomIn",
                    zoomOutButton: "zoomOut",
                    nextButton: "next",
                    previousButton: "previous",
                }))
        );
    }

    componentDidMount() {
        document.body.style.overflowY = "hidden";
        this.initOpenseadragon();
    }

    componentWillUnmount() {
        document.body.style.overflowY = "scroll";
        this.viewer.destroy();
    }

    render() {
        return (
            <div
                className={
                    this.props.show
                        ? "ImageModal__collection__item show_modal"
                        : "ImageModal__collection__item hide_modal"
                }
                id="ImageModal"
            >
                <span
                    className="ImageModal__collection__item__closeOverlay"
                    id="ImageModal__collection__item__closeOverlay"
                >
                    <div onClick={this.props.handleClose} className="ImageModal__collection__item__close">
                        <button className="visuallyHidden">
                            @Html.TranslateFallback("/closeButton/closeText", "Close")
                        </button>
                    </div>
                    <div className="ImageModal__collection__item__fullscreen__controlls">
                        <div className="ImageModal__collection__item__fullscreen__container">
                            <button id="previous" className="ImageModal__fullscreen previous"></button>
                            <button id="zoomIn" className="ImageModal__fullscreen zoom__in"></button>
                            <button id="zoomOut" className="ImageModal__fullscreen zoom__out"></button>
                            <button id="next" className="ImageModal__fullscreen next"></button>
                        </div>
                    </div>
                </span>
                <div
                    data-object-fit="contain"
                    id="openseadragon"
                    className="ImageModal__collection__item__openseadragon_container"
                    ref={this.setviewInputRef}
                ></div>
            </div>
        );
    }
}
