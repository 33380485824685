import isAboveTablet from "../../../../Static/Animations/isAboveTablet";

function setArrowPosition() {
    
    if (isAboveTablet()) {
        const image = document.querySelector(".HappeningHeader__image");
        const arrow = document.querySelector(".HappeningHeader__arrowSeeMoreWrapperOuter") as HTMLElement;
        
        const imageHeight = image ? image.clientHeight : 0;
        if(arrow) {
            arrow.style.paddingTop = `${imageHeight}px`;
        }
    }
}

//window.addEventListener('DOMContentLoaded', setArrowPosition);
//window.addEventListener('resize', setArrowPosition);