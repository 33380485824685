import React, { SyntheticEvent } from "react";

//import { CollectionObject } from "../../Collection/Models/CollectionObject.csharp";
import { CollectionImageComponentProps } from "./CollectionImageComponentProps.csharp";
import FullscreenImagesModal from "./FullscreenImagesModal";
import { AssetHelper } from "../../ReactComponents/assetHelper";

export interface CollectionObjectState {
    imageInfoUrls: string[];
    imageThumbnailsUrls: string[];
    mainImageUrl: string;
    isLoading: boolean;
    showModal: boolean;
    selectedImageIndex: number;
    showMoreThumbnails: boolean;
    thumbnailImageContainerWidth: number;
}

const numberOfThumbnailsBeforeShowMoreButton = 18;

export class CollectionImageComponent extends React.Component<CollectionImageComponentProps, CollectionObjectState> {
    constructor(props: CollectionImageComponentProps) {
        super(props);

        this.state = {
            isLoading: true,
            imageInfoUrls: new Array<string>(),
            imageThumbnailsUrls: new Array<string>(),
            mainImageUrl: "",
            showModal: false,
            selectedImageIndex: 0,
            showMoreThumbnails: false,
            thumbnailImageContainerWidth: 0,
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleToggleMoreThumbnails = this.handleToggleMoreThumbnails.bind(this);
    }

    handleToggleMoreThumbnails() {
        if (this.state.showMoreThumbnails) {
            this.setState({ showMoreThumbnails: false });
            window.scrollTo(0, 0);
        } else {
            this.setState({ showMoreThumbnails: true });
        }
    }

    handleOpenModal(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        this.setState({
            showModal: true,
            selectedImageIndex: +event.currentTarget.id,
        });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = "/dist/assets/images/noImage.png";
    };

    componentDidMount() {
        const { collectionObject } = this.props;
        let mainImageId;
        if (collectionObject.primaryMedia) {
            mainImageId = collectionObject.primaryMedia.split("/")[4];
        } else {
            mainImageId = "";
        }
        const mainImageUrl = `https://emuseum.oslo.kommune.no/apis/iiif/image/v2/${mainImageId}/full/max/0/default.jpg`;
        this.setState({ mainImageUrl: mainImageUrl });

        this.requestManifestInfo();
    }

    render() {
        const { translations, collectionObject, virtualObjectViewModel } = this.props;

        return (
            <div className="collection_images">
                <div className="main_image">
                    <img
                        src={
                            this.state.mainImageUrl.length == 0
                                ? AssetHelper.images.placeholder
                                : this.state.mainImageUrl
                        }
                        onClick={this.handleOpenModal}
                        className="thumbnail_image_main"
                        alt={collectionObject.title}
                        onError={this.imageOnErrorHandler}
                    />
                </div>

                <div className="object_short_description_images">
                    <div className="object_short_descripton">
                        <h3>
                            {collectionObject.isVirtualObject
                                ? collectionObject.classification + translations.motifVirtualTitle + translations.title
                                : translations.title}
                        </h3>
                        <h5>{translations.medium}</h5>
                        <h5>{collectionObject.displayDate}</h5>
                        <div className="object_short_descripton_details">
                            <p>
                                {translations.inventoryNumber} {collectionObject.invNo}
                            </p>
                            {collectionObject.catalogueRaisonne ? (
                                this.getCatRaisText(collectionObject.catalogueRaisonne).map((e, i) =>
                                        i == 0 ? (
                                            <p key={e}>Catalogue raisonné: {e}</p>
                                        ) : (
                                            <p key={e}>
                                                {e}
                                            </p>
                                        )
                                    )
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div
                        className={
                            this.state.imageThumbnailsUrls.length == 0 && !this.state.isLoading
                                ? ""
                                : "thumbnails_container"
                        }
                    >
                        {this.state.imageThumbnailsUrls.length == 0 && this.state.isLoading ? (
                            <div className="thumbnails_image_container">
                                <img
                                    src={AssetHelper.images.placeholder}
                                    height="100"
                                    width="75"
                                    alt="place-holder-image"
                                    className="thumbnail_image"
                                />
                                <img
                                    src={AssetHelper.images.placeholder}
                                    height="100"
                                    width="75"
                                    alt="place-holder-image"
                                    className="thumbnail_image"
                                />
                                <img
                                    src={AssetHelper.images.placeholder}
                                    height="100"
                                    width="75"
                                    alt="place-holder-image"
                                    className="thumbnail_image"
                                />
                                <img
                                    src={AssetHelper.images.placeholder}
                                    height="100"
                                    width="75"
                                    alt="place-holder-image"
                                    className="thumbnail_image"
                                />
                            </div>
                        ) : (
                            <div
                                id="thumbnailsImageContainer"
                                className={
                                    this.state.showMoreThumbnails
                                        ? "thumbnails_image_container thumbnails_image_container__show_all"
                                        : "thumbnails_image_container"
                                }
                            >
                                {this.state.imageThumbnailsUrls.map((imageUrl, i) => {
                                    return (
                                        <img
                                            src={imageUrl}
                                            onClick={this.handleOpenModal}
                                            key={i.toString()}
                                            id={i.toString()}
                                            className="thumbnail_image"
                                            alt={collectionObject.title}
                                        />
                                    );
                                })}
                            </div>
                        )}

                        {this.state.imageThumbnailsUrls.length > numberOfThumbnailsBeforeShowMoreButton ? (
                            <button
                                className={
                                    this.state.showMoreThumbnails
                                        ? "CardList__button button--secondary button--collapse"
                                        : "CardList__button button--secondary button--expand"
                                }
                                onClick={this.handleToggleMoreThumbnails}
                            >
                                <span>
                                    {this.state.showMoreThumbnails ? translations.showLess : translations.showMore}
                                </span>
                            </button>
                        ) : null}
                    </div>
                    {virtualObjectViewModel.virtualParentInvNo !== null ? (
                        <div className="object_short_descripton">
                            <div className="object_short_descripton_details">
                                <p>{virtualObjectViewModel.virtualCollectionDescriptionString}</p>
                                <a
                                    href={`${virtualObjectViewModel.virtualParentInvNo}`}
                                    className="CardList__button button--secondary"
                                >
                                    <span>{virtualObjectViewModel.virtualShowParentText}</span>
                                </a>
                            </div>
                        </div>
                    ) : null}
                </div>
                {this.state.showModal ? (
                    <FullscreenImagesModal
                        selectedIndex={this.state.selectedImageIndex}
                        imageInfoUrls={this.state.imageInfoUrls}
                        handleClose={this.handleCloseModal}
                        show={this.state.showModal}
                    />
                ) : null}
            </div>
        );
    }

    private async requestManifestInfo() {
        this.setState({
            isLoading: true,
        });

        var urlManifest = `https://emuseum.oslo.kommune.no/apis/iiif/presentation/v2/objects-${this.props.collectionObject.id}/manifest`;

        await fetch(urlManifest, { credentials: "same-origin" })
            .then(
                (response) => response.json(),
                (error) => {
                    console.log(error);
                    this.setState({ isLoading: false });
                }
            )
            .then((result) => this.getimageUrlsFromManifestInfo(result));
    }

    private getimageUrlsFromManifestInfo(result) {
        if (!result) {
            return;
        }

        var infoUrlarray = new Array<string>();
        var thumbnailUrlarray = new Array<string>();

        result.sequences.forEach((sequence) => {
            if (sequence.canvases.length > 0) {
                sequence.canvases.forEach((canvas) => {
                    var id = canvas["@id"].split("/").pop();
                    var urlInfo = `https://emuseum.oslo.kommune.no/apis/iiif/image/v2/${id}/info.json`;
                    var urlThumbnailImages = `https://emuseum.oslo.kommune.no/apis/iiif/image/v2/${id}/full/,100/0/default.jpg`;
                    infoUrlarray.push(urlInfo);
                    thumbnailUrlarray.push(urlThumbnailImages);
                });
            }
        });

        this.setState({
            imageInfoUrls: infoUrlarray,
            imageThumbnailsUrls: thumbnailUrlarray,
            isLoading: false,
        });
    }

    private getCatRaisText(catRais) {
        if (catRais.includes("Schiefler")) {
            let inputString = catRais.replace("Schiefler", "<br>Schiefler").replace("Willoch", "<br>Willoch").replace("Presler", "<br>Presler");
            return inputString.split("<br>");
        }

        return [catRais];
    }
}
