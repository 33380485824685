import * as React from "react";
import {TestComponentProps} from "./TestComponentProps.csharp";
import {ResponsivePicture} from "../../ReactComponents/responsivePicture/responsivePicture";

export class TestComponent extends React.Component<TestComponentProps, {isDescriptionVisible: boolean, buttonLabel: string, isMagicEnabled: boolean}> {
    private static readonly HideLabel = "Hide Description";
    private static readonly ShowLabel = "Show Description";
    
    
    constructor(props: TestComponentProps) {
        super(props);

        this.state = {
            isDescriptionVisible: this.props.model.isDescriptionVisible,
            buttonLabel: this.props.model.isDescriptionVisible ? TestComponent.HideLabel: TestComponent.ShowLabel,
            isMagicEnabled: false
        };

        this.toggleDescriptionVisibility = this.toggleDescriptionVisibility.bind(this);
    }
    
    render() {
        const props = this.props;
        console.log(props);
        const magic = this.state.isMagicEnabled ? 
            <div className="TestComponent__magic"><ResponsivePicture profile={props.profile} model={props.model.magic}/></div>:"";
        const description = this.state.isDescriptionVisible ? (<><p>{props.model.description}</p>{magic}</>) : "";
        return (<div className="TestComponent">
            <h1>{props.model.title}</h1>
            <button onClick={this.toggleDescriptionVisibility}>{this.state.buttonLabel}</button>
            {description}
        </div>);
    }

    private toggleDescriptionVisibility(): void {
        const newButtonLabel = this.state.isDescriptionVisible ? TestComponent.ShowLabel : TestComponent.HideLabel;
        this.setState({
            isDescriptionVisible: !this.state.isDescriptionVisible,
            buttonLabel: newButtonLabel,
            isMagicEnabled: this.state.isMagicEnabled || !this.state.isDescriptionVisible            
        });
    }
}