import { useEffect } from "react";

const useEvent = (
  eventName = '',
  eventHandler?: (event: Event) => void,
  dependencies: any[] = []
) => {
  useEffect(() => {
    const handler = (event: Event) => {
      if (eventHandler) {
        eventHandler(event);
      }
    };

    window.addEventListener(eventName, handler);
    return () => window.removeEventListener(eventName, handler);
  }, dependencies);
};

export default useEvent;