import * as React from "react";

interface RangeSelectorProps {
    maxValue: number;
    minValue: number;
    step: number;
    value: number[];
    onChange?: (newValue: number[]) => void;
}

export class RangeSelector extends React.Component<RangeSelectorProps> {

    constructor(props: RangeSelectorProps) {
        super(props);

        this.decreaseValue = this.decreaseValue.bind(this);
        this.increaseValue = this.increaseValue.bind(this);
    }

    render() {
        if(this.props.value.length !== 2) { throw "invalid argument";}

        const previousEnabled = this.previousMinValue < this.props.value[0];
        
        const nextEnabled = this.nextMaxValue > this.props.value[1];
        
        
        return <div className="RangeSelector">
            {previousEnabled && <div><button className="RangeSelectorButton--previous button--secondary button--previous"
                                        onClick={this.decreaseValue}>
                {this.previousMinValue} &ndash; {this.props.value[0]-1}
            </button></div>}

            <div className="RangeSelector__currentValue">{this.props.value[0]} &ndash; {this.props.value[1]}</div>
            
            {nextEnabled &&<div><button className="RangeSelectorButton--next button--secondary button--next"
                                    onClick={this.increaseValue}>
                {this.props.value[1]+1} &ndash; {this.nextMaxValue}
            </button></div>}
        </div>;
    }

    private decreaseValue() {
        this.props.onChange && this.props.onChange([this.previousMinValue, this.props.value[0]-1]);
    }

    private increaseValue() {
        this.props.onChange && this.props.onChange([this.props.value[1]+1, this.nextMaxValue]);
    }

    private get previousMinValue()
    {
        let newMin = Math.floor((this.props.value[0]/this.props.step)-1)*this.props.step;
        
        if(newMin - this.props.minValue < this.props.step - 1) {
            newMin = this.props.minValue;
        }
        
        return Math.max(newMin, this.props.minValue);
    }
    
    private get nextMaxValue() {
        let newMax = Math.ceil((this.props.value[1]) / this.props.step+1)*this.props.step-1;

        if(this.props.maxValue - newMax < this.props.step - 1) {
            newMax = this.props.maxValue;
        }

        return Math.min(newMax, this.props.maxValue);
    }
}