import React from "react";
import { IncrementSelector } from "../IncrementSelector/IncrementSelector";
import { graphQlRequestBodyString } from "../../Product/graphQlRequestBodyString";
import { ErrorMessage } from "../ErrorMessage";
import { formatTime } from "../../Ticket/utils/formatTime";

import moment from "moment";
import cn from "classnames";

interface CartItemsProps {
    cartId?: string;
    sizeLabel: string;
    errorMessage: string;
    graphQlEndpoint: string;
    endpointGuestCartItems: string;
    endpointMemberCartItems: string;
    accessToken: string;
    currencyFormat: string;
    productCount?: number;
    currentLanguage?: string,
    products: {
        id: number;
        uid: string;
        product: {
            sku: string;
            stock_status: string;
            name: string;
            small_image: {
                label: string;
                url: string;
            };
            price_range: {
                maximum_price: {
                    final_price: {
                        currency: string;
                        value: number;
                    };
                };
            };
        };
        prices: {
            row_total_including_tax: {
                value: number;
            };
        };
        configurable_options?: { value_label: string }[];
        quantity: number;
    }[];
    tickets: {
        item_id: number;
        sku: string;
        name: string;
        qty: number;
        price: number;
        product_type: string;
        extension_attributes?: {
            ticket_purchase?: {
                admissions: {
                    start_utc_timestamp: number;
                    end_utc_timestamp: number;
                }[];
            };
            membership_purchase?: {
                extension_attributes: {
                    change_membership?: {
                        period_end?: string;
                    };
                };
            };
        };
    }[];
    setCartIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setCart?: React.Dispatch<React.SetStateAction<any[]>>;
    setNumberOfProducts?: React.Dispatch<React.SetStateAction<number>>;
    setTickets: React.Dispatch<React.SetStateAction<any[]>>;
    isCheckout?: boolean;
}

export const CartItems = ({
    cartId,
    sizeLabel,
    errorMessage,
    products,
    setCartIsOpen,
    graphQlEndpoint,
    setCart,
    setNumberOfProducts,
    tickets,
    setTickets,
    endpointGuestCartItems,
    endpointMemberCartItems,
    accessToken,
    isCheckout,
    currencyFormat,
    currentLanguage = '',
}: CartItemsProps) => {
    const [error, setError] = React.useState<number | string>("");

    const formatDate = (time: number) => {
        const date = new Date(time * 1000);

        function formatDateToNorwayLocal(date) {
            const options = { day: "2-digit", month: "2-digit", year: "numeric" };
            const norwayDate = date.toLocaleDateString("nb-NO", options);

            return norwayDate;
        }
        return formatDateToNorwayLocal(date);
    };

    const formatMembershipDate = (dateString: string) => {
        const date = moment(dateString).format("DD.MM.YYYY");
        return date;
    };

    const guestHeaders = {
        "content-type": "application/json",
        "store": currentLanguage
    };
    const memberHeaders = {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken} `,
        "store": currentLanguage
    };

    const guestHeadersRest = {
        "content-type": "application/json",
    };
    const memberHeadersRest = {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken} `,
    };

    const changeTicketCount = async (
        id: number,
        sku: string,
        productId: string | number,
        count: number
    ) => {
        var resolvedCartItemsEndpoint = accessToken ? `${endpointMemberCartItems}` : `${endpointGuestCartItems}`;
        // If user clicks minus button when count is 1, remove product from cart
        if (count === 0) {
            const delOptions = {
                method: "DELETE",
                headers: accessToken ? memberHeadersRest : guestHeadersRest,
            };

            const delResponse = await fetch(`${resolvedCartItemsEndpoint}/${productId}`, delOptions);
            const delData = await delResponse.json();
        } else {
            // Update quantity

            const body = {
                cartItem: {
                    sku: sku,
                    qty: count,
                },
            };

            const putOptions = {
                method: "PUT",
                headers: accessToken ? memberHeadersRest : guestHeadersRest,
                body: JSON.stringify(body),
            };

            const putResponse = await fetch(`${resolvedCartItemsEndpoint}/${productId}`, putOptions);
            const putData = await putResponse.json();
        }

        // Get cart items after quantity update/removed

        const getOptions = {
            method: "GET",
            headers: accessToken ? memberHeadersRest : guestHeadersRest,
        };

        const getResponse = await fetch(`${resolvedCartItemsEndpoint}`, getOptions);
        const getData = await getResponse.json();

        const ticketsWithoutProducts = getData.filter(
            (item) =>
                item.product_type === "ticket" ||
                item.product_type == "membership_renew" ||
                item.product_type == "membership" ||
                item.product_type == "voucher"
        );

        setTickets(ticketsWithoutProducts);
        setNumberOfProducts && setNumberOfProducts(getData.reduce((partialSum, a) => partialSum + a.qty, 0));
    };
    const changeCount = async (id: number, sku: string, productId: string | number, count: number) => {
        const graphqlQuery = {
            operationName: "updateCartItems",
            query: `mutation {
                updateCartItems(
                  input: {
                    cart_id: "${cartId}"
                    cart_items: [
                      {
                       
                          quantity: ${count}
                          cart_item_uid: "${productId}"
                        
                      }
                    ]
                  }
                ) {
                    cart {
                       ${graphQlRequestBodyString} 
                    }
                   
                }
              }`,
            variables: {},
        };

        const options = {
            method: "POST",
            headers: accessToken ? memberHeaders : guestHeaders,
            body: JSON.stringify(graphqlQuery),
        };

        const response = await fetch(graphQlEndpoint, options);
        const data = await response.json();

        if (data.data.updateCartItems === null || !response.ok) {
            setError(productId);
        } else {
            setError("");

            const productsWithoutTickets = data.data.updateCartItems.cart.items.filter((item) => item !== null);
            setCart(productsWithoutTickets);
            setNumberOfProducts &&
                setNumberOfProducts(
                    productsWithoutTickets.reduce((partialSum, a) => partialSum + a.quantity, 0) +
                        tickets.reduce((partialSum, a) => partialSum + a.qty, 0)
                );
            setCartIsOpen && setCartIsOpen(true);
        }
    };

    return (
        <>
            <ul className={cn("Cart__items", { "Cart__items--is-checkout": isCheckout })}>
                {products &&
                    products.length !== 0 &&
                    products.map(
                        (item, index) =>
                            item.product && (
                                <li className="Cart__item" key={item.uid+index}>
                                    <div className="Cart__item-info-container">
                                        <div className="Cart__item-info">
                                            <h2 className="Cart__item-title">{item.product.name}</h2>
                                            {item.configurable_options &&
                                                item.configurable_options.length !== 0 &&
                                                sizeLabel && (
                                                    <div className="Cart__item-size">
                                                        <span>{sizeLabel}: </span>
                                                        {item.configurable_options[0].value_label}
                                                    </div>
                                                )}

                                            <div className="Cart__item-counter">
                                                <IncrementSelector
                                                    count={item.quantity}
                                                    id={parseInt(item.product.sku)}
                                                    name={item.product.name}
                                                    first={index === 0}
                                                    reduceAriaLabel="reduce"
                                                    increaseAriaLabel="increase"
                                                    changeCount={changeCount}
                                                    sku={item.product.sku}
                                                    productId={item.uid}
                                                />
                                            </div>
                                        </div>

                                        <div className="Cart__item-image-and-price">
                                            <img
                                                className="Cart__item-image"
                                                src={item.product.small_image.url}
                                                alt={item.product.small_image.label}
                                            ></img>

                                            <div className="Cart__item-price">
                                                <span>
                                                    {currencyFormat.replace(
                                                        "price",
                                                        Math.round(item.prices.row_total_including_tax.value).toString()
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {error && error === item.uid && (
                                        <div className="Cart__item-error">
                                            <ErrorMessage text={errorMessage} />
                                        </div>
                                    )}
                                </li>
                            )
                    )}

                {tickets &&
                    tickets.length !== 0 &&
                    tickets.map((item, index) => (
                        <li className="Cart__item" key={item.sku+index}>
                            <div className="Cart__item-info-container">
                                <div className="Cart__item-info">
                                    <h2 className="Cart__item-title">{item.name}</h2>
                                    {item.product_type === "ticket" &&
                                        item.extension_attributes.ticket_purchase.admissions &&
                                        item.extension_attributes.ticket_purchase.admissions.length !== 0 && (
                                            <div className="Cart__item-ticket-date">
                                                <span>
                                                    {formatDate(
                                                        item.extension_attributes.ticket_purchase.admissions[0]
                                                            .start_utc_timestamp
                                                    )}
                                                </span>
                                                <span>
                                                    {formatTime(
                                                        item.extension_attributes.ticket_purchase.admissions[0]
                                                            .start_utc_timestamp
                                                    ) +
                                                        " - " +
                                                        formatTime(
                                                            item.extension_attributes.ticket_purchase.admissions[0]
                                                                .end_utc_timestamp
                                                        )}
                                                </span>
                                            </div>
                                        )}

                                    {item.product_type === "membership_renew" && (
                                        <div className="Cart__item-ticket-date">
                                            <span>
                                                {"Gyldig til: " +
                                                    formatMembershipDate(
                                                        item.extension_attributes.membership_purchase
                                                            .extension_attributes.change_membership.period_end
                                                    )}
                                            </span>
                                        </div>
                                    )}

                                    <div className="Cart__item-counter">
                                        <IncrementSelector
                                            count={item.qty}
                                            id={parseInt(item.sku)}
                                            name={item.name}
                                            first={index === 0}
                                            reduceAriaLabel="reduce"
                                            increaseAriaLabel="increase"
                                            changeCount={changeTicketCount}
                                            sku={item.sku}
                                            productId={item.item_id}
                                        />
                                    </div>
                                </div>

                                <div className="Cart__item-image-and-price">
                                    {/* TO DO: Get list from backend to match correct color theme to tickettype */}
                                    {item.product_type === "ticket" ? (
                                        <div
                                            className={
                                                item.sku === "30069V"
                                                    ? "Cart__item-ticket-image Cart__item-ticket-image--adult"
                                                    : item.sku === "30069U"
                                                    ? "Cart__item-ticket-image Cart__item-ticket-image--young-adult"
                                                    : item.sku === "30069B"
                                                    ? "Cart__item-ticket-image Cart__item-ticket-image--child"
                                                    : item.sku === "30069L"
                                                    ? "Cart__item-ticket-image Cart__item-ticket-image--companion"
                                                    : "Cart__item-ticket-image Cart__item-ticket-image--adult"
                                            }
                                        >
                                            <span>M</span>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                item.product_type === "voucher"
                                                    ? "Cart__item-ticket-image Cart__item-ticket-image--voucher"
                                                    : "Cart__item-ticket-image Cart__item-ticket-image--membership"
                                            }
                                        >
                                            <span>M</span>
                                        </div>
                                    )}

                                    <div className="Cart__item-price">
                                        <span>
                                            {item.product_type !== "voucher"
                                                ? currencyFormat.replace(
                                                      "price",
                                                      Math.round(item.price * 1.12).toString()
                                                  )
                                                : currencyFormat.replace("price", item.price.toString())}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>
        </>
    );
};
