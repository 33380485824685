import React, { useEffect, SetStateAction, Dispatch, useState, useRef } from "react";
import { ITicket, ITimeSlot } from "../TicketPage";
import { formatTime } from "../utils/formatTime";
import { totalPrice } from "../utils/totalPrice";
import { ToggleButton } from "../../Shared/ToggleButton/ToggleButton";
import { NextButton } from "../../Shared/NextButton/NextButton";
import { Loader } from "../../Shared/Loader";
import { MessageBox } from "../../Shared/MessageBox/MessageBox";
import { IncrementSelector } from "../../Shared/IncrementSelector/IncrementSelector";
import classNames from "classnames";
import SignLink, { ISignLink } from "../../../../Partials/SignLink/SignLink";

interface ITicketBox {
    data: ITicket;
    numberLabel: string,
    changeCount: (id: number, sku: string, productId: string | number, count: number) => void;
    currencyText: string;
    first: boolean;
    reduceAriaLabel: string;
    increaseAriaLabel: string;
    freeText: string;
    normalDesktopLayout: boolean;
    normalMobileLayout: boolean;
    disablePlus: boolean;
}

interface ITicketAmountSelector {
    ticketData: ITicket[];
    setTicketData: Dispatch<SetStateAction<ITicket[]>>;
    editorialProps: {
        ticketSaleTitle: string;
        accompanyingHint: string;
        strollerHint: string;
        totalAmountText: string;
        memberLoginHint: string;
        memberLogoutHint: string;
        memberLoginLink: string;
        memberLoginLinkText: string;
        selectDateTimeButtonText: string;
        currencyText: string;
        endpoint: string;
        entryNowButtonText: string;
        entryLaterButtonText: string;
        express: boolean;
        firstAvailableButtonText: string;
        buyMoreTicketsNoButtonText?: string;
        buyMoreTicketsText?: string;
        buyMoreTicketsYesButtonText?: string;
        reduceAriaLabel: string;
        increaseAriaLabel: string;
        maxTotalTickets: number;
        maxTotalTicketsExceededError: string;
        confirmButtonNoTimeSlotsText?: string;
        loginLinkText?: string;
        logoutLinkText?: string;
        loginHint?: string;
        logoutHint?: string;
        numberLabel?: string;
    };
    setDatePickerIsOpen: Dispatch<SetStateAction<boolean>>;
    setIsExpress: Dispatch<SetStateAction<boolean>>;
    setSelectedTime: Dispatch<SetStateAction<ITimeSlot>>;
    setSelectedDate: Dispatch<SetStateAction<Date>>;
    numberOfTickets: number;
    isMember: boolean;
    availableTimeSlots: ITimeSlot[];
    normalTicketId: number;
    isLoading: boolean;
    isSoldOut: boolean;
    soldOutText: string;
    isExpired: boolean;
    expiredText: string;
    notExists: boolean;
    notExistsText: string;
    freeText: string;
    noDateAndScheduleRequired?: boolean;
    setShowSummary: Dispatch<SetStateAction<boolean>>;
    availableDates?: Date[];
    ticketEventProps?: {
        alreadyMemberText: string;
        isLoggedIn: boolean;
    };
    signLink?: ISignLink;
}

export const TicketAmountSelector = ({
    ticketData,
    setTicketData,
    editorialProps,
    setDatePickerIsOpen,
    setIsExpress,
    setSelectedDate,
    setSelectedTime,
    numberOfTickets,
    isMember,
    availableTimeSlots,
    normalTicketId,
    isLoading,
    isSoldOut,
    soldOutText,
    isExpired,
    expiredText,
    notExists,
    notExistsText,
    freeText,
    noDateAndScheduleRequired,
    setShowSummary,
    availableDates,
    ticketEventProps,
    signLink,
}: ITicketAmountSelector) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [currentPagePath, setCurrentPagePath] = useState("");

    const normalDesktopLayout = ticketData.every((data) => data.name.length <= 15);
    const normalMobileLayout = ticketData.every((data) => data.name.length <= 10);

    const calculateTickets = (tickets) => {
        let totalCount = 0;

        tickets.forEach(ticket => {
            totalCount += ticket.count;
        });

        return totalCount;
    }
    
    const countIsCapped =
        editorialProps.maxTotalTickets > 0 &&
        calculateTickets(ticketData) >= editorialProps.maxTotalTickets;


    let firstAvailableTimeSlot = null;
    let isExpressPageAndHasAvailableTimeSlot = null;
    const ref = useRef(null);
    if (availableTimeSlots !== null && availableTimeSlots.length > 0) {
        firstAvailableTimeSlot = availableTimeSlots.find((timeSlot) => timeSlot.remaining_slots >= numberOfTickets);

        isExpressPageAndHasAvailableTimeSlot = !!(editorialProps.express && firstAvailableTimeSlot);
    }
    const changeCount = (id: number, sku: string, productId: string | number, count: number) => {
        setTicketData((curr: ITicket[]) => {
            const data = curr;
            const index = data.findIndex((ticket: ITicket) => ticket.id === id);
            data[index].count = count;
            return [...data];
        });
    };

    const isLedsager =
        ticketData.find((ticket: ITicket) => ticket.sku.toUpperCase().includes("L")) &&
        ticketData.find((ticket: ITicket) => ticket.sku.toUpperCase().includes("L")).count !== 0;

    const isChildTicket =
        ticketData.find((ticket: ITicket) => ticket.sku.toUpperCase().includes("B")) &&
        ticketData.find((ticket: ITicket) => ticket.sku.toUpperCase().includes("B")).count !== 0;

    const onChange = (value: string) => {
        setIsOpen(value === editorialProps.buyMoreTicketsYesButtonText);
        setDatePickerIsOpen(value !== editorialProps.buyMoreTicketsYesButtonText);
        setTicketData(
            ticketData.map((ticket) =>
                ticket.package_product_id === normalTicketId ? { ...ticket, count: 0 } : ticket
            )
        );
    };

    const logoutHint = editorialProps.logoutHint ? editorialProps.logoutHint : "";
    const loginHint = editorialProps.loginHint ? editorialProps.loginHint : "";

    useEffect(() => {
        if (ref.current) {
            setTimeout(() => ref.current.scrollIntoView({ behavior: "smooth" }), 300);
        }
    }, [ref]);

    useEffect(() => {
        const dependantTickets = ticketData.filter((ticket) => ticket.requires.length > 0);
        if (dependantTickets.length > 0) {
            const data = ticketData;
            dependantTickets.forEach((depTicket) => {
                if (!data.some((ticket) => depTicket.requires.includes(ticket.id) && ticket.count > 0)) {
                    const index = data.findIndex((ticket) => ticket.id === depTicket.id);
                    data[index].count = 0;
                }
            });
            setTicketData(data);
        }
    }, [ticketData]);

    React.useEffect(() => {
        if (window) {
            const currentURL = new URL(window.location.href);
            const pathnameWithQuery = currentURL.pathname + currentURL.search;
            setCurrentPagePath(pathnameWithQuery ? pathnameWithQuery : "");
        }
    }, []);

    return (
        <>
            {isSoldOut && (
                <p className="TicketAmountSelector__errormessage">{soldOutText}</p>
            )}

            {isExpired && (
                <p className="TicketAmountSelector__errormessage">{expiredText}</p>
            )}

            {notExists && (
                <p className="TicketAmountSelector__errormessage">{notExistsText}</p>
            )}

            {!isSoldOut && !isExpired && !notExists && isMember && (
                <ToggleButton
                    header={editorialProps.buyMoreTicketsText}
                    row={true}
                    values={[editorialProps.buyMoreTicketsYesButtonText, editorialProps.buyMoreTicketsNoButtonText]}
                    onChange={onChange}
                    forwardRef={ref}
                />
            )}
            {(!isSoldOut && !isExpired && !notExists && (!isMember || isOpen)) && (
                <>
                    <ul
                        className={classNames("TicketAmountSelector__list", {
                            "TicketAmountSelector__list--desktop-secondary-layout": !normalDesktopLayout,
                            "TicketAmountSelector__list--mobile-secondary-layout": !normalMobileLayout,
                        })}
                    >
                        {isLoading ? (
                            <Loader />
                        ) : (
                            ticketData
                                .filter((ticket: ITicket) => normalTicketId === ticket.package_product_id)
                                .map((data, index) => {
                                    const disablePlus =
                                        data.requires.length > 0 &&
                                        !ticketData.some(
                                            (ticket) => data.requires.includes(ticket.id) && ticket.count > 0
                                        );

                                    return (
                                        <TicketBox
                                            data={data}
                                            changeCount={changeCount}
                                            currencyText={editorialProps.currencyText}
                                            first={index === 0}
                                            key={data.id}
                                            numberLabel={editorialProps.numberLabel}
                                            reduceAriaLabel={editorialProps.reduceAriaLabel}
                                            increaseAriaLabel={editorialProps.increaseAriaLabel}
                                            freeText={freeText}
                                            normalDesktopLayout={normalDesktopLayout}
                                            disablePlus={disablePlus || countIsCapped}
                                            normalMobileLayout={normalMobileLayout}
                                        />
                                    );
                                })
                        )}
                    </ul>
                    <div className="TicketAmountSelector__totalamount">
                        <p>{editorialProps.totalAmountText}</p>
                        <p>
                            {totalPrice(ticketData)} {editorialProps.currencyText}
                        </p>
                    </div>
                    <div role="alert">
                        {countIsCapped ? (
                            <MessageBox text={editorialProps.maxTotalTicketsExceededError} />
                        ) : isLedsager && isChildTicket ? (
                                <MessageBox text={[
                                    editorialProps.accompanyingHint,
                                    editorialProps.strollerHint
                                ]} />
                        ) : isLedsager ? (
                            <MessageBox text={editorialProps.accompanyingHint} />
                        ) : isChildTicket ? (
                            <MessageBox text={editorialProps.strollerHint} />
                        ) : null}
                    </div>

                    {signLink && (
                        <div className="TicketAmountSelector__login__container">
                            {((signLink.isSignedIn && logoutHint) || (!signLink.isSignedIn && loginHint)) && (
                                <div
                                    className="TicketAmountSelector__login-text"
                                    dangerouslySetInnerHTML={{
                                        __html: signLink.isSignedIn ? logoutHint : loginHint,
                                    }}
                                ></div>
                            )}

                            {((signLink.isSignedIn && editorialProps.logoutLinkText) ||
                                (!signLink.isSignedIn && editorialProps.loginLinkText)) && (
                                <SignLink
                                    {...signLink}
                                    signInLabel={editorialProps.loginLinkText}
                                    signOutLabel={editorialProps.logoutLinkText}
                                    returnUrl={signLink.returnUrl ? signLink.returnUrl : currentPagePath}
                                    className="TicketAmountSelector__login-link"
                                />
                            )}
                        </div>
                    )}

                    {ticketData.some((data) => data.count !== 0) && (
                        <NextButton
                            showTwo={isExpressPageAndHasAvailableTimeSlot}
                            mainButtonClick={() => {
                                setIsExpress(false);
                                setDatePickerIsOpen(true);
                                if (!noDateAndScheduleRequired) {
                                    setShowSummary(false);
                                    setSelectedTime(null);
                                    if (availableDates?.length !== 1) setSelectedDate(null);
                                } else {
                                    setShowSummary(true);
                                }
                            }}
                            secondaryButtonText={
                                firstAvailableTimeSlot === availableTimeSlots[0]
                                    ? editorialProps.entryNowButtonText
                                    : editorialProps.firstAvailableButtonText +
                                      formatTime(firstAvailableTimeSlot?.start_utc_timestamp)
                            }
                            secondaryButtonclick={() => {
                                setIsExpress(true);
                                setDatePickerIsOpen(false);
                                setSelectedDate(new Date());
                            }}
                            mainButtonText={
                                noDateAndScheduleRequired
                                    ? editorialProps.confirmButtonNoTimeSlotsText
                                    : isExpressPageAndHasAvailableTimeSlot
                                    ? editorialProps.entryLaterButtonText
                                    : editorialProps.selectDateTimeButtonText
                            }
                        />
                    )}
                </>
            )}
        </>
    );
};

const TicketBox = ({
    data,
    numberLabel,
    changeCount,
    currencyText,
    first,
    reduceAriaLabel,
    increaseAriaLabel,
    freeText,
    normalDesktopLayout,
    normalMobileLayout,
    disablePlus,
}: ITicketBox) => {
    return (
        <li>
            <div
                className={classNames("TicketAmountSelector__label__wrapper", {
                    "TicketAmountSelector__label__wrapper--desktop-secondary-layout": !normalDesktopLayout,
                    "TicketAmountSelector__label__wrapper--mobile-secondary-layout": !normalMobileLayout,
                })}
                id={data.name.replace(" ", "_")}
                aria-label={`${data.name}, ${
                    data.brackets[0].price_incl_vat === 0
                        ? freeText
                        : Math.round(data.brackets[0].price_incl_vat) + " " + currencyText
                }`}
            >
                <span className="TicketAmountSelector__label">{data.name}</span>
                {
                    <span
                        className={classNames("TicketAmountSelector__price", {
                            "TicketAmountSelector__price--hide": !normalDesktopLayout,
                            "TicketAmountSelector__price--hide-mobile": !normalMobileLayout,
                        })}
                    >
                        {data.brackets[0].price_incl_vat === 0
                            ? freeText
                            : Math.round(data.brackets[0].price_incl_vat) + " " + currencyText}
                    </span>
                }
            </div>
            <div className="IncrementSelector__container">
                {
                    <span
                        className={classNames("TicketAmountSelector__price", {
                            "TicketAmountSelector__price--hide": normalMobileLayout,
                            "TicketAmountSelector__price--mobile": !normalMobileLayout && normalDesktopLayout,
                        })}
                    >
                        {data.brackets[0].price_incl_vat === 0
                            ? freeText
                            : Math.round(data.brackets[0].price_incl_vat) + " " + currencyText}
                    </span>
                }
                <IncrementSelector
                    count={data.count}
                    numberLabel={numberLabel}
                    id={data.id}
                    name={data.name}
                    changeCount={changeCount}
                    first={first}
                    reduceAriaLabel={reduceAriaLabel}
                    increaseAriaLabel={increaseAriaLabel}
                    hasNormalDesktopLayout={normalDesktopLayout}
                    hasNormalMobileLayout={normalMobileLayout}
                    disableMinus={data.count === data.qty.min}
                    disablePlus={data.count === data.qty.max || disablePlus}
                    min={data.qty.min}
                    max={data.qty.max}
                    enableNumberInput={true}
                />
            </div>
        </li>
    );
};
