import React from "react";
import cn from "classnames";

interface CartTotalsProps {
    subTotal?: string | number;
    grandTotal: string | number;
    shipping?: string | number;
    tax?: string | number;
    discount?: {
        label?: string;
        value?: string;
    };
    voucher?: {
        label?: string;
        value?: string;
    };
    discountCoupon?: {
        label?: string;
        value?: string;
    };
    subTotalLabel?: string;
    totalLabel?: string;
    shippingLabel?: string;
    taxText?: string;
    currencyFormat: string;
}

export const CartTotals = ({
    subTotal,
    grandTotal,
    discount,
    voucher,
    discountCoupon,
    tax,
    shipping,
    subTotalLabel,
    totalLabel,
    shippingLabel,
    taxText,
    currencyFormat,
}: CartTotalsProps) => {
    const formattedPrice = (price) => {
        const priceWithFormat = currencyFormat.replace("price", price);
        return priceWithFormat;
    };
    return (
        <dl className="CartTotals">
            {!!subTotal && subTotal !== grandTotal && (
                <>
                    <dt className="CartTotals__label">{subTotalLabel}</dt>
                    <dd className="CartTotals__value">{formattedPrice(subTotal)}</dd>
                </>
            )}

            {!!shipping && (
                <>
                    <dt className="CartTotals__label">{shippingLabel}</dt>
                    <dd className="CartTotals__value">{formattedPrice(shipping)}</dd>
                </>
            )}

            {discount && discount.label && discount.value && (
                <>
                    <dt className="CartTotals__label CartTotals__label--discount">{discount.label}</dt>
                    <dd className="CartTotals__value">{formattedPrice(discount.value)}</dd>
                </>
            )}

            {discountCoupon && discountCoupon.label && discountCoupon.value && (
                <>
                    <dt className="CartTotals__label CartTotals__label--discount">{discountCoupon.label}</dt>
                    <dd className="CartTotals__value">{formattedPrice(discountCoupon.value)}</dd>
                </>
            )}

            {voucher && voucher.label && voucher.value && (
                <>
                    <dt className="CartTotals__label CartTotals__label--discount">{voucher.label}</dt>
                    <dd className="CartTotals__value">{formattedPrice(voucher.value)}</dd>
                </>
            )}

            <>
                <dt
                    className={cn("CartTotals__label CartTotals__label--total", {
                        "CartTotals__label CartTotals__label--total CartTotals__label--has-tax": tax,
                    })}
                >
                    {totalLabel}
                </dt>
                <dd
                    className={cn("CartTotals__value CartTotals__value--total", {
                        "CartTotals__value CartTotals__value--total CartTotals__value--has-tax": tax,
                    })}
                >
                    {formattedPrice(grandTotal)}
                </dd>

                {tax && taxText && grandTotal !== 0 && (
                    <>
                        <dt className="CartTotals__label CartTotals__label"></dt>
                        <dd className="CartTotals__value CartTotals__value--tax">
                            {taxText.replace("{0}", tax.toString())}
                        </dd>
                    </>
                )}
            </>
        </dl>
    );
};
