import React from "react";
import { RefObject } from "react";
function useAnimateHeight<T extends HTMLElement = HTMLElement>(isOpen: Boolean, setHeight: any, ref: RefObject<T>) {
    React.useEffect(() => {
        if (isOpen) {
            setHeight(ref.current?.getBoundingClientRect().height);
        } else {
            setHeight(0);
        }
    }, [isOpen, setHeight, ref]);
}
export default useAnimateHeight;
