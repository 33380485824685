import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import classNames from "classnames";
import CountrySelect from "./CountrySelect";
import { CountryCode } from "libphonenumber-js";
import PhoneInput from "react-phone-number-input/input";
import nb from "react-phone-number-input/locale/nb.json.js";

export interface IInput {
    type: "text" | "email" | "tel" | "password";
    isRequired: boolean;
    onChange: (e: any) => void;
    value: any;
    isBig: boolean;
    placeholder: any;
    autoComplete: string;
    label?: string;
    onBlur?: () => void;
    errorValidation?: boolean;
    errorValidationText?: string;
    showError?: boolean;
    setCountryCode?: Dispatch<SetStateAction<CountryCode>>;
    countryCode?: CountryCode;
    isDisabled ?: boolean;
}

export const Input = ({
    type,
    label,
    onChange,
    value,
    isRequired,
    onBlur,
    isBig,
    placeholder,
    autoComplete,
    errorValidation,
    errorValidationText,
    showError,
    setCountryCode,
    countryCode,
    isDisabled,
}: IInput) => {
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (onBlur && showError) onBlur();
    }, [value]);

    return (
        <div key={label} className={classNames("Input__Wrapper", { "Input__Wrapper--big": isBig })}>
            {label && (
                <label className="Input__Label" htmlFor={label}>
                    {label}
                    {isRequired && <span>*</span>}
                </label>
            )}
            {type === "tel" && countryCode ? (
                <div
                    className={classNames("PhoneInput__Container", {
                        "PhoneInput__Container--error": isRequired && errorValidation,
                    })}
                    onBlur={onBlur}
                >
                    <CountrySelect labels={nb} value={countryCode} onChange={setCountryCode} />
                    <PhoneInput
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        country={countryCode}
                        id={label}
                    />
                </div>
            ) : type === "password" ? (
                <div className="InputPassword-wrapper">
                    <input
                        className={classNames("InputField", {
                            "InputField--error": errorValidation,
                        })}
                        id={label}
                        name={label}
                        type={showPassword ? "text" : "password"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder={placeholder}
                        onBlur={onBlur}
                        autoComplete={autoComplete}
                    />
                    {value !== "" && (
                        <button
                            type="button"
                            className="password-toggle-button"
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}
                        >
                            {showPassword ? "skjul" : "vis"}
                        </button>
                    )}
                </div>
            ) : (
                <input
                    className={classNames("InputField", {
                        "InputField--error": isRequired && errorValidation,
                    })}
                    type={type}
                    id={label}
                    name={label}
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    required={isRequired}
                    aria-required={isRequired}
                    onBlur={onBlur}
                    disabled={isDisabled ? isDisabled : null}
                />
            )}
            {errorValidation && (
                <div role="alert" className="InputField__validation-error">
                    {errorValidationText}
                </div>
            )}
        </div>
    );
};
