import React, { Dispatch, SetStateAction, KeyboardEvent } from "react";
import classNames from "classnames";
export interface ICheckBox {
    label: string;
    isChecked: boolean;
    setIsChecked: Dispatch<SetStateAction<boolean>>;
}
export const CheckBox = ({ label, isChecked, setIsChecked }: ICheckBox) => {
    const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            setIsChecked(!isChecked);
        }
    };
    return (
        <div className="CheckBox__container">
            <div
                className="CheckBox__checkbox"
                tabIndex={0}
                aria-labelledby={"newsletter"}
                aria-checked={isChecked}
                onKeyDown={(e) => onKeyDown(e)}
            >
                <span
                    className={classNames("CheckBox__checkbox__icon", {
                        "CheckBox__checkbox__icon--checked": isChecked,
                    })}
                />
                <input
                    id="newsletter"
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => {
                        setIsChecked(!isChecked);
                    }}
                    tabIndex={-1}
                />
            </div>
            <label htmlFor="newsletter" className="CheckBox__label">
                {label}
            </label>
        </div>
    );
};
