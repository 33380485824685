import React, { useState } from "react";
import classNames from "classnames";

interface INextButton {
    showTwo: boolean;
    mainButtonClick: () => void;
    mainButtonText: string;
    secondaryButtonclick?: () => void;
    secondaryButtonText?: string;
    disabled?: boolean;
}

export const NextButton = ({
    showTwo,
    mainButtonClick,
    secondaryButtonclick,
    mainButtonText,
    secondaryButtonText,
    disabled,
}: INextButton) => {
    const [buttonState, setButtonState] = useState<number>();
    return (
        <div className="next-button__container">
            {showTwo && (
                <button
                    className={classNames("next-button button--primary button--forward button--red", {
                        "next-button--clicked": buttonState === 0,
                    })}
                    onClick={() => {
                        secondaryButtonclick();
                        setButtonState(0);
                    }}
                >
                    <span>{secondaryButtonText}</span>
                </button>
            )}

            <button
                type="button"
                className={classNames(
                    !showTwo
                        ? "next-button button--primary button--forward button--red"
                        : "next-button-secondary button--secondary",
                    {
                        "next-button--clicked": !showTwo && buttonState === 1,
                        "next-button-secondary--clicked": showTwo && buttonState === 1,
                    }
                )}
                onClick={() => {
                    mainButtonClick();
                    setButtonState(1);
                }}
                disabled={disabled}
            >
                <span>{mainButtonText}</span>
            </button>
        </div>
    );
};
