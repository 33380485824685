import * as React from "react";
import classNames from "classnames";
import { ResponsivePicture } from "../../ReactComponents/responsivePicture/responsivePicture";
import { ImageCarouselProps } from "./ImageCarouselProps.csharp";
import { Swipeable } from 'react-swipeable';

export class ImageCarousel extends React.Component<
    ImageCarouselProps,
    { currentImageIndex: number }
> {
    constructor(props: ImageCarouselProps) {
        super(props);
        
        this.state = {
            currentImageIndex: 0
        };
        
        this.nextImage = this.nextImage.bind(this);
        this.prevImage = this.prevImage.bind(this);
    }
    
    render() {
        const [profile, items, currentImageIndex] = [this.props.profile, this.props.model.items, this.state.currentImageIndex];
        
        return (
            <div className="ImageCarousel">
                <div className="ImageCarousel__inner">
                    <Swipeable className="ImageCarousel__image" onSwipedLeft={this.nextImage}
                               onSwipedRight={this.prevImage}>
                        <div className="ImageCarousel__controls ImageCarousel__controls--prev">
                            <button
                                className="ImageCarousel__button ImageCarousel__button--prev"
                                onClick={this.prevImage}
                                aria-label={this.props.translations.previous}
                            >
                            </button>
                        </div>
                        <div className="ImageCarousel__ratioContainer">
                            {items.map((item, index) =>
                                <div key={`ImageCarousel-${index}`}
                                    className={`ImageCarousel__ratioContent ${this.state.currentImageIndex == index ? "ImageCarousel__ratioContent--visible" : ""}`}>
                                    <ResponsivePicture
                                        profile={profile}
                                        model={items[index].picture}
                                        imgAttributes={{"data-object-fit": "contain"}}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="ImageCarousel__controls ImageCarousel__controls--next">
                            <button
                                className="ImageCarousel__button ImageCarousel__button--next"
                                onClick={this.nextImage}
                                aria-label={this.props.translations.next}
                            >
                            </button>
                        </div>
                    </Swipeable>
                    <div className={classNames("ImageCarousel__description",
                        {"ImageCarousel--hideDescription":!items[currentImageIndex].caption && !items[currentImageIndex].copyright}
                        )}>
                        <div className="ImageCarousel__copyrightInner">
                            {items[currentImageIndex].caption &&
                                <div className="ImageCarousel__caption">
                                    {items[currentImageIndex].caption}
                                </div>
                            }
                            {items[currentImageIndex].copyright &&
                                <div className="ImageCarousel__copyright">
                                    {items[currentImageIndex].copyright}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    nextImage(): void {
        this.setState({
            currentImageIndex: (this.state.currentImageIndex + 1) % this.props.model.items.length
        })
    }

    prevImage(): void {
        const newImageIndex: number = this.state.currentImageIndex === 0
            ? this.props.model.items.length - 1
            : this.state.currentImageIndex - 1;
        this.setState({
            currentImageIndex: (newImageIndex) % this.props.model.items.length
        })
    }
}
