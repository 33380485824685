import * as React from "react";
import CalendarPicker from "../../../ReactComponents/CalendarPicker/CalendarPicker";
import { SelectItemViewModel } from "./SelectItemViewModel.csharp";
import { EventCalendarTranslations } from "./EventCalendarTranslations.csharp";
import { CalendarLocalization } from "../../../ReactComponents/CalendarPicker/CalendarLocalization.csharp";
import Dropdown, { Option } from "react-dropdown";

export interface EventCalendarFiltersProps {
    title: string;
    translations: EventCalendarTranslations;
    currentCategory: string;
    onCategoryChange: (arg: Option) => void;
    isLoading: boolean;
    categories: SelectItemViewModel[];
    currentDate: Date;
    onDateChange: (date: Date) => void;
    localization: CalendarLocalization;
    totalResults?: number;
    isSearching?: boolean;
    categoryLabel?: string;
}

export const defaultSelectValue = "all";

export const EventCalendarFilters = (props: EventCalendarFiltersProps) => {
    const sortedCategories = props.categories.sort((a, b) => a.sortOrder - b.sortOrder);
    const categories = [{ value: defaultSelectValue, label: props.translations.showAllEvents }, ...sortedCategories];

    const handleResult = () => {
        let result = "";
        if (props.localization.cultureName === "en") {
            if (props.isSearching) {
                result += props.translations.totalResults + " ";
            }
            result += props.totalResults + " ";
            if (props.isSearching && props.totalResults == 1 && props.currentCategory !== "all") {
                result += " event in "
            } else if (props.isSearching && props.totalResults == 1 && props.currentCategory === "all") {
                result += " ";
            }

            else if (!props.isSearching) {
                result += props.translations.events.toLowerCase() + " "
            }

            if (props.isSearching && props.currentCategory !== "all") {
                if (props.totalResults == 1) {
                    result += " " + props.categoryLabel;
                } else {
                    result += props.translations.eventsIn.toLowerCase() + " " + props.categoryLabel;
                }

            }
        } else {
            if (props.isSearching) {
                result += props.translations.totalResults + " ";
            }
            result += props.totalResults + " ";
            if (props.isSearching && props.totalResults == 1 && props.currentCategory !== "all") {
                result += props.translations.eventsIn.toLowerCase()
            } else if (props.isSearching && props.totalResults == 1 && props.currentCategory === "all") {
                result += " ";
            }

            else if (!props.isSearching) {
                result += props.translations.events.toLowerCase() + " ";
            }

            if (props.isSearching && props.currentCategory !== "all") {
                if (props.totalResults == 1) {
                    result += " " + props.categoryLabel;
                } else {
                    result += props.translations.eventsIn.toLowerCase() + " " + props.categoryLabel;
                }

            }
        }

        return result;
    }
    return (
        <div className="EventCalendarFilters">
            <h1 className="EventCalendar__pageTitle">{props.title}</h1>
            <div className="EventCalendar__inner">
                <fieldset className="EventCalendar__control">
                    <label>{props.translations.categoryLabel}</label>
                    <div className="EventCalendar__filter">
                        <Dropdown
                            options={categories}
                            onChange={props.onCategoryChange}
                            disabled={props.isLoading}
                            value={props.currentCategory}
                            placeholder={props.translations.categoryFilterPlaceholder}
                        />
                    </div>
                </fieldset>
                <fieldset className="EventCalendar__control">
                    <label>{props.translations.dateLabel}</label>
                    <CalendarPicker
                        selectedValue={props.currentDate}
                        onDateChange={props.onDateChange}
                        disabled={props.isLoading}
                        localization={props.localization}
                        dateInputPlaceholder={props.translations.chooseDate}
                    />
                </fieldset>
            </div>
            <div className="EventCalendar__results">
                <div>
                    {handleResult()}
                </div>
            </div>
        </div>
    );
};
