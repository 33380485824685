//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ExhibitionType {
	Exhibition = 0,
	Performance = 1,
	NoMarking = 2,
	InteractiveExperience = 3
}
