import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import classNames from "classnames";
export interface IProductImages {
    images: {
        url: string;
        altText: string;
    }[];
}

export const ProductImages = ({ images }: IProductImages) => {
    const swiperRef = React.useRef<SwiperType>();
    const [activeIndex, setActiveIndex] = React.useState(0);
    return (
        <div>
            <ul className="ProductImages">
                {images.map((image, i) => (
                    <li
                        key={image.url + i}
                        className={classNames("ProductImages__image-container", {
                            "ProductImages__image-container--threeOrLess": images.length <= 3,
                            "ProductImages__image-container--fourOrFive": images.length === 4 || 5,
                            "ProductImages__image-container--sixOrMore": images.length > 5,
                        })}
                    >
                        <img
                            src={image.url}
                            alt={image.altText ? image.altText : ""}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/dist/assets/images/noImage.png";
                            }}
                        />
                    </li>
                ))}
            </ul>
            <div className="ProductImages--mobile">
                <Swiper
                    slidesPerView={1.15}
                    spaceBetween={16}
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    onSlideChange={(swiper) => {
                        const { activeIndex } = swiper;
                        setActiveIndex(activeIndex);
                    }}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={image.url + index}>
                            <div className="ProductImages__image-container">
                                <img
                                    src={image.url}
                                    alt={image.altText}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/dist/assets/images/noImage.png";
                                    }}
                                />
                                {images.length > 1 && (
                                    <div className="ProductImages__counter">
                                        {index + 1} / {images.length}
                                    </div>
                                )}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};
