import * as React from "react";
import {CardProps} from "./CardProps.csharp";
import {CardType} from "./CardType.csharp";
import {ArticleCard} from "../ArticleCard/ArticleCard";
import {ExhibitionCard} from "../ExhibitionCard/ExhibitionCard";
import {EventCard} from "../EventCard/EventCard";

export const Card = (props: CardProps) => {
    if(props.model.type === CardType.ArticleCard){
        return <ArticleCard {...props}/>;
    }
    else if (props.model.type === CardType.ExhibitionCard){
        return <ExhibitionCard {...props}/>;
    }
    else if (props.model.type === CardType.EventCard){
        return <EventCard {...props}/>;
    }
    throw "Unknown card type";
};