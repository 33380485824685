import React from "react";
import cn from "classnames";

export const BoxPanel = ({
    children,
    className,
    hasMobileSideMargin = true,
}: {
    children?: React.ReactNode;
    className?: string;
    hasMobileSideMargin?: boolean;
}) => {
    return (
        <div className={cn("BoxPanel", className)}>
            <div
                className={cn("BoxPanel__Inner", {
                    "BoxPanel__Inner--has-mobile-side-margin": hasMobileSideMargin,
                })}
            >
                {children}
            </div>
        </div>
    );
};
