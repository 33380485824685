import * as React from "react";
import { SliderItem } from "react-compound-slider/dist/types/types";
import { GetTrackProps } from "react-compound-slider/dist/types/Tracks/types";

export interface TrackProps {
    source: SliderItem;
    target: SliderItem;
    getTrackProps: GetTrackProps;
}

export const Track: React.FC<TrackProps> = ({ source, target, getTrackProps }) => {
    return (
        <div
            className="CollectionSearchRange__track"
            style={{
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps()}
        />
    );
};
