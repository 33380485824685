import * as React from "react";
import { SliderItem } from "react-compound-slider/dist/types/types";
import { GetHandleProps } from "react-compound-slider/dist/types/Handles/types";

export interface HandleProps {
    domain: number[];
    handle: SliderItem;
    getHandleProps: GetHandleProps;
}

export const Handle: React.FC<HandleProps> = ({
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps,
}) => {
    return <div className="CollectionSearchRange__handle" style={{ left: `${percent}%` }} {...getHandleProps(id)} />;
};
