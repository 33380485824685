import { postRequest } from "./post";
import { Dispatch, SetStateAction } from "react";
import { netsPayment } from "./netsPayment";
import { vippsPayment } from "./vippsPayment";
import { newsletterSignup } from "./newsletterSignup";
export const updatePayment = async (
    graphQlEndpoint: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    countryId: string,
    streetAddress: string[],
    city: string,
    postCode: string,
    cartId: string,
    vippsCartId: string,
    emailInput: string,
    setShowErrorMessage: Dispatch<SetStateAction<boolean>>,
    vippsCallbackUrl: string,
    clearLoader: () => void,
    paymentMethod: string,
    termsId: string,
    endpointSetPaymentInformation: string,
    endpointGetNetsUrl: string,
    endpointVippsInitUrl: string,
    accessToken?: string,
    newsletterIsChecked?: boolean
) => {
    try {
        try {
            const querySetPaymentMethod = JSON.stringify({
                paymentMethod: {
                    method: paymentMethod,
                    extension_attributes: {
                        agreement_ids: [termsId],
                    },
                },
                email: emailInput,
                cartId: cartId,
                billingAddress: {
                    telephone: phoneNumber,
                    firstname: firstName,
                    lastname: lastName,
                    country_id: countryId,
                    street: streetAddress,
                    city: city,
                    postcode: postCode
                },
            });

            const responseSetPaymentMethod = await postRequest(
                endpointSetPaymentInformation,
                querySetPaymentMethod,
                accessToken
            );
            const dataSetPaymentMethod = await responseSetPaymentMethod.json();
            if (!responseSetPaymentMethod.ok) throw new Error(dataSetPaymentMethod);
            if (newsletterIsChecked) {
                const setNewsletterResponse = await newsletterSignup(graphQlEndpoint, emailInput, accessToken);
                const setNewsletterResult = await setNewsletterResponse.json();
                console.log(setNewsletterResult);
                if (
                    !setNewsletterResponse.ok
                )
                    throw new Error(setNewsletterResult);
            }
            if (paymentMethod === "vipps")
                await vippsPayment(
                    graphQlEndpoint,
                    endpointVippsInitUrl,
                    vippsCartId,
                    cartId,
                    emailInput,
                    setShowErrorMessage,
                    vippsCallbackUrl,
                    clearLoader,
                    accessToken
                );
            else if (paymentMethod === "dibseasycheckout")
                await netsPayment(endpointGetNetsUrl, setShowErrorMessage, clearLoader);
        } catch (e) {
            console.log(e);
            clearLoader();
            setShowErrorMessage(true);
        }
    } catch (e) {
        console.log(e);
        setShowErrorMessage(true);
        clearLoader();
    }
};
