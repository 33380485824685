import * as React from "react";
import { CardProps } from "../Card/CardProps.csharp";
import { EventCardViewModel } from "./EventCardViewModel.csharp";
import { ResponsivePicture } from "../../ReactComponents/responsivePicture/responsivePicture";
import classNames from "classnames";
import { CardVariant } from "../Card/CardVariant.csharp";

export const EventCard = (props: CardProps) => {
    const { title, subtitle, picture, location, date, time, url, type, ticketUrl, ticketText, timesAsList } =
        props.model as EventCardViewModel;

    const ticketsButton = ticketUrl ? <a href={ticketUrl}>{ticketText}</a> : <span>{ticketText}</span>;

    let dateTime;
    if (timesAsList) {
        dateTime = (
            <div className="multi-time">
                <div>{date}</div>
                <div className="desktop">
                    {timesAsList.slice(0, 4).map((time, i) => (
                        <span>
                            {time}
                            {i === 3 && timesAsList.length > 4 ? ", ..." : ""}
                        </span>
                    ))}
                </div>
                <div className="mobile">
                    <span>
                        {timesAsList.slice(0, 4).join(", ")}
                        {timesAsList.length > 4 ? ", ..." : ""}
                    </span>
                </div>
            </div>
        );
    } else if (!date && !time) {
        dateTime = <div></div>;
    } else {
        dateTime =
            props.variant === CardVariant.ListItem ? (
                <>
                    <div>{date}</div>
                    <div>{time}</div>
                </>
            ) : (
                <div>
                    {date},{time}
                </div>
            );
    }

    return (
        <div
            className={classNames(
                "EventCard",
                props.variant != CardVariant.ListItem ? "EventCard--withImage" : "EventCard--listItem"
            )}
        >
            <a className="EventCard__link" href={url} title={title}>
                <div className="CardPicture">
                    <ResponsivePicture profile={props.profile} model={picture} />
                </div>
                <h2 className={classNames("EventCard__title", { "EventCard__title--featured": !!props.featured })}>
                    {title}
                    <span className="EventCard__subtitle">{subtitle}</span>
                </h2>
                <div className="EventCard__intro">
                    <div>{dateTime}</div>
                    <div>{location}</div>
                </div>
                {props.variant == CardVariant.ListItem && (
                    <div className="EventCard__seeMore">
                        <div>{ticketsButton}</div>
                    </div>
                )}
            </a>
        </div>
    );
};
