import React, { useState, useEffect, useRef } from "react";
import { RenewMembershipProps } from "./RenewMembershipProps.csharp";
import { IRadio, RadioList } from "../Shared/RadioList/RadioList";
import { postRequest } from "../Shared/api/post";
import { Loader } from "../Shared/Loader";
import classNames from "classnames";
import { ErrorMessage } from "../Shared/ErrorMessage";
import { formatPrice } from "../Shared/utils/formatPrice";
import { AddDiscounts } from "../Checkout/AddDiscounts/AddDiscounts";
import { initDiscounts } from "../Shared/api/initDiscounts";
import { CartTotals } from "../Checkout/CartTotals/CartTotals";
export const RenewMembershipPage = (props: RenewMembershipProps) => {
    const [wayOfPayment, setWayOfPayment] = useState("");
    const [paymentMethods, setPaymentMethods] = useState<IRadio[]>([]);
    const [cartId, setCartId] = useState(props.cartId);
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentMetodLoading, setIsPaymentMetodLoading] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [discount, setDiscount] = useState({ label: "", value: "" });
    const [currentDiscount, setCurrentDiscount] = useState<string>();
    const [voucher, setVoucher] = useState({ label: "", value: "" });
    const [currentVoucher, setCurrentVoucher] = useState<string>();
    const [discountCoupon, setDiscountCoupon] = useState({ label: "", value: "" });
    const [currentDiscountCoupon, setCurrentDiscountCoupon] = useState<string>();
    const [grandTotal, setGrandTotal] = useState<string | number>();
    const [unitPrice, setUnitPrice] = useState<string | number>();

    const [vat, setVat] = useState<string | number>();
    const [itemId, setItemId] = useState<string | number>();

    const [subTotal, setSubTotal] = useState<string | number>();

    const [paymentMethodFree, setPaymentMethodFree] = useState(false);

    var alreadyHasItemsInCart = useRef(false);

    const vippsPayment = async () => {
        if (props.accessToken === null) {
            const queryInitPayment = JSON.stringify({
                query: `mutation {
                    vippsInitPayment(
                        input: {
                            cart_id: "${cartId}",
                            fallback_url: "${props.vippsCallbackUrl.replace("#cartId#", cartId)}"
                        })
                        {
                            url
                        }
                    }
            `,
            });

            try {
                const responseInitPayment = await postRequest(props.graphQlUrl, queryInitPayment, props.accessToken);
                console.log('Response from initPayment GraphQl:', responseInitPayment);
                const dataInitPayment = await responseInitPayment.json();

                console.log(dataInitPayment.data); // data
                console.log(dataInitPayment.errors); //

                window.location.href = dataInitPayment.data.vippsInitPayment.url;
            } catch (error) {
                const data = JSON.stringify({
                    cartId: props.cartId,
                    exceptionMessage: error.toString(),
                });
                const response = await postRequest(props.endpointAddErrorUrl, data, null);
                if (!response.ok) console.error('Sending error message to api failed.');
                console.error('Error during initPayment GraphQl request:', error);
            }
            
        }
        else {
            const requestVippsInitBody = {
                cartId: props.vippsCartId,
                fallback_url: props.vippsCallbackUrl.replace("#cartId#", props.vippsCartId)
            };

            console.log('Response from initPayment rest:', requestVippsInitBody);

            var bearer = "Bearer " + props.accessToken;
            try {
                const responseInitPayment = await fetch(props.endpointVippsInitUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': bearer
                    },
                    body: JSON.stringify(requestVippsInitBody)
                });
                if (responseInitPayment.ok) {
                    const dataInitPayment = await responseInitPayment.json();
                    const urlArray: string[] = dataInitPayment;
                    if (urlArray.length === 0) {
                        setShowErrorMessage(true);
                    } else {
                        window.location.href = urlArray[0];
                    }
                } else {
                    const errorData = await responseInitPayment.json();
                    throw new Error(errorData);
                }
            }
            catch (error) {
                const data = JSON.stringify({
                    cartId: props.cartId,
                    exceptionMessage: error.toString(),
                });
                const response = await postRequest(props.endpointAddErrorUrl, data, null);
                if (!response.ok) console.error('Sending error message to api failed.');
                console.error('Error during initPayment rest request:', error);
            }
        }
    };

    const netsPayment = async () => {
        const headersNets = new Headers();
        headersNets.append("Accept", "application/json");
        headersNets.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
            method: "GET",
            headers: headersNets,
            redirect: "follow",
        };

        try {
            const response = await fetch(props.endpointGetNetsUrl, requestOptions);

            if (!response.ok) {
                throw new Error('Get nets url response was not ok');
            }

            const result = await response.json();

            if (!result || !result.paymentUrl) {
                throw new Error('Invalid get nets url response or missing payment URL');
            }

            window.location.href = result.paymentUrl;
        } catch (error) {
            const data = JSON.stringify({
                cartId: props.cartId,
                exceptionMessage: error.toString(),
            });
            const response = await postRequest(props.endpointAddErrorUrl, data, null);
            if (!response.ok) console.error('Sending error message to api failed.');
            console.error('Error fetching Nets URL:', error);
        }
    };

    const toPayment = async () => {
        const timeout = setTimeout(() => {
            setIsLoading(true);
        }, 300);
        setShowErrorMessage(false);
        if (paymentMethodFree) {
            try {
                const responseSetPaymentMethodFree = await postRequest(props.setPaymentMethodFreeEndpoint, null, null);
                const dataSetPaymentMethodFree = await responseSetPaymentMethodFree.json();
                if (!responseSetPaymentMethodFree.ok) throw new Error(dataSetPaymentMethodFree);

                if (dataSetPaymentMethodFree != null && dataSetPaymentMethodFree.url != null) {
                    window.location.href = dataSetPaymentMethodFree.url;
                } else {
                    setShowErrorMessage(true);
                    setIsLoading(false);
                }
            }
            catch (e) {
                const data = JSON.stringify({
                    cartId: props.cartId,
                    exceptionMessage: e.toString(),
                });
                const response = await postRequest(props.endpointAddErrorUrl, data, null);
                if (!response.ok) console.error('Sending error message to api failed.');
                console.log(e);
                clearTimeout(timeout);
                setIsLoading(false);
                setShowErrorMessage(true);
            }
        } else {
            try {
                if (alreadyHasItemsInCart.current && props.mainCartUrl) {
                    window.location.href = props.mainCartUrl;
                } else if (wayOfPayment === "vipps") await vippsPayment();
                else if (wayOfPayment === "dibseasycheckout") await netsPayment();
            } catch (e) {
                const data = JSON.stringify({
                    cartId: props.cartId,
                    exceptionMessage: e.toString(),
                });
                const response = await postRequest(props.endpointAddErrorUrl, data, null);
                if (!response.ok) console.error('Sending error message to api failed.');
                console.log(e);
                clearTimeout(timeout);
                setIsLoading(false);
                setShowErrorMessage(true);
            }
        }
    };

    const updateEcommerceObj = (event, itemId, totalPrice, unitPrice, vat) => {
        if(totalPrice && unitPrice){
            // @ts-ignore
            const dataLayer = window.dataLayer || [];
            const vatAmount = vat ? vat : 0;
            const totalPriceExclVat = totalPrice - vatAmount;

            dataLayer.push({
                event: event,
                ecommerce: {
                currency: "NOK",
                value: totalPriceExclVat.toFixed(2),
                items: [  
                    {
                        item_id: itemId,  
                        item_name: "Medlemskap fornyelse", 
                        price: unitPrice.toFixed(2),
                        quantity: 1
                    }
                ]
                }
            });
        }
    }

    useEffect(() => {
        // @ts-ignore
        const dataLayer = window.dataLayer || [];
        dataLayer.push({ ecommerce: null }); 
        (async () => {
            setIsLoading(true);

            if (props.isRenewMemberShipAvailable) {
                const headers = new Headers();
                var bearer = "Bearer " + props.accessToken;
                headers.append("Accept", "application/json");
                headers.append("Content-Type", "application/json");
                headers.append("Authorization", bearer);

                const requestOptions: RequestInit = {
                    method: "GET",
                    headers: headers,
                    redirect: "follow",
                };

                let cartId = props.cartId;
                var shouldInitCart = false;

                try {
                    var getCartRequest = await fetch(props.endpointGetCart, requestOptions);

                    if (getCartRequest.status !== 200) {
                        shouldInitCart = true;
                    } else {
                        var getCartResponse = await getCartRequest.json();
                        const containsMembership = getCartResponse.items.some(
                            (item) => item.product_type === "membership_renew"
                        );
                        if (props.mainCartUrl) {
                            if (
                                getCartResponse &&
                                getCartResponse.items &&
                                getCartResponse.items.length > 0 &&
                                !containsMembership
                            ) {
                                alreadyHasItemsInCart.current = true;
                            }
                        }
                    }
                }
                catch (error) {
                    const data = JSON.stringify({
                        cartId: props.cartId,
                        exceptionMessage: error.toString(),
                    });
                    const response = await postRequest(props.endpointAddErrorUrl, data, null);
                    if (!response.ok) console.error('Sending error message to api failed.');
                    console.log(error);
                }

                if (shouldInitCart) {
                    try {
                        var request = await postRequest(props.endpointGetCart, null, props.accessToken);
                        var response = await request.json();
                        console.log('Response from initCart:', response);
                    }
                    catch (error) {
                        const data = JSON.stringify({
                            cartId: props.cartId,
                            exceptionMessage: error.toString(),
                        });
                        const response = await postRequest(props.endpointAddErrorUrl, data, null);
                        if (!response.ok) console.error('Sending error message to api failed.');
                        console.log('Error from initCart:', error);
                    }
                }

                var membershipNumber = null;

                try {
                    const response = await fetch(props.endpointMembership, requestOptions);

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const result = await response.json();

                    if (!result || !result.membership_number) {
                        throw new Error('Invalid response or missing membership number');
                    }

                    console.log(result);
                    membershipNumber = result.membership_number;
                } catch (error) {
                    console.error('Error fetching membership details:', error);
                    const data = JSON.stringify({
                        cartId: props.cartId,
                        exceptionMessage: error.toString(),
                    });
                    const response = await postRequest(props.endpointAddErrorUrl, data, null);
                    if (!response.ok) console.error('Sending error message to api failed.');
                }
                

                var sku = null;
                var itemType = null;
                try {
                    const response = await fetch(props.endpointChangeItems, requestOptions);

                    if (!response.ok) {
                        throw new Error('Change items response was not ok');
                    }

                    const result = await response.json();

                    if (!result || !Array.isArray(result) || result.length === 0) {
                        throw new Error('Change items has invalid response or empty result array');
                    }

                    console.log(result);
                    sku = result[0].item_no;
                    itemType = result[0].type;
                    setUnitPrice(result[0].web_unit_price);
                    setItemId(sku);

                } catch (error) {
                    console.error('Error fetching and processing item details:', error);
                    const data = JSON.stringify({
                        cartId: props.cartId,
                        exceptionMessage: error.toString(),
                    });
                    const response = await postRequest(props.endpointAddErrorUrl, data, null);
                    if (!response.ok) console.error('Sending error message to api failed.');
                }

                if (!getCartResponse.items.some((item) => item.product_type === "membership_renew")) {
                    const addTCartBody = JSON.stringify({
                        cartItem: {
                            sku: sku,
                            qty: 1,
                            extension_attributes: {
                                membership_purchase: {
                                    item_type: itemType,
                                    item_number: sku,
                                    extension_attributes: {
                                        change_membership: {
                                            membership_number: membershipNumber,
                                        },
                                    },
                                },
                            },
                        },
                    });

                    try {
                        const response = await postRequest(props.endpointAddToCart, addTCartBody, props.accessToken);

                        if (!response.ok) {
                            throw new Error('Add to cart response was not ok');
                        }

                        const result = await response.json();

                        console.log(result);
                        // Further processing with the 'result' if needed
                    } catch (error) {
                        console.error('Error adding to cart:', error);
                        const data = JSON.stringify({
                            cartId: props.cartId,
                            exceptionMessage: error.toString(),
                        });
                        const response = await postRequest(props.endpointAddErrorUrl, data, null);
                        if (!response.ok) console.error('Sending error message to api failed.');
                    }
                }

                if (alreadyHasItemsInCart.current) {
                    setWayOfPayment("dummy");
                    setIsPaymentMetodLoading(false);
                } else {
                    const queryAvailablePaymentMethods = JSON.stringify({
                        query: `{
                        customerCart {
                            id
                            available_payment_methods {
                                code
                                title
                            }
                        }
                    }
                `,
                    });

                    try {
                        const responseGetAvailablePaymentMethods = await postRequest(
                            props.graphQlUrl,
                            queryAvailablePaymentMethods,
                            props.accessToken
                        );
                        const dataGetAvailablePaymentMethods = await responseGetAvailablePaymentMethods.json();
                        const paymentMethodFree =
                            dataGetAvailablePaymentMethods.data.customerCart.available_payment_methods.some(
                                (item) => item.code === "free"
                            ) || grandTotal === 0;

                        if (dataGetAvailablePaymentMethods.data != null) {
                            setCartId(dataGetAvailablePaymentMethods.data.customerCart.id);
                            cartId = dataGetAvailablePaymentMethods.data.customerCart.id;

                            if (paymentMethodFree) {
                                setPaymentMethodFree(true);
                                setPaymentMethods([]);
                            } else {
                                setPaymentMethodFree(false);
                                setPaymentMethods(
                                    dataGetAvailablePaymentMethods.data.customerCart.available_payment_methods.map(
                                        (value) => ({
                                            ...value,
                                            title:
                                                value.code === "vipps"
                                                    ? props.vippsText
                                                    : value.code === "dibseasycheckout"
                                                        ? props.cardButtonText
                                                        : value.title,
                                        })
                                    )
                                );
                            }
                        }
                        setIsLoading(false);
                        setIsPaymentMetodLoading(false);
                    }
                    catch (error) {
                        const data = JSON.stringify({
                            cartId: props.cartId,
                            exceptionMessage: error.toString(),
                        });
                        const response = await postRequest(props.endpointAddErrorUrl, data, null);
                        if (!response.ok) console.error('Sending error message to api failed.');
                        console.log(error);
                    }
                }
            } else {
                setIsLoading(false);
                setIsPaymentMetodLoading(false);
            }
            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        updateEcommerceObj("add_to_cart", itemId, grandTotal, unitPrice, vat );
        updateEcommerceObj("begin_checkout", itemId, grandTotal, unitPrice, vat );
    }, [paymentMethods]);

    // Get discounts
    const paymentInfoGuestEndpoint = "";
    const paymentInfoMemberEndpoint = props.endpointMemberGetPaymentInformation;
    const guestHeaders = {
        "content-type": "application/json",
    };

    const memberHeaders = {
        "content-type": "application/json",
        Authorization: `Bearer ${props.accessToken}`,
    };

    const options = {
        method: "GET",
        headers: props.accessToken ? memberHeaders : guestHeaders,
    };

    const grandTotalWithoutShipping = (data) => {
        const grandTotalWithoutShipping = data.totals.total_segments.find((item) => item.code === "grand_total").value;
        const shipping = data.totals.base_shipping_incl_tax;
        const total = parseInt(grandTotalWithoutShipping) - parseInt(shipping);
        setGrandTotal(total);
        setVat(data.totals.total_segments.find((item) => item.code === "tax").value);
    };

    useEffect(() => {
        initDiscounts(
            props.accessToken,
            paymentInfoGuestEndpoint,
            paymentInfoMemberEndpoint,
            options,
            setCurrentDiscount,
            setDiscount,
            discount,
            setCurrentVoucher,
            setVoucher,
            voucher,
            setCurrentDiscountCoupon,
            setDiscountCoupon,
            discountCoupon,
            setSubTotal,
            grandTotalWithoutShipping
        );
    }, [grandTotal, paymentMethods]);

    const disabled = !wayOfPayment;

    const price = props.price && props.currencyFormat.replace("price", formatPrice(props.price));

    return (
        <div className="RenewPassword">
            <div className="RenewPassword__Container">
                <section className="TicketPage__card animateIn">
                    <h1 className="RenewPassword__Title">{props.title}</h1>
                    <div className="RenewPassword__Label__Container">
                        <p className="RenewPassword__Label">{props.membershipDescription}</p>
                        {props.isRenewMemberShipAvailable ? <p className="RenewPassword__Price">{price}</p> : null}
                    </div>
                    {props.isRenewMemberShipAvailable ? (
                        <React.Fragment>
                            <p>
                                {props.periodStart} - {props.expirationDate}
                            </p>
                            {isPaymentMetodLoading || isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    <CartTotals
                                        discount={discount}
                                        voucher={voucher}
                                        grandTotal={grandTotal}
                                        tax={vat}
                                        totalLabel={props.totalText}
                                        taxText={props.vatText}
                                        currencyFormat={props.currencyFormat}
                                    ></CartTotals>
                                </>
                            )}
                            <div className="RenewMembership__discounts">
                                <AddDiscounts
                                    props={props.discountCode}
                                    setDiscount={setDiscount}
                                    setCurrentDiscount={setCurrentDiscount}
                                    discount={discount}
                                    currentDiscount={currentDiscount}
                                    setSubTotal={setSubTotal}
                                    paymentInfoGuestEndpoint={paymentInfoGuestEndpoint}
                                    paymentInfoMemberEndpoint={paymentInfoMemberEndpoint}
                                    options={options}
                                    memberHeaders={memberHeaders}
                                    guestHeaders={guestHeaders}
                                    accessToken={props.accessToken}
                                    setCurrentVoucher={setCurrentVoucher}
                                    currentVoucher={currentVoucher}
                                    setVoucher={setVoucher}
                                    voucher={voucher}
                                    grandTotalWithoutShipping={grandTotalWithoutShipping}
                                    setCurrentDiscountCoupon={setCurrentDiscountCoupon}
                                    currentDiscountCoupon={currentDiscountCoupon}
                                    setDiscountCoupon={setDiscountCoupon}
                                    discountCoupon={discountCoupon}
                                />
                            </div>

                            {paymentMethods.length > 0 ? (
                                <RadioList radioList={paymentMethods} setValue={setWayOfPayment} value={wayOfPayment} />
                            ) : isPaymentMetodLoading ? (
                                <Loader />
                            ) : null}

                            <div
                                className="RenewPassword__Terms"
                                dangerouslySetInnerHTML={{ __html: props.termsText }}
                            />
                            <div className="RenewPassword__Button">
                                <button
                                    onClick={toPayment}
                                    className={classNames("button--primary button--forward ", {
                                        "button--red": !disabled || paymentMethodFree,
                                    })}
                                    disabled={disabled && !paymentMethodFree}
                                >
                                    {isLoading ? (
                                        <Loader isSmall isWhite />
                                    ) : grandTotal && grandTotal !== 0 && !isLoading ? (
                                        `${props.paymentButtonText} ${props.currencyFormat.replace(
                                            "price",
                                            grandTotal.toString()
                                        )}`
                                    ) : (
                                        props.paymentFreeButtonText
                                    )}
                                </button>
                                {showErrorMessage && <ErrorMessage text={props.paymentErrorMessage} />}
                            </div>
                        </React.Fragment>
                    ) : null}
                </section>
            </div>
        </div>
    );
};
