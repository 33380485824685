import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import { CountryCode } from "libphonenumber-js";

interface ICountrySelect {
    value: CountryCode;
    onChange: Dispatch<SetStateAction<CountryCode>>;
    labels: { [key: string]: string };
}

const CountrySelect = ({ value, onChange, labels }: ICountrySelect) => {
    const [selected, setSelected] = useState<string>(`+${getCountryCallingCode(value)}`);

    const countries = getCountries()
        .map((country) => ({ [labels[country]]: country }))
        .sort((a, b) => Object.keys(a)[0].localeCompare(Object.keys(b)[0]));

    return (
        <div className="CountrySelect">
            <select
                value={value}
                onChange={(event) => {
                    const countryCode = event.target.value as CountryCode;
                    onChange(countryCode);
                    setSelected(`+${getCountryCallingCode(countryCode)}`);
                }}
                aria-label={"lands kode"}
            >
                {countries.map((mappedCountry) => {
                    const country = Object.values(mappedCountry);
                    return (
                        <option key={country[0]} value={country[0]}>
                            {labels[country[0]]} +{getCountryCallingCode(country[0])}
                        </option>
                    );
                })}
            </select>
            <div className="fake__select" aria-hidden={true}>
                {selected}
            </div>
        </div>
    );
};

export default CountrySelect;
