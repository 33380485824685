const formatPrice = ( input, exportFormat ) => {

    var outputPrice = input

    if (typeof input === 'string' || input instanceof String) {
        const decimalReplace = input.replace("-", "00")
        const separatorReplace = decimalReplace.replace(",", ".")
        outputPrice = separatorReplace;
    }

    if (exportFormat === "float") {
        return parseFloat(outputPrice)
    } else if (exportFormat === "int") {
        return parseInt(outputPrice)
    } else {
        return outputPrice
    }
}

export default formatPrice