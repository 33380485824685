import * as React from "react";
import { ReactNode } from "react";
import FocusTrap from "focus-trap-react";
import { NavigationItem } from "../NavigationItem.csharp";
import { NavigationProps } from "../NavigationProps.csharp";
import { LanguageSwitcher } from "../../LanguageSwitcher/LanguageSwitcher";
import { MegaMenu } from "./MegaMenu";
import { SkipToContent } from "./SkipToContent";
import classNames from "classnames";
import { PageTheme } from "../../../Pages/Base/Themes/PageTheme.csharp";
import { ScrollLock } from "./ScrollLock";
import { Hamburger } from "./Hamburger";
import { NavigationTopMenu } from "./NavigationTopMenu";
import { OpeningHours } from "./OpeningHours";

export class Navigation extends React.Component<
    NavigationProps,
    { isExpanded: boolean; offsetLeft: number; navHeight: number; scrollPosition: number }
> {
    private readonly navRef: React.RefObject<any>;
    private readonly buttonRef: React.RefObject<any>;

    constructor(props: NavigationProps) {
        super(props);

        this.state = {
            isExpanded: false,
            offsetLeft: 0,
            navHeight: 0,
            scrollPosition: 0,
        };

        this.navRef = React.createRef();
        this.buttonRef = React.createRef();
        this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this);
        this.handleEscClick = this.handleEscClick.bind(this);
        this.setMegaMenuPosition = this.setMegaMenuPosition.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleEscClick);
        window.addEventListener("load", this.setMegaMenuPosition);
        window.addEventListener("resize", this.setMegaMenuPosition);
        window.addEventListener("scroll", this.handleScroll);
        this.handleScroll();
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleEscClick);
        window.removeEventListener("load", this.setMegaMenuPosition);
        window.removeEventListener("resize", this.setMegaMenuPosition);
        window.removeEventListener("scroll", this.handleScroll);
    }

    render(): ReactNode {
        const headerItems = this.props.model.header.map((item) =>
            Navigation.createHeaderItem(item, this.props.model.theme)
        );
        const leftItems = this.props.model.header.map((item) =>
            Navigation.createLeftItem(item, this.props.model.theme, this.state.isExpanded)
        );
        const bottomItems = this.props.model.bottomMenu.map((item) => Navigation.createTopItem(item));

        return (
            <React.Fragment>
                <SkipToContent text={this.props.translations.skipToContent} />
                <ScrollLock isLocked={this.state.isExpanded} />
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                                .Hamburger::before { 
                                    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M30 23.5v5H2v-5h28zm0-10v5H2v-5h28zm0-10v5H2v-5h28z' fill='${this.props.model?.customThemeColors?.customThemeFontColor?.replace(
                                        "#",
                                        "%23"
                                    )}' fill-rule='evenodd' clip-rule='evenodd'/%3E%3C/svg%3E") no-repeat }
                            `,
                    }}
                />
                <FocusTrap active={this.state.isExpanded}>
                    <nav
                        ref={this.navRef}
                        className={classNames(
                            "Navigation",
                            this.getThemeModifier(),
                            this.state.isExpanded ? "Navigation--expanded" : null
                        )}
                    >
                        <div className="Navigation__inner wrapper">
                            <ul
                                className={classNames(
                                    "HeaderNav",
                                    this.state.isExpanded ? "HeaderNav--expanded" : null
                                )}
                            >
                                <li
                                    className={classNames(
                                        this.state.isExpanded
                                            ? "HeaderNav__itemLogo_expanded HeaderNav__item HeaderNav__itemLogo"
                                            : "HeaderNav__item HeaderNav__itemLogo"
                                    )}
                                >
                                    {this.props.model.theme === PageTheme.Custom &&
                                    this.props.model.customThemeColors.customThemeMunchLogoColor ? (
                                        <a
                                            href={this.props.model.homepageUrl}
                                            style={{
                                                color: this.props.model.customThemeColors.customThemeMunchLogoColor,
                                            }}
                                            //title={this.props.model.logoTitle} TODO: BE add logoTitle property
                                        >
                                            Munch
                                        </a>
                                    ) : (
                                        <a href={this.props.model.homepageUrl}>Munch</a>
                                    )}
                                </li>
                                {!this.state.isExpanded && (
                                    <li className="headerNavItems">
                                        {headerItems}
                                    </li>
                                )}

                                <li className="HeaderNav__topMenuItems">
                                    {this.state.isExpanded && (
                                        <div className="HeaderNav__openingHour">
                                            <OpeningHours
                                                text={this.props.model.openingHours.openingHoursString}
                                                link={{
                                                    text: this.props.model.openingHours.openingHoursLink
                                                        .openingHoursUrlText,
                                                    url: this.props.model.openingHours.openingHoursLink.openingHoursUrl,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <NavigationTopMenu
                                        className="HeaderNav__topMenu"
                                        myPageLink={{
                                            text: this.props.model.loginLogoutViewModel.myPageLinkText,
                                            url: this.props.model.loginLogoutViewModel.myPageLinkUrl,
                                        }}
                                        searchLink={this.props.model.searchLink}
                                        signLink={{
                                            signInUrl: this.props.model.loginLogoutViewModel.logInUrl,
                                            signInLabel: this.props.model.loginLogoutViewModel.logInText,
                                            signOutUrl: this.props.model.loginLogoutViewModel.logOutUrl,
                                            signOutLabel: this.props.model.loginLogoutViewModel.logOutText,
                                            isSignedIn: this.props.model.loginLogoutViewModel.isLoggedIn,
                                            serverErrorText: this.props.model.loginLogoutViewModel.serverErrorText,
                                            returnUrl: this.props.model.loginLogoutViewModel.myPageLinkUrl,
                                        }}
                                        languageSwitcher={{
                                            model: this.props.model.languageSwitcher,
                                            isOpeningTop: false,
                                            translations: this.props.translations,
                                        }}
                                    />
                                </li>
                                <li className="HeaderNav__actionButtons">
                                    {this.props.model.languageSwitcher && (
                                        <div
                                            className={classNames("HeaderNav__language", {
                                                "HeaderNav__language--expanded": this.state.isExpanded,
                                            })}
                                        >
                                            <LanguageSwitcher
                                                model={this.props.model.languageSwitcher}
                                                isOpeningTop={false}
                                                translations={this.props.translations}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={classNames(
                                            "HeaderNav__menuButton",
                                            this.state.isExpanded ? "HeaderNav__item--expanded" : null
                                        )}
                                    >
                                        <Hamburger
                                            isExpanded={this.state.isExpanded}
                                            toggleMenu={this.handleMenuButtonClick}
                                            ariaLabel={this.props.translations.menu}
                                            forwardRef={this.buttonRef}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {this.props.model.megaMenu && (
                            <MegaMenu
                                myPageLink={{
                                    text: this.props.model.loginLogoutViewModel.myPageLinkText,
                                    url: this.props.model.loginLogoutViewModel.myPageLinkUrl,
                                }}
                                searchLink={this.props.model.searchLink}
                                signLink={{
                                    signInUrl: this.props.model.loginLogoutViewModel.logInUrl,
                                    signInLabel: this.props.model.loginLogoutViewModel.logInText,
                                    signOutUrl: this.props.model.loginLogoutViewModel.logOutUrl,
                                    signOutLabel: this.props.model.loginLogoutViewModel.logOutText,
                                    isSignedIn: this.props.model.loginLogoutViewModel.isLoggedIn,
                                    returnUrl: this.props.model.loginLogoutViewModel.myPageLinkUrl,
                                    serverErrorText: this.props.model.loginLogoutViewModel.serverErrorText,
                                }}
                                model={{
                                    featuredItems: this.props.model.featuredMenu,
                                    menuItems: this.props.model.megaMenu,
                                    leftItems: leftItems,
                                    bottomItems: bottomItems,
                                    openingsHoursText: this.props.model.openingHours.openingHoursString,
                                    openingsHours: this.props.model.openingHours.openingHoursLink.openingHoursUrlText,
                                    openingsHoursUrl: this.props.model.openingHours.openingHoursLink.openingHoursUrl,
                                }}
                                translations={this.props.translations}
                                languageSwitcher={this.props.model.languageSwitcher}
                                isExpanded={this.state.isExpanded}
                                offsetLeft={this.state.offsetLeft}
                            />
                        )}
                    </nav>
                </FocusTrap>
            </React.Fragment>
        );
    }

    private static createHeaderItem(item: NavigationItem, theme: PageTheme): ReactNode {
        return (
            <a
                key={item.url}
                href={item.url}
                className={classNames(
                    theme === PageTheme.Custom ? "HeaderNav__item__custom HeaderNav__item" : "HeaderNav__item"
                )}
            >
                {item.name}
            </a>
        );
    }

    private static createLeftItem(item: NavigationItem, theme: PageTheme, isExpanded: boolean): ReactNode {
        return (
            <li className={classNames("HeaderNav__item_left", isExpanded ? "left_item" : "")} key={item.url}>
                <a
                    href={item.url}
                    className={classNames(theme === PageTheme.Custom ? "HeaderNav__item__custom" : null)}
                >
                    {item.name}
                </a>
            </li>
        );
    }

    private static createTopItem(item: NavigationItem): ReactNode {
        return (
            <li className="HeaderNav__item" key={item.url}>
                <a href={item.url} className={classNames("MenuLine__item")}>
                    {item.name}
                </a>
            </li>
        );
    }

    handleMenuButtonClick(): void {
        this.setState({
            isExpanded: !this.state.isExpanded,
        });
    }

    handleEscClick(event: KeyboardEvent | undefined): void {
        const isEscape = event && (event.key === "Escape" || event.which === 27);

        console.log("isEscape", isEscape);
        if (isEscape) {
            this.setState({
                isExpanded: false,
            });
        }
    }

    setMegaMenuPosition(): void {
        const menuItem = document.querySelector<HTMLUListElement>(".HeaderNav__item:nth-child(2)");

        this.setState({
            offsetLeft: menuItem ? menuItem.offsetLeft : 0,
            navHeight: this.navRef.current.offsetHeight,
        });
    }

    handleScroll(): void {
        const currentScrollPos = window.pageYOffset;
        const headerHeight = this.navRef.current.offsetHeight;
        const body = document.body;

        const scrollUp = this.state.scrollPosition > currentScrollPos;
        const scrollDown = !scrollUp;

        const isSticky = scrollUp && currentScrollPos > headerHeight;
        const hiddenNavigation =
            (scrollDown && currentScrollPos >= headerHeight) || (scrollUp && currentScrollPos > headerHeight);

        this.setState({
            scrollPosition: currentScrollPos,
        });

        if (!Navigation.isIE()) {
            isSticky ? body.classList.add("stickyNavigation") : body.classList.remove("stickyNavigation");
            hiddenNavigation ? body.classList.add("hideNavigation") : body.classList.remove("hideNavigation");
        }
    }

    private getThemeModifier(): string {
        return `Navigation--${this.lowercaseFirstLetter(PageTheme[this.props.model.theme])}`;
    }

    private lowercaseFirstLetter(theme: string) {
        return theme.charAt(0).toLowerCase() + theme.slice(1);
    }

    private static isIE() {
        return window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    }
}
