import * as React from "react";
import { SliderItem } from "react-compound-slider/dist/types/types";

export interface TickProps {
    tick: SliderItem;
    count: number;
    format?: (val: number) => string;
}

export const Tick: React.FC<TickProps> = ({ tick, count, format = (d) => d }) => {
    return (
        <div>
            <div className="CollectionSearchRange__tick__dot" style={{ left: `${tick.percent == 100 ? 95 : 0}%` }} />
            <div
                className="CollectionSearchRange__tick__number"
                style={{
                    left: `${tick.percent == 100 ? 95 : 0}%`,
                }}
            >
                {format(tick.value)}
            </div>
        </div>
    );
};
