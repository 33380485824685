import React, { FC } from "react";
import ProductListBlock from "../../Blocks/ProductListBlock/ProductListBlock";
import SplitBlock from "../../Blocks/SplitBlock/SplitBlock";
import CallToActionBlock from "../../Blocks/CallToActionBlock/CallToActionBlock";
import CardsListBlock from "../../Blocks/CardsListBlock/CardsListBlock";

interface Block {
    id: string;
    name: string;
    props: any;
}

interface ContentAreaProps {
    blocks: Block[];
}

const ContentArea: FC<ContentAreaProps> = ({ blocks }) => {
    return (
        <div>
            {blocks.map((block) => {
                return (
                    <div key={block.id} className="scrollObserver">
                        {block.name === "ProductListBlock" && <ProductListBlock {...block.props} />}
                        {block.name === "SplitBlock" && <SplitBlock {...block.props} />}
                        {block.name === "CallToActionBlock" && <CallToActionBlock {...block.props} />}
                        {block.name === "CardsListBlock" && <CardsListBlock {...block.props} />}
                    </div>
                );
            })}
        </div>
    );
};

export default ContentArea;
