import * as React from 'react';

interface ScrollLockProps {
    isLocked: boolean;
}

interface ScrollLockState {
    scrollPosition?: number;
}

export class ScrollLock extends React.Component<ScrollLockProps, ScrollLockState> {
    constructor(props: ScrollLockProps) {
        super(props);
        this.state = {
            scrollPosition: undefined,
        };
    }

    componentDidUpdate(prevProps: ScrollLockProps): void {
        const isLockedChanged = prevProps.isLocked !== this.props.isLocked;
        if (isLockedChanged) {
            
            const body = document.body;
            const scrollY = window.scrollY || window.pageYOffset;
            
            if (this.props.isLocked) {
                body.classList.add('BodyHidden');
                body.style.top = `-${scrollY}px`;
            } else if (this.state.scrollPosition !== undefined) {
                body.classList.remove('BodyHidden');
                body.style.top = '';
                window.scrollTo({
                    top: this.state.scrollPosition,
                    behavior: 'auto'
                });
            }

            this.setState({ scrollPosition: this.props.isLocked ? scrollY : undefined });
        }
    }

    render(): Object | string | number | {} | Object | Object | boolean {
        return <React.Fragment></React.Fragment>;
    }
}
