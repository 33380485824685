import classNames from "classnames";
import React from "react";

interface ISize {
    name: string;
    sku: string;
    price: number;
}

export const Select = ({
    values,
    onChange,
    label,
    cn,
}: {
    values: ISize[];
    onChange: (x: string) => void;
    label: string;
    cn?: string;
}) => {
    return (
        <>
            <label className="Select__label" htmlFor={label}>
                {label}
            </label>
            <div className={classNames("Select__Container", cn)}>
                <select onChange={(e) => onChange(e.target.value)} id={label}>
                    {values.map((value) => {
                        return <option key={value.sku}>{value.name}</option>;
                    })}
                </select>
            </div>
        </>
    );
};
