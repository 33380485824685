//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum PageTheme {
	GoldBlue = 0,
	BlackRed = 1,
	PinkBlue = 2,
	GreenWhite = 3,
	BlueWhite = 4,
	BlueRed = 5,
	RedBlack = 6,
	RedBlue = 7,
	PinkBlack = 8,
	BlackGold = 9,
	BlackWhite = 10,
	GoldBlack = 11,
	WhiteBlack = 12,
	WhiteRed = 13,
	WhiteGreen = 14,
	WhiteBlue = 15,
	GoldWhite = 16,
	BluePink = 17,
	GreenPink = 18,
	BlackWhiteWithWhiteLogo = 19,
	BlackWhiteWithRedLogo = 20,
	Custom = 21,
	GreyRed = 22
}
