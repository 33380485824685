import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/nb";

interface IMembershipExpiration {
    title?: string;
    intro: string;
    linkItem: {
        text?: string;
        url?: string;
    };
    expirationDate?: string;
    canRenewMembership?: boolean;
}

const MembershipExpiration = ({
    title,
    intro,
    linkItem,
    expirationDate,
    canRenewMembership
}: IMembershipExpiration) => {

    const replaceText = (text, value) => {
        text = text.replace("{0}", value ? value : '');
        return text;
    };

    const introTextWithExpData = intro ? replaceText(intro, expirationDate) : null;

    return  (
        <div className="MembershipExpiration">
            <div className="MembershipExpiration__inner">
                <div className="MembershipExpiration__column">
                    {title && <h2 className="MembershipExpiration__title">{title}</h2>}
                </div>
                <div className="MembershipExpiration__column">
                    {introTextWithExpData && <div className="MembershipExpiration__text">{introTextWithExpData}</div>}
                    {canRenewMembership && linkItem && (
                        <a className="MembershipExpiration__link button--secondary" href={linkItem.url}>
                            {linkItem.text}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MembershipExpiration;
