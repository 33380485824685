import React, { useState, useEffect } from "react";
import Modal from "../../../../../Partials/Modal/Modal";
import QRCode from "react-qr-code";
import { Textfit } from "react-textfit";

export interface IMemberCard {
    isOpen: boolean;
    title?: string;
    nameOfMemberLabel?: string;
    nameOfMember: string;
    typeOfMembershipLabel?: string;
    typeOfMembership: string;
    validToLabel: string;
    validTo: string;
    qrCode: string;
    closeButtonText?: string;
    showMemberCard?: (val: any) => any;
}

const MemberCard = ({
    isOpen,
    title,
    nameOfMemberLabel,
    nameOfMember,
    typeOfMembershipLabel,
    typeOfMembership,
    validToLabel,
    validTo,
    qrCode,
    closeButtonText,
    showMemberCard = () => {},
}: IMemberCard) => {
    const handleClose = () => {
        showMemberCard(false);
    };

    return (
        <Modal isOpen={isOpen} contentLabel="card modal" closeButtonLabel={closeButtonText} onClose={handleClose}>
            <div className="MemberCard__wrapper">
                {title && <h2 className="MemberCard__title">{title}</h2>}

                <div className="MemberCard">
                    <strong className="MemberCard__logo">Munch</strong>
                    <div className="MemberCard__items">
                        <dl className="MemberCard__item MemberCard__item--wide">
                            <dt className="MemberCard__item-label">{nameOfMemberLabel}</dt>
                            <dt className="MemberCard__item-value MemberCard__item-value--large">{nameOfMember}</dt>
                        </dl>
                        <dl className="MemberCard__item">
                            <dt className="MemberCard__item-label">{typeOfMembershipLabel}</dt>
                            <dt className="MemberCard__item-value">{typeOfMembership}</dt>
                        </dl>
                        <dl className="MemberCard__item">
                            <dt className="MemberCard__item-label">{validToLabel}</dt>
                            <dt className="MemberCard__item-value">{validTo}</dt>
                        </dl>
                    </div>

                    {qrCode && (
                        <div className="MemberCard__qr-container">
                            <QRCode
                                value={qrCode}
                                viewBox={`0 0 256 256`}
                                size={256}
                                style={{ height: "100%", maxWidth: "100%", width: "100%" }}
                                role="img"
                                aria-label="QR code"
                            />
                            <div className="MemberCard__code">
                                <Textfit mode="single" forceSingleModeWidth={true} min={7} max={11}>
                                    {qrCode}
                                </Textfit>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default MemberCard;
