import * as React from "react";
import classNames from "classnames";
import {Collapse} from "react-collapse";
import {Animations} from "../../../ReactComponents/animations";
import {HappeningDetailsInfoProps} from "./HappeningDetailsInfoProps.csharp";

export class HappeningDetailsInfo extends React.Component<HappeningDetailsInfoProps,{isExpanded: boolean}> {
    static COLLAPSED_ITEMS_COUNT = 6;
    
    constructor(props: HappeningDetailsInfoProps) {
        super(props);
        
        this.state = {
            isExpanded: false
        };

        this.toggleExpandState = this.toggleExpandState.bind(this);
    }
    render() {
        const isExpandable = this.props.model.length > HappeningDetailsInfo.COLLAPSED_ITEMS_COUNT;
        const visibleItems = this.state.isExpanded
            ? this.props.model 
            : this.props.model.slice(0, HappeningDetailsInfo.COLLAPSED_ITEMS_COUNT);
        
        const toggleExpandButton = isExpandable 
            ? <button className={classNames("HappeningHeader__seeMore button--secondary button--theme", this.state.isExpanded? "button--collapse":" button--expand")}
                      onClick={this.toggleExpandState}>{this.state.isExpanded ? this.props.showLessText : this.props.showMoreText}</button>
            : "";
        
        return <Collapse isOpened={true} springConfig={Animations.spring}>
            {
                visibleItems.map((item:string) => 
                <div key={item} className="HappeningHeader__item">{item}</div>)
            }
            {toggleExpandButton}
        </Collapse>

    }

    private toggleExpandState() {
        this.setState({isExpanded: !this.state.isExpanded});
    }
}