import React, { useState, useEffect } from "react";
import cn from "classnames";

interface ICallToAction {
    buttonsAlignment: string;
    color: string;
    column1: string;
    column2: string;
    column3: string;
    primaryButtonText: string;
    primaryButtonUrl: string;
    secondaryButtonText: string;
    secondaryButtonUrl: string;
    title: string;
}

const CallToActionBlock = ({
    buttonsAlignment,
    color,
    column1,
    column2,
    column3,
    primaryButtonText,
    primaryButtonUrl,
    secondaryButtonText,
    secondaryButtonUrl,
    title,
}: ICallToAction) => {
    const columns = [column1, column2, column3].filter(column => column && column.length > 0);
    const numberOfCols = columns.length;
    const buttonColumnIndex =
        buttonsAlignment === "right" ? numberOfCols - 1 : buttonsAlignment === "left" ? 0 : (numberOfCols - 1) / 2;
    const showPrimaryButton = primaryButtonText && primaryButtonUrl;
    const showSecondaryButton = secondaryButtonText && secondaryButtonUrl;
    return (
        <div
            className={cn("CallToAction wrapper", {
                [`CallToAction--${numberOfCols}col`]: numberOfCols,
                [`CallToAction--${color}`]: color,
            })}
        >
            {title && <h2 className="CallToAction__title">{title}</h2>}
            <div className="CallToAction__body scrollObserver">
                <div className="CallToAction__inner">
                    {columns.map((column, index) => {
                        const columnStyle = {
                            animationDelay: index * 100 + 10 + "ms",
                        };
                        return column ? (
                            <div key={index}>
                                <div
                                    style={columnStyle}
                                    className="CallToAction__text"
                                    dangerouslySetInnerHTML={{ __html: `${column}` }}
                                ></div>

                                {(showPrimaryButton || showSecondaryButton) &&
                                    buttonColumnIndex === index &&
                                    buttonsAlignment != "center" && (
                                        <div className="CallToAction__buttonWrapper">
                                            {showPrimaryButton && (
                                                <a className="button--primary" href={primaryButtonUrl}>
                                                    <span>{primaryButtonText}</span>
                                                </a>
                                            )}
                                            {showSecondaryButton && (
                                                <a className="button--secondary" href={secondaryButtonUrl}>
                                                    {secondaryButtonText}
                                                </a>
                                            )}
                                        </div>
                                    )}
                            </div>
                        ) : null;
                    })}
                </div>

                {(showPrimaryButton || showSecondaryButton) && buttonsAlignment === "center" && (
                    <div className="CallToAction__buttonWrapper CallToAction__buttonWrapper--center scrollObserver">
                        <div className={cn("CallToAction__buttons", buttonsAlignment)}>
                            {showPrimaryButton && (
                                <a className="button--primary" href={primaryButtonUrl}>
                                    <span>{primaryButtonText}</span>
                                </a>
                            )}
                            {showSecondaryButton && (
                                <a className="button--secondary" href={secondaryButtonUrl}>
                                    {secondaryButtonText}
                                </a>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CallToActionBlock;
