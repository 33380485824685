import React from "react";
interface IBreadcrumbs {
    items: {
        url: string;
        text: string;
    }[];
}
export const Breadcrumbs = ({ items }: IBreadcrumbs) => {
    return (
        <ul className="Breadcrumbs">
            {items &&
                items.map((item) => (
                    <li className="Breadcrumbs__item" key={item.url}>
                        <a href={item.url}>
                            {items.length === 1 && <span className="Breadcrumbs__item--single" />}
                            {item.text}
                        </a>
                    </li>
                ))}
        </ul>
    );
};
