import React, { useState, useEffect, useRef} from "react";
import cn from "classnames";
import Cookies from 'js-cookie';

export interface ISignLink {
    signInLabel: string;
    signInUrl: string;
    signOutUrl?: string;
    signOutLabel: string;
    isSignedIn: boolean;
    className?: string;
    serverErrorText: string;
    returnUrl?: string; 
}
const SignLink = ({ signInUrl, signInLabel, signOutLabel, signOutUrl, isSignedIn, className, returnUrl }: ISignLink) => {

    const signInLinkUrl = returnUrl ? signInUrl +'?ReturnUrl=' + returnUrl : signInUrl;

      const handleLogout = (e: React.FormEvent) => {
        e.preventDefault();

        const options = {
            method: "POST"
        };

        fetch(signOutUrl, options)
            .then((response) => {
                console.log('response', response);
                if (response.status == 200) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.log(error);
            });
      };
    return (
        <>
            {isSignedIn ? (
                <button onClick={handleLogout} className={cn("SignLink SignLink--log-out", className)}>{signOutLabel}</button>
            ) : (
                <a href={signInLinkUrl} className={cn("SignLink", className)}>
                    {signInLabel}
                </a>
            )}
        </>
    );
};

export default SignLink;
