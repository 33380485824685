import {NavigationItem} from "../NavigationItem.csharp";
import classNames from "classnames";
import * as React from "react";

export enum MenuLineVariant {
    STANDARD,
    FEATURED
}

export const MenuLine =  (props: {model: NavigationItem[], variant: MenuLineVariant}) => {
    const items = props.model.map(item => <li key={item.url} className={classNames("MenuLine__item")}><a href={item.url}>{item.name}</a> </li>);
    return <ul className="MenuLine">
        {items}
    </ul>
};