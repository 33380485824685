import React, { useRef, useEffect, useState } from "react";
import { RadioList } from "../RadioList/RadioList";

import { getData } from "../../Checkout/api/get";
import { Loader } from "../Loader";
import { ErrorMessage } from "../ErrorMessage";
import { postRequest } from "../../Checkout/api/post";
import cn from "classnames";

export interface IShippingOptions {
    title: string;
    endpoint: string;
    token: string;
    freePriceText: string;
    currencyFormat: string;
    errorMessage?: string;
    onShippingSelect?: (val: any) => any;
    setShippingEndpoint: string;
    memberData: {
        countryId: string;
        city: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        postalCode: string;
        streetAddress: string;
    };
    summaryTotals: { subTotal: number; shipping: number; total: number };
    setSummaryTotals: React.Dispatch<
        React.SetStateAction<{ subTotal: number; shipping: number; total: number; tax: 0 }>
    >;
    grandTotal: string | number;
    setSkipShipping: React.Dispatch<React.SetStateAction<boolean>>;
    onlyTicketsInCart: boolean;
}

const ShippingOptions = ({
    title,
    endpoint,
    token,
    freePriceText,
    currencyFormat,
    errorMessage,
    onShippingSelect,
    setShippingEndpoint,
    memberData,
    setSummaryTotals,
    summaryTotals,
    grandTotal,
    onlyTicketsInCart,
    setSkipShipping,
}: IShippingOptions) => {
    const [shippingCarrier, setShippingCarrier] = useState("");
    const [methods, setMethods] = useState([]);
    const [setupError, setSetupError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const shippingRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (shippingRef?.current && !onlyTicketsInCart) {
            shippingRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [shippingRef.current, !onlyTicketsInCart]);

    useEffect(() => {
        onShippingSelect(shippingCarrier);

        const method = methods && methods.find((item) => item.carrier_code === shippingCarrier);

        const setShippingBody = {
            addressInformation: {
                shipping_address: {
                    city: memberData.city,
                    firstname: memberData.firstName,
                    lastname: memberData.lastName,
                    email: memberData.email,
                    telephone: memberData.phoneNumber,
                    postcode: memberData.postalCode,
                    street: [memberData.streetAddress],
                    country_id: memberData.countryId,
                },
                shipping_method_code: method ? method.method_code : "",
                shipping_carrier_code: shippingCarrier,
            },
        };

        (async () => {
            try {
                const responseSetShipping = await postRequest(
                    setShippingEndpoint,
                    JSON.stringify(setShippingBody),
                    token
                );
                const summaryData = await responseSetShipping.json();
                const grandTotalWithoutShipping = summaryData.totals.total_segments.find(
                    (item) => item.code === "grand_total"
                ).value;
                const shipping = summaryData.totals.base_shipping_incl_tax;
                const subTotal = parseInt(grandTotalWithoutShipping) - parseInt(shipping);

                setSummaryTotals({
                    ...summaryTotals,
                    subTotal: subTotal,
                    shipping: summaryData.totals.base_shipping_incl_tax,
                    total: summaryData.totals.base_grand_total,
                    tax: summaryData.totals.base_tax_amount,
                });

                setSkipShipping(true);
            } catch (error) {
                setIsLoading(false);
            }
        })();
    }, [shippingCarrier, grandTotal, onlyTicketsInCart]);

    function getShippingValues(shippingArr) {
        const resultArr = [];

        shippingArr.forEach((shippingObj) => {
            const { carrier_code, carrier_title, price_incl_tax } = shippingObj;
            let title;

            if (price_incl_tax === 0) {
                title = `${carrier_title}:  ${freePriceText}`;
            } else {
                title = `${carrier_title}:  ${currencyFormat.replace("price", price_incl_tax)}`;
            }

            resultArr.push({
                code: carrier_code,
                title,
            });
        });

        return resultArr;
    }

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            try {
                const data = await getData(endpoint, token, setSetupError);
                setOptions(getShippingValues(data));
                setMethods(data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                setSetupError(true);
            }
        })();
    }, [grandTotal]);

    return (
        !onlyTicketsInCart && (
            <div className={cn("ShippingOptions", { "ShippingOptions--hidden": onlyTicketsInCart })} ref={shippingRef}>
                {title && <h2 className="ShippingOptions__Title">{title}</h2>}
                {isLoading && <Loader />}
                {setupError && <ErrorMessage text={errorMessage} />}
                {options && options.length > 0 && (
                    <RadioList
                        radioList={options}
                        setValue={setShippingCarrier}
                        value={shippingCarrier}
                        className="RadioList--no-margin"
                    />
                )}
            </div>
        )
    );
};

export default ShippingOptions;
