function addImagePreview() {
    const images = <NodeListOf<HTMLImageElement>>document.querySelectorAll("#ImagePreview .lazyload");
    if (!images) return;

    images.forEach(image => {
        createImageModal(image);
    });
}

function createImageModal(img: HTMLImageElement) {
    const modal = document.getElementById("ImageModal");
    const modalImg = <HTMLImageElement>document.getElementById("ImageModal__img");
    const captionText = document.getElementById("ImageModal__caption");
    const span = document.getElementById("ImageModal__closeOverlay");
    
    img.onclick = (e:MouseEvent) => {
        const targetImage = <HTMLImageElement>e.target;
        if(targetImage && modal && modalImg && captionText) {
            modal.style.display = "flex";
            modalImg.src = targetImage.src;
            captionText.innerHTML = targetImage.alt;
        }
    };
    if(span && modal) {
        span.onclick = () => {
            modal.style.display = "none";
        }
    }
}

document.addEventListener('DOMContentLoaded', function () {
    addImagePreview();
}, false);