import * as React from "react";
import { GetRailProps } from "react-compound-slider/dist/types/Rail/types";

export interface SliderRailProps {
    getRailProps: GetRailProps;
}

export const SliderRail: React.FC<SliderRailProps> = ({ getRailProps }) => {
    return (
        <>
            <div className="CollectionSearchRange__rail CollectionSearchRange__rail_inner" />
            <div className="CollectionSearchRange__rail CollectionSearchRange__rail_outter" {...getRailProps()} />
        </>
    );
};
