import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import { LanguageSwitcherPropsWithClassName } from "../../LanguageSwitcher/LanguageSwitcher";
import { LanguageSwitcher } from "../../LanguageSwitcher/LanguageSwitcher";
import { ISignLink } from "../../SignLink/SignLink";
import SignLink from "../../SignLink/SignLink";
import classNames from "classnames";

interface NavigationTopMenuProps {
    languageSwitcher?: LanguageSwitcherPropsWithClassName;
    searchLink?: {
        url: string;
        text: string;
    };
    myPageLink?: {
        url: string;
        text: string;
    };
    signLink?: ISignLink;
    className?: string;
}

export const NavigationTopMenu = ({
    languageSwitcher,
    myPageLink,
    searchLink,
    signLink,
    className,
}: NavigationTopMenuProps) => {
    return (
        <ul className={cn("NavigationTopMenu", className)}>
            {myPageLink && myPageLink.text && signLink && signLink.isSignedIn && (
                <li className="NavigationTopMenu__item">
                    <a href={myPageLink.url} className="NavigationTopMenu__my-page-link">
                        {myPageLink.text}
                    </a>
                </li>
            )}

            {signLink && (
                <li className="NavigationTopMenu__item">
                    <SignLink
                        className="NavigationTopMenu__sign-link"
                        signInUrl={signLink.signInUrl}
                        signInLabel={signLink.signInLabel}
                        signOutLabel={signLink.signOutLabel}
                        signOutUrl={signLink.signOutUrl}
                        isSignedIn={signLink.isSignedIn}
                        serverErrorText={signLink.serverErrorText}
                        returnUrl={signLink.returnUrl}
                    />
                </li>
            )}
            {searchLink && searchLink.text && (
                <li className="NavigationTopMenu__item">
                    <a href={searchLink.url} className="NavigationTopMenu__search-link">
                        {searchLink.text}
                    </a>
                </li>
            )}
            {languageSwitcher && (
                <li className="NavigationTopMenu__item">
                    <LanguageSwitcher className="NavigationTopMenu__LanguageSwitcher" {...languageSwitcher} />
                </li>
            )}
        </ul>
    );
};
