import formatPrice from './GTMFormatPrice'

const GTMViewItemListData = ( title, path, productListProps ) => {
    const item_list = productListProps.map(product => {
      
      const item = 
        {
          item_name: product.productName,
          item_id: product.productSku,
          item_brand: product.brand,
          price: formatPrice(product.price.vatPrice, "float"),
          quantity: 1
        }
      
      return item
    })    
    
    return ({       
      
            event: "view_item_list",
            ecommerce: {
              item_list_id: path,
              item_list_name: title,
              items: item_list
            }
          

    })
}

export default GTMViewItemListData