import React from "react";
import cn from "classnames";
interface ILoader {
    isSmall?: boolean;
    isWhite?: boolean;
}
export const Loader = ({ isSmall = false, isWhite= false }: ILoader) => {
    return (
        <div 
        className={cn("LoaderContainer", {
            "LoaderContainer--small": isSmall,
            "LoaderContainer--white": isWhite
        })}>
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};
