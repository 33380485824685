import React, { useState, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import { MemberTicketsProps } from "./MemberTicketsProps.csharp";
import MemberLayout from "../Shared/Member/MemberLayout/MemberLayout";
import MemberTickets from "../Shared/MemberTickets/MemberTickets";
import { BoxPanel } from "../Shared/BoxPanel/BoxPanel";
import { ArrowLinkList } from "../../../Blocks/ArrowLinkList/ArrowLinkList";
import { getData } from "../Membership/api/get";

export const MemberTicketsPage = (props: MemberTicketsProps) => {
    const [memberCardShown, setMemberCardShown] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [serverError, setServerError] = useState(false);
    const [memberName, setMemberName] = useState("");
    const [memberLastName, setMemberLastName] = useState("");
    const [validUntilDate, setValidUntilDate] = useState("");
    const [qrCode, setQrCode] = useState("");
    const [typeOfMembership, setTypeOfMembership] = useState("");

    const TicketHistoricalItems =
        props.memberTicketHistorical && props.memberTicketHistorical.memberTicketHistoricalItems
            ? props.memberTicketHistorical.memberTicketHistoricalItems.map((item) => ({
                  url: item.orderReceiptUrl,
                  text: item.title,
              }))
            : [];
    
            useEffect(() => {
                (async () => {
                    try {
                        const responseMember = await getData(props.endpointMember, props.token, setServerError);
                        const responseMembership = await getData(props.endpointMembership, props.token, setServerError);
                        const cardNumber = responseMember?.card_list?.cards?.[0]?.card_number;
                        
                        setMemberName(responseMember.first_name);
                        setMemberLastName(responseMember.last_name);
                        setValidUntilDate(responseMembership.valid_until_date);
                        setQrCode(cardNumber || "");
                        setTypeOfMembership(responseMembership.membership_code_caption);
                        setIsLoading(false);
                    } catch (error) {
                        setIsLoading(false);
                        setServerError(true);
                        console.log(error);
                    }
                })();
            }, []);

    return (
        <MemberLayout 
            contextualMenu={props.contextualMenu} 
            memberCard={{
                ...props.memberCard,
                isOpen: memberCardShown,
                showMemberCard: setMemberCardShown,
                nameOfMember: memberName + " " + memberLastName,
                typeOfMembership: typeOfMembership,
                validTo: validUntilDate,
                qrCode: qrCode,
            }}
            isLoading={isLoading}
            serverError={serverError}
            serverErrorMessage={props.serverErrorMessage}
            title={props.title}
            showMemberCard={setMemberCardShown}
        >
            {props.memberTickets &&
                props.memberTickets.map((tickets, index) => { 
                    const formattedTickets = {
                        date: tickets.date,
                        title: tickets.intro,
                        dateLabel: tickets.ticketReceiptOrderLines[0].dateLabel,
                        timeLabel: tickets.ticketReceiptOrderLines[0].timeLabel,
                        numberOfTicketText: props.numberOfTicketText,
                        prevButtonText: props.prevButtonText,
                        nextButtonText: props.nextButtonText,
                        tickets: tickets.ticketReceiptOrderLines.map((item) => ({
                            date: item.date,
                            barcode: item.qrCode,
                            time: item.time,
                            type: item.type,
                            name: item.title,
                        })),
                    };

                    return <MemberTickets {...formattedTickets} showFutureOnly={false} key={index} />;
                })}

            {props.memberTicketHistorical && (
                <BoxPanel>
                    <ArrowLinkList
                        title={props.memberTicketHistorical.title}
                        items={TicketHistoricalItems}
                        className="ArrowLinkList--in-two-columns"
                    />
                </BoxPanel>
            )}
        </MemberLayout>
    );
};
