import * as React from "react";
import { ReactNode } from "react";
import { NavigationItem } from "../NavigationItem.csharp";
import { NavigationTranslations } from "../NavigationTranslations.csharp";
import { LanguageSwitcherViewModel } from "../../LanguageSwitcher/LanguageSwitcherViewModel.csharp";
import classNames from "classnames";
import { MenuLine, MenuLineVariant } from "./MenuLine";
import { NavigationTopMenu } from "./NavigationTopMenu";
import { OpeningHours } from './OpeningHours';
import { ISignLink } from "../../SignLink/SignLink";

export interface MegaMenuProps {
    model: {
        featuredItems: NavigationItem[];
        menuItems: NavigationItem[];
        leftItems: ReactNode[];
        bottomItems?: ReactNode[];
        openingsHoursText ?: string;
        openingsHours: string;
        openingsHoursUrl: string;
    };
    translations: NavigationTranslations;
    languageSwitcher: LanguageSwitcherViewModel;
    isExpanded: boolean;
    offsetLeft: number;
    searchLink ?: {
        url: string;
        text: string;
    },
    myPageLink?: {
        url: string;
        text: string;
    };
    signLink?: ISignLink;
}

export class MegaMenu extends React.Component<MegaMenuProps> {
    
    render(): ReactNode {
        const items: ReactNode = this.props.model.menuItems.map((item) => (
            <li className="HeaderNav__item_left left_item" key={item.url}>
                <a href={item.url}>{item.name}</a>
            </li>
        ));

        const featured = this.props.model.featuredItems.length > 0 && (
            <MenuLine model={this.props.model.featuredItems} variant={MenuLineVariant.FEATURED} />
        );

        return (
            <div
                className={classNames("MegaMenu", this.props.isExpanded ? "MegaMenu--expanded" : "MegaMenu--collapsed")}
                id="menu-content"
            >
                <div className="MegaMenu__outerContent">
                    <div className="MegaMenu_openingHours">
                        <OpeningHours
                            text={this.props.model.openingsHoursText}
                            link={{
                                url: this.props.model.openingsHoursUrl,
                                text: this.props.model.openingsHours
                            }}
                        />
                    </div>
                    <NavigationTopMenu
                        className="MegaMenu__topMenu"
                        myPageLink={this.props.myPageLink}
                        searchLink={this.props.searchLink}
                        signLink={this.props.signLink}
                        languageSwitcher={{
                            model: this.props.languageSwitcher,
                            isOpeningTop: false,
                            translations: this.props.translations,
                        }}
                    />

                    <div className="MegaMenu__inner">
                        <div className="MegaMenu__contentOuter">
                            <div className="MegaMenu__content">
                                <div className="MegaMenu_left">
                                    <ul className="MegaMenu__list MegaMenu__list--white">{this.props.model.leftItems}</ul>
                                    <ul className="MegaMenu__list">{items}</ul>
                                    <ul className="MegaMenu__bottom-menu MegaMenu__bottom-menu--in-column">{this.props.model.bottomItems}</ul>
                                </div>

                                <div className={classNames("MegaMenu_right")}>
                                    {featured}
                                </div>
                            </div>
                        </div>
                        <ul className="MegaMenu__bottom-menu MegaMenu__bottom-menu--in-bottom">{this.props.model.bottomItems}</ul>
                    </div>
                </div>
            </div>
        );
    }

    private flatten(item: NavigationItem): NavigationItem[] {
        return [item, ...item.children];
    }
}
