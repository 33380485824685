import formatPrice from './GTMFormatPrice'

const GTMPurchaseData = ( receiptOrderLines, grandTotal, vatTotal, getOrderLinesEndpoint ) => {
  
  const item_list = receiptOrderLines.map(line => {
    const item = {
      item_name: line.title,
      item_id: line.itemId,
      price: formatPrice(line.price.originalPrice, "float"),
      quantity: 1
    }

    return item
  })

  const orderId = getOrderLinesEndpoint.split("protectCode=")[1];
  
  return ({       
      event: "purchase",
      ecommerce: {
        transaction_id: orderId,
        value: formatPrice(grandTotal, "float"),
        tax: formatPrice(vatTotal, "float"),
        shipping: 0,
        currency: "NOK",
        items: item_list
      }
  })
}

export default GTMPurchaseData
