import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import cn from "classnames";
import moment from "moment";
import { Textfit } from "react-textfit";

export interface IMemberTicket {
    name?: string;
    type?: string; //"adult" | "kid" | "youngAdult" | "companion" | "member" | "unknown";
    dateLabel?: string;
    endDateTime?: string;
    timeLabel?: string;
    startDateTime?: string;
    date?: string;
    time?: string;
    barcode?: string;
    slideNumber?: number;
    slidesNumber?: number;
    numberOfTicketText?: string;
    showNumberOfTicketText?: boolean;
}

const MemberTicket = ({
    name,
    type,
    dateLabel,
    endDateTime,
    timeLabel,
    startDateTime,
    barcode,
    slideNumber,
    slidesNumber,
    numberOfTicketText,
    date,
    time,
    showNumberOfTicketText = true
}: IMemberTicket) => {

    const replaceText = (text, value1, value2) => {
        text = text.replace("{0}", value1);
        text = text.replace("{1}", value2);
        return text;
    };
    const countText =
        slideNumber && slidesNumber && numberOfTicketText && slidesNumber > 1
            ? replaceText(numberOfTicketText, slideNumber, slidesNumber)
            : null;
    const [dateStart, setDateStart] = useState("");
    const [timeSlot, setTimeSlot] = useState("");

    useEffect(() => {
        if (startDateTime && endDateTime) {
            const timeFrom = moment(startDateTime).format("HH:mm");
            const timeTo = moment(endDateTime).format("HH:mm");
            const date = moment(startDateTime).format("DD.MM.YYYY");
            setDateStart(date);
            setTimeSlot(timeFrom + "-" + timeTo);
        }
    }, [endDateTime, startDateTime]);

    return (
        <div className="MemberTicket__wrapper">
            <div
                className={cn("MemberTicket", {
                    [`MemberTicket--${type}`]: type,
                })}
            >
                {barcode && (
                    <div className="MemberTicket__qr-container">
                        <QRCode
                            value={barcode}
                            viewBox={`0 0 256 256`}
                            size={256}
                            style={{ height: "100%", maxWidth: "100%", width: "100%" }}
                            role="img"
                            aria-label="QR code"
                        />
                        <div className="MemberTicket__code">
                            <Textfit mode="single" forceSingleModeWidth={true} min={7} max={11}>
                                {barcode}
                            </Textfit>
                        </div>
                    </div>
                )}
                {name && <h3 className="MemberTicket__name">{name}</h3>}
                <div className="MemberTicket__items">
                    <dl className="MemberTicket__item">
                        <dt className="MemberTicket__item-label">{dateLabel}</dt>
                        <dd className="MemberTicket__item-value">{date ? date : dateStart}</dd>
                    </dl>
                    <dl className="MemberTicket__item">
                        <dt className="MemberTicket__item-label">{timeLabel}</dt>
                        <dd className="MemberTicket__item-value">{time ? time : timeSlot}</dd>
                    </dl>
                </div>
                <strong className="MemberTicket__logo">Munch</strong>
            </div>

            {countText && showNumberOfTicketText && <div className="MemberTicket__count-number">{countText}</div>}
        </div>
    );
};

export default MemberTicket;
