import React, { FormEvent } from "react";
import classNames from "classnames";
import { IInput, Input } from "../Input/Input";
import { Loader } from "../Loader";
import { ErrorMessage } from "../ErrorMessage";
import { ICheckBox, CheckBox } from "../Checkbox/Checkbox";
interface IForm {
    inputs: IInput[];
    onSubmit: (e: FormEvent) => void;
    richText?: string;
    autoComplete?: "on" | "off";
    confirmButtonText: string;
    disabled: boolean;
    isLoading: boolean;
    showErrorMessage: boolean;
    errorMessage: string;
    className?: string;
    newsletterSignup?: ICheckBox;
    hideNewsletterText?: boolean;
}

export const Form = ({
    inputs,
    onSubmit,
    richText,
    autoComplete,
    confirmButtonText,
    disabled,
    isLoading,
    showErrorMessage,
    errorMessage,
    className,
    hideNewsletterText = false,
    newsletterSignup,
}: IForm) => {
    return (
        <form onSubmit={(e) => onSubmit(e)} autoComplete={autoComplete} className={classNames("Form", className)}>
            <div className="Input__Container">
                {inputs.map((input) => (
                    <Input
                        type={input.type}
                        label={input.label}
                        onChange={input.onChange}
                        value={input.value}
                        isRequired={input.isRequired}
                        onBlur={input.onBlur}
                        isBig={input.isBig}
                        placeholder={input.placeholder}
                        autoComplete={input.autoComplete}
                        errorValidation={input.errorValidation}
                        errorValidationText={input.errorValidationText}
                        showError={input.showError}
                        setCountryCode={input.setCountryCode}
                        countryCode={input.countryCode}
                        key={input.label}
                        isDisabled={input.isDisabled}
                    />
                ))}
            </div>
            {!hideNewsletterText && newsletterSignup && newsletterSignup.label && (
                <CheckBox
                    label={newsletterSignup.label}
                    isChecked={newsletterSignup.isChecked}
                    setIsChecked={newsletterSignup.setIsChecked}
                />
            )}
            {richText && <div className="Form__RichText" dangerouslySetInnerHTML={{ __html: richText }} />}
            <div className="Form__Submit__Container">
                {
                    <button
                        type="submit"
                        className={classNames("button--primary button--forward ", {
                            "button--red": !disabled,
                        })}
                        disabled={disabled}
                    >
                        {isLoading ? <Loader isSmall isWhite /> : confirmButtonText}
                    </button>
                }
                <div role="alert" className="Form__alert">
                    {showErrorMessage && <ErrorMessage text={errorMessage} />}
                </div>
            </div>
        </form>
    );
};
