import * as React from "react";
import {ICardViewModel} from "../../../Partials/Card/ICardViewModel.csharp";
import {EventCalendarTranslations} from "./EventCalendarTranslations.csharp";
import {CardsList} from "../../../Blocks/CardsList/CardsList";
import {CardsListVariant} from "../../../Blocks/CardsList/CardsListVariant.csharp";
import {PictureProfile} from "../../../../ResizedImage/PictureProfile.csharp";

export interface ExhibitionsListProps {
    exhibitions: ICardViewModel[];
    profile: PictureProfile;
    translations: EventCalendarTranslations;
    allExhibitionsUrl: string;
}

export const ExhibitionsList = (props: ExhibitionsListProps) => {

    return <div className="ExhibitionsList">
        <div className="EventsCalendar__sideList">
            {props.exhibitions.length > 0
                ? <CardsList className="EventCalendar__list"
                             defaultProfile={props.profile}
                             featuredProfile={props.profile}
                             translations={props.translations.cardsList}
                             variant={CardsListVariant.Grid}
                             model={{
                                 title: '',
                                 cards: props.exhibitions,
                                 alwaysExpanded: true,
                                 showFeatured: false,
                                 hideLink: false
                             }}
                />
                : <p>{props.translations.noExhibitionsForFilters}</p>}
        </div>
        <a href={props.allExhibitionsUrl}
           className="EventCalendar__button button--secondary">{props.translations.showAllExhibitions}</a>
    </div>
};
