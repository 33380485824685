import React, { useState, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import ContextualMenu from "../ContextualMenu/ContextualMenu";
import { Loader } from "../../Loader";
import { ErrorMessage } from "../../ErrorMessage";
import MemberCard from "../MemberCard/MemberCard";
import { IContextualMenu } from "../ContextualMenu/ContextualMenu";
import { IMemberCard } from "../MemberCard/MemberCard";

interface IMemberLayout {
    title?: string;
    children?: React.ReactNode;
    contextualMenu?: IContextualMenu;
    memberCard?: IMemberCard;
    serverError?: boolean;
    isLoading?: boolean;
    serverErrorMessage?: string;
    showMemberCard?: (val: any) => any;
}

const MemberLayout = ({
    title,
    children,
    contextualMenu,
    memberCard,
    serverError = false,
    isLoading = false,
    serverErrorMessage,
    showMemberCard = () => {},
}: IMemberLayout) => {
    return (
        <div className="MemberLayout">
            <div className="MemberLayout__Container">
                {contextualMenu && (
                    <ContextualMenu
                        {...contextualMenu}
                        showMemberCard={showMemberCard}
                    />
                )}
                {title && <h1 className="MemberLayout__Title">{title}</h1>}
                {serverError && (
                    <div className="MemberLayout__server-error">
                        <div className="wrapper">
                            <ErrorMessage text={serverErrorMessage} />
                        </div>
                    </div>
                )}
                {isLoading && (
                    <div className="MemberLayout__loader">
                        <Loader />
                    </div>
                )}

                {children}

                {memberCard && <MemberCard {...memberCard} showMemberCard={showMemberCard} />}
            </div>
        </div>
    );
};

export default MemberLayout;
