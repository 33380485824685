import React, { useEffect, useState, useRef } from "react";
import FocusTrap from "focus-trap-react";
import useAnimateHeight from "../../../Pages/Ecommerce/Shared/hooks/useAnimateHeight.hook";
import useScrollListener from "../../../Pages/Ecommerce/Shared/hooks/useScrollListener.hook";
import useOutsideClick from "../../../Pages/Ecommerce/Shared/hooks/useOutsideClick.hook";
import useEscape from "../../../Pages/Ecommerce/Shared/hooks/useEscape.hook";
import { Cart } from "../Components/Cart";
import { initCart } from "../../../Pages/Ecommerce/Shared/CartApi/initCart";
import cn from "classnames";
import { SkipToContent } from "../../Navigation/Components/SkipToContent";
import { Hamburger } from "../../Navigation/Components/Hamburger";
import { LanguageSwitcherViewModel } from "../../LanguageSwitcher/LanguageSwitcherViewModel.csharp";
import { LanguageSwitcher } from "../../LanguageSwitcher/LanguageSwitcher";
import { ISignLink } from "../../SignLink/SignLink";

import { OpeningHours } from "../../Navigation/Components/OpeningHours";
import { NavigationTopMenu } from "../../Navigation/Components/NavigationTopMenu";

interface NavigationEcomProps {
    props: any;
    productCount?: number;
    products?: {
        id: number;
        uid: string;
        product: {
            sku: string;
            stock_status: string;
            name: string;
            small_image: {
                label: string;
                url: string;
            };
            price_range: {
                maximum_price: {
                    final_price: {
                        currency: string;
                        value: number;
                    };
                };
            };
        };
        prices: {
            row_total_including_tax: {
                value: number;
            };
        };
        configurable_options?: { value_label: string }[];
        quantity: number;
    }[];
    signLink?: ISignLink;
    menuItems: {
        url: string;
        categoryName: string;
    }[];
    cartIsOpen?: boolean;
    setCartIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setCartId?: React.Dispatch<React.SetStateAction<string>>;
    cartId?: string;
    setCart?: React.Dispatch<React.SetStateAction<any[]>>;
    setNumberOfProducts?: React.Dispatch<React.SetStateAction<number>>;
    setTickets?: React.Dispatch<React.SetStateAction<any[]>>;
    tickets?: {
        item_id: number;
        sku: string;
        name: string;
        qty: number;
        price: number;
        product_type: string;
        extension_attributes?: {
            ticket_purchase: {
                admissions: {
                    start_utc_timestamp: number;
                    end_utc_timestamp: number;
                }[];
            };
        };
    }[];
}

export const NavigationEcom = ({
    props,
    productCount,
    products,
    cartIsOpen,
    setCartIsOpen,
    setCartId,
    setCart,
    menuItems,
    setNumberOfProducts,
    cartId,
    setTickets,
    tickets,
}: NavigationEcomProps) => {
    const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
    const [height, setHeight] = React.useState<number | undefined>(menuIsOpen ? undefined : 0);
    const [headerIsVisible, setHeaderIsVisible] = React.useState(true);
    const [headerBackground, setHeaderBackground] = React.useState(false);
    const [lastHeaderY, setLastHeaderY] = React.useState<number>(0);
    const [currentPagePath, setCurrentPagePath] = useState("");

    const collapseRef = React.useRef<HTMLDivElement>(null);
    const scrollData = useScrollListener();
    const outsideCartRef = useRef();

    useEscape(() => setMenuIsOpen(false));

    useAnimateHeight(menuIsOpen, setHeight, collapseRef);

    const toggleMenu = () => {
        setMenuIsOpen(!menuIsOpen);
        setCartIsOpen(false);
    };

    const toggleCart = () => {
        setCartIsOpen(!cartIsOpen);
        setMenuIsOpen(false);
    };

    useOutsideClick(outsideCartRef, () => {
        cartIsOpen && setCartIsOpen(false);
    });

    useEffect(() => {
        if (menuIsOpen) return;
        if (productCount > 0) {
            setHeaderIsVisible(true);
        }
        if (lastHeaderY + 80 > scrollData.y && scrollData.lastY < scrollData.y && !headerIsVisible) {
            // scrolled down less than 200px
            setHeaderBackground(true);
            setHeaderIsVisible(true);
        } else if (
            lastHeaderY + 80 <= scrollData.y &&
            scrollData.lastY < scrollData.y &&
            headerIsVisible &&
            productCount === 0
        ) {
            // scrolled down more than 200px

            setHeaderIsVisible(false);
        } else if (scrollData.lastY > scrollData.y) {
            // scrolled up

            if (!headerIsVisible) {
                setHeaderBackground(true);
                setHeaderIsVisible(true);
            }
            if (scrollData.y !== lastHeaderY) {
                // update header y if it has changed

                setLastHeaderY(scrollData.y);
            }
        }
    }, [scrollData, lastHeaderY, headerIsVisible, menuIsOpen, productCount]);

    React.useEffect(() => {
        initCart(
            props.languageSwitcherViewModel?.currentLanguage,
            props.cart.accessToken,
            props.graphQlEndpoint,
            props.cart.cartId,
            setCartId,
            props.cart.addCartIdEndpoint,
            props.cart.endpointGuestCartNoId,
            props.cart.endpointMemberCartItems,
            setTickets,
            setCart,
            null,
            setNumberOfProducts
        );

        if (window) {
            const currentURL = new URL(window.location.href);
            const pathnameWithQuery = currentURL.pathname + currentURL.search;
            setCurrentPagePath(pathnameWithQuery ? pathnameWithQuery : "");
        }
    }, []);

    const ShopTag = ({ url, text }) => {
        const Tag = url ? "a" : "div";
        const props = {
            className: url
                ? "NavigationEcommerce__sub-title NavigationEcommerce__sub-title--with-link"
                : "NavigationEcommerce__sub-title",
            ...(url && { href: url }),
        };
        return <Tag {...props}>{text}</Tag>;
    };

    return (
        <>
            <SkipToContent text={props.translations.skipToContent} />
            <FocusTrap active={menuIsOpen}>
                <nav
                    className={cn("NavigationEcommerce", {
                        "NavigationEcommerce--is-open": menuIsOpen,
                        "NavigationEcommerce--hidden": !headerIsVisible,
                        "NavigationEcommerce--has-information-banner": props.informationBannerText,
                        "NavigationEcommerce--has-background": headerBackground,
                        "NavigationEcommerce--has-background-from-start": props.model.hasBackground,
                        "NavigationEcommerce--scrolled": scrollData.y > 1,
                    })}
                >
                    <div className="NavigationEcommerce__header-wrapper">
                        {props.informationBannerText && (
                            <div
                                className="NavigationEcommerce__informatio-banner"
                                dangerouslySetInnerHTML={{ __html: props.informationBannerText }}
                            ></div>
                        )}
                        <div className="NavigationEcommerce__header wrapper">
                            <div className="NavigationEcommerce__logo">
                                <a className="NavigationEcommerce__logo-text" href={props.model.homepageUrl}>
                                    MUNCH
                                </a>
                                <ShopTag url={props.model.categoryPageUrl} text={props.model.subTitle} />
                            </div>
                            {menuIsOpen && (
                                <div className="NavigationEcommerce__header-opening-hours">
                                    <OpeningHours
                                        text={props.model.openingHours.openingHoursString}
                                        link={{
                                            url: props.model.openingHours.openingHoursLink.openingHoursUrl,
                                            text: props.model.openingHours.openingHoursLink.openingHoursUrlText,
                                        }}
                                    />
                                </div>
                            )}

                            <NavigationTopMenu
                                className="NavigationEcommerce__HeaderTopMenu"
                                myPageLink={{
                                    text: props.model.loginLogoutViewModel.myPageLinkText,
                                    url: props.model.loginLogoutViewModel.myPageLinkUrl,
                                }}
                                signLink={{
                                    signInUrl: props.model.loginLogoutViewModel.logInUrl,
                                    signOutUrl: props.model.loginLogoutViewModel.logOutUrl,
                                    signOutLabel: props.model.loginLogoutViewModel.logOutText,
                                    signInLabel: props.model.loginLogoutViewModel.logInText,
                                    isSignedIn: props.model.loginLogoutViewModel.isLoggedIn,
                                    serverErrorText: props.model.loginLogoutViewModel.serverErrorText,
                                    returnUrl: props.model.loginLogoutViewModel.returnUrl
                                        ? props.model.loginLogoutViewModel.returnUrl
                                        : currentPagePath,
                                }}
                                languageSwitcher={{
                                    model: props.languageSwitcherViewModel,
                                    isOpeningTop: false,
                                    translations: {
                                        collapse: props.translations.collapse,
                                        expand: props.translations.expand,
                                    },
                                }}
                            />

                            <LanguageSwitcher
                                className="NavigationEcommerce__LanguageSwitcher"
                                model={props.languageSwitcherViewModel}
                                isOpeningTop={false}
                                translations={{
                                    collapse: props.translations.collapse,
                                    expand: props.translations.expand,
                                }}
                            />

                            {productCount !== 0 && (
                                <button
                                    aria-label={cartIsOpen ? "Lukk handlekurv" : "Åpne handlekurv"}
                                    aria-expanded={cartIsOpen}
                                    aria-controls="cart-content"
                                    onClick={toggleCart}
                                    className="NavigationEcommerce__cart-button"
                                >
                                    <span className="NavigationEcommerce__cart-text">{props.cart.title}</span>
                                    <span className="NavigationEcommerce__cart-counter">{productCount}</span>
                                </button>
                            )}
                            <Hamburger
                                isExpanded={menuIsOpen}
                                toggleMenu={toggleMenu}
                                ariaLabel={props.translations.menu}
                                className="NavigationEcommerce__hamburger-button"
                            />
                        </div>
                    </div>

                    <div
                        id="cart-content"
                        className={cn("NavigationEcommerce__cart-wrapper", {
                            "NavigationEcommerce__cart-wrapper--is-open": cartIsOpen,
                        })}
                    >
                        <div
                            ref={outsideCartRef}
                            className={cn("NavigationEcommerce__cart", {
                                "NavigationEcommerce__cart--is-open": cartIsOpen,
                            })}
                        >

                                <Cart
                                    products={products}
                                    {...props.cart}
                                    productCount={productCount}
                                    setCartIsOpen={setCartIsOpen}
                                    cartId={cartId}
                                    graphQlEndpoint={props.graphQlEndpoint}
                                    setCart={setCart}
                                    setNumberOfProducts={setNumberOfProducts}
                                    tickets={tickets}
                                    setTickets={setTickets}
                                    currentLanguage={props.languageSwitcherViewModel?.currentLanguage}
                                />

                        </div>
                    </div>

                    {menuItems && menuItems.length !== 0 && (
                        <div
                            id="menu-content"
                            className={cn("NavigationEcommerce__content-wrapper", {
                                "NavigationEcommerce__content-wrapper--is-open": menuIsOpen,
                            })}
                            style={{ height }}
                        >
                            <div ref={collapseRef} className="NavigationEcommerce__height-wrapper">
                                <div className="NavigationEcommerce__opening-hours wrapper">
                                    <OpeningHours
                                        text={props.model.openingHours.openingHoursString}
                                        link={{
                                            url: props.model.openingHours.openingHoursLink.openingHoursUrl,
                                            text: props.model.openingHours.openingHoursLink.openingHoursUrlText,
                                        }}
                                    />
                                </div>
                                <div className="NavigationEcommerce__top-links wrapper">
                                    <NavigationTopMenu
                                        className="NavigationEcommerce__TopMenu"
                                        myPageLink={{
                                            text: props.model.loginLogoutViewModel.myPageLinkText,
                                            url: props.model.loginLogoutViewModel.myPageLinkUrl,
                                        }}
                                        signLink={{
                                            signInUrl: props.model.loginLogoutViewModel.logInUrl,
                                            signOutUrl: props.model.loginLogoutViewModel.logOutUrl,
                                            signOutLabel: props.model.loginLogoutViewModel.logOutText,
                                            signInLabel: props.model.loginLogoutViewModel.logInText,
                                            isSignedIn: props.model.loginLogoutViewModel.isLoggedIn,
                                            serverErrorText: props.model.loginLogoutViewModel.serverErrorText,
                                            returnUrl: props.model.loginLogoutViewModel.returnUrl
                                                ? props.model.loginLogoutViewModel.returnUrl
                                                : currentPagePath,
                                        }}
                                        languageSwitcher={{
                                            model: props.languageSwitcherViewModel,
                                            isOpeningTop: false,
                                            translations: {
                                                collapse: props.translations.collapse,
                                                expand: props.translations.expand,
                                            },
                                        }}
                                    />
                                </div>
                                <div className="NavigationEcommerce__list wrapper">
                                    <ul className="NavigationEcommerce__list-items">
                                        {menuItems.map((item) => (
                                            <li className="NavigationEcommerce__list-item" key={item.url}>
                                                <a className="NavigationEcommerce__list-link" href={item.url}>
                                                    {item.categoryName}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {props.model.ecomBottomMenu && (
                                    <ul className="NavigationEcommerce__bottom-items wrapper">
                                        {props.model.ecomBottomMenu.map((item) => (
                                            <li className="NavigationEcommerce__bottom-item" key={item.url}>
                                                <a className="NavigationEcommerce__bottom-item-link" href={item.url}>
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    )}
                </nav>
            </FocusTrap>
        </>
    );
};
