import * as React from "react";
import { NavigationSmallProps } from "../NavigationSmallProps.csharp";
import { LanguageSwitcher } from "../../LanguageSwitcher/LanguageSwitcher";
import { SkipToContent } from "../../Navigation/Components/SkipToContent";

export const NavigationSmall = (props: NavigationSmallProps) => {
    return (
        <nav className="NavigationSmall">
            <SkipToContent text={props.translations.skipToContent} />
            <ul className="NavigationSmall__items">
                <li className="NavigationSmall__logo">
                    <a href={props.model.homepageUrl}>Munch</a>
                </li>

                <li className="NavigationSmall__item NavigationSmall__item--language-switcher">
                    <LanguageSwitcher
                        model={props.languageSwitcherViewModel}
                        isOpeningTop={false}
                        translations={props.translations}
                        className="LanguageSwitcher--in-NavigationSmall"
                    />
                </li>
                {props.model.allTicketsText && (
                    <li className="NavigationSmall__item">
                        <a href={props.model.allTicketsUrl} className="NavigationSmall__item-link">{props.model.allTicketsText}</a>
                    </li>
                )}
            </ul>
        </nav>
    );
};
