import { Dispatch, SetStateAction } from "react";

export const netsPayment = async (
    endpointGetNetsUrl: string,
    setShowErrorMessage: Dispatch<SetStateAction<boolean>>,
    clearLoader: () => void,
) => {
    try {
        const headersNets = new Headers();
        headersNets.append("Accept", "application/json");
        headersNets.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
            method: "GET",
            headers: headersNets,
            redirect: "follow",
        };

        await fetch(endpointGetNetsUrl, requestOptions)
            .then((response) => {
                if (response.ok) return response.json();
            })
            .then((result) => {
                window.location.href = result.paymentUrl;
            })
            .catch((error) => {
                console.log(error);
                setShowErrorMessage(true)
            });
    } catch (e) {
        console.log(e);

        setShowErrorMessage(true);
        clearLoader();
    }
};
