import React, { useState, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import { MembershipProps } from "./MembershipProps.csharp";
import MembershipExpiration from "../Membership/MembershipExpiration/MembershipExpiration";
import PersonalInfo from "../Membership/PersonalInfo/PersonalInfo";
import MemberLayout from "../Shared/Member/MemberLayout/MemberLayout";
import { getData } from "./api/get";

export const MembershipPage = (props: MembershipProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [serverError, setServerError] = useState(false);
    const [validUntilDate, setValidUntilDate] = useState("");
    const [memberName, setMemberName] = useState("");
    const [typeOfMembership, setTypeOfMembership] = useState("");
    const [qrCode, setQrCode] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [disablePersonalInfoEdit, setDisablePersonalInfoEdit] = useState(true);
    const [memberCardShown, setMemberCardShown] = useState(false);
    const [memberResponse, setMemberResponse] = useState({
        email: "",
        first_name: "",
        last_name: "",
        phone_no: "",
        postcode: "",
        city: "",
        address: "",
        id: "",
    });

    useEffect(() => {
        (async () => {
            try {
                const responseMember = await getData(props.endpointMember, props.token, setServerError);
                const responseMembership = await getData(props.endpointMembership, props.token, setServerError);
                const responseDetailedMembership = await getData(props.endpointDetailedMembership, props.token, setServerError);
                const cardNumber = responseDetailedMembership?.card_list?.cards?.[0]?.card_number;

                setValidUntilDate(responseMembership.valid_until_date);
                setCountryCode(responseMember.bill_to_customer.address.country_code);
                setMemberResponse({
                    email: responseMember.bill_to_customer.address.email,
                    first_name: responseMember.bill_to_customer.address.person.first_name,
                    last_name: responseMember.bill_to_customer.address.person.last_name,
                    phone_no: responseMember.bill_to_customer.address.telephone,
                    postcode: responseMember.bill_to_customer.address.postcode,
                    city: responseMember.bill_to_customer.address.city,
                    address: responseMember.bill_to_customer.address.address,
                    id: responseMember.bill_to_customer.id
                });
                setMemberName(responseMember.membership.display_name);
                setQrCode(cardNumber || "");
                setTypeOfMembership(responseMembership.membership_code_caption);
                setIsLoading(false);
                setDisablePersonalInfoEdit(false);
            } catch (error) {
                setIsLoading(false);
                setServerError(true);
                console.log(error);
            }
        })();
    }, []);

    const memberCardData = {
        isOpen: memberCardShown,
        nameOfMemberLabel: props.nameOfMemberLabel,
        nameOfMember: memberName,
        typeOfMembershipLabel: props.typeOfMembershipLabel,
        typeOfMembership: typeOfMembership,
        validTo: validUntilDate,
        qrCode: qrCode,
        validToLabel: props.validToLabel, 
        closeButtonText: props.closeButtonText,
        title: props.memberCardTitle
    };
    return (
        <MemberLayout
            contextualMenu={{ ...props.contextualMenu, numberOfTickets: props.numberOfTickets }}
            memberCard={memberCardData}
            isLoading={isLoading}
            showMemberCard={setMemberCardShown}
            serverError={serverError}
            serverErrorMessage={props.serverErrorMessage }
        >
            {props.title && <h1 className="MembershipPage__Title">{props.title}</h1>}
            {props.membershipExpiration && <MembershipExpiration {...props.membershipExpiration} expirationDate={validUntilDate} />}

            {memberResponse && !isLoading && (
                <PersonalInfo
                    {...props.customerInformation}
                    id={memberResponse.id}
                    email={memberResponse.email}
                    firstName={memberResponse.first_name}
                    lastName={memberResponse.last_name}
                    phoneNumber={memberResponse.phone_no}
                    token={props.token}
                    postalCode={memberResponse.postcode}
                    city={memberResponse.city}
                    streetAddress={memberResponse.address}
                    changeCustomerInformation={...props.customerInformation.changeCustomerInformation}
                    modalCloseButtonText={props.modalCloseButtonText}
                    modalContentLabel={props.modalContentLabel}
                    graphQlUrl={props.graphQlUrl}
                    disableEditButton={disablePersonalInfoEdit}
                    countryCode={countryCode}
                />
            )}
        </MemberLayout>
    );
};
