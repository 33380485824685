import formatPrice from './GTMFormatPrice'

const GTMViewItemData = ( productProps ) => {

  return ({       
      event: "view_item",
      ecommerce: {
        currency: "NOK",
        value: formatPrice(productProps.price.vatPrice, "float"),
        items: [
          {
            item_id: productProps.productSku && productProps.productSku,
            item_name: productProps.title && productProps.title,
            index: 0,
            item_brand: productProps.brand && productProps.brand,
            item_category: productProps.breadcrumbs && productProps.breadcrumbs.slice(-1)[0].text,
            price: productProps.price.vatprice && formatPrice(productProps.price.vatPrice, "float"),
            quantity: 1
          }
        ]
      }
  })
}

export default GTMViewItemData