import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { ErrorMessage } from "../../Shared/ErrorMessage";
import classnames from "classnames";
import { ITicket, ITimeSlot } from "../TicketPage";
import { formatTime } from "../utils/formatTime";
import { Loader } from "../../Shared/Loader";
interface ITicketTimeSlotSelector {
    editorialProps: IEditorialTimeSlotProps;
    selectedTime: ITimeSlot | null;
    setSelectedTime: Dispatch<SetStateAction<ITimeSlot | null>>;
    ticketsWarningLimit: number;
    availableTimeSlots: ITimeSlot[];
    schedulesError: boolean;
    noTimeslotsError: boolean;
    errorMessage: string;
    isLoading: boolean;
    setShowSummary: Dispatch<SetStateAction<boolean>>;
    ticketData: ITicket[];
    noTimeslotsErrorMessage: string;
}

export interface IEditorialTimeSlotProps {
    title: string;
    ingress: string;
    fewTicketsLeftText: string;
    soldOutText: string;
    endpoint: string;
    ticketsWarningLimit: number;
    showTimeslots: boolean;
    ticketsSoldOutLimit: number;
}

export const TicketTimeSlotSelector = ({
    editorialProps,
    selectedTime,
    setSelectedTime,
    ticketsWarningLimit,
    availableTimeSlots,
    schedulesError,
    noTimeslotsError,
    errorMessage,
    isLoading,
    setShowSummary,
    ticketData,
    noTimeslotsErrorMessage
}: ITicketTimeSlotSelector) => {
    const ref = useRef<null | HTMLDivElement>(null);
    const listRef = useRef(null);


    const setFocusOnFirstTimeSlot = () => {
        if (availableTimeSlots && availableTimeSlots.length > 0 && listRef.current) {
            const button = listRef.current.querySelector("li:first-child .TicketTimeslotSelector__button");
            button.focus();
        }
    };

    useEffect(() => {
        if (ref?.current) {
            setTimeout(() => {
                ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
                setTimeout(() => {
                    setFocusOnFirstTimeSlot();
                }, 600),
                300;
            });
        }
    }, [availableTimeSlots]);
    

    return (
        <div className="TicketTimeslotSelector__container" ref={ref}>
            <div className="TicketPage__hero">
                <h2 className="TicketPage__panelTitle">{editorialProps.title}</h2>
                <p className="TicketPage__paragraph">{editorialProps.ingress}</p>
            </div>
            {!schedulesError && !noTimeslotsError ? (
                isLoading ? (
                    <Loader />
                ) : (
                    <ul className="TicketTimeslotSelector__wrapper" ref={listRef}>
                        {availableTimeSlots &&
                            availableTimeSlots.map((timeSlot: ITimeSlot) => {
                                const now = new Date().getTime();
                                if (timeSlot.salable_until_utc_timestamp * 1000 > now) {
                                    const isSoldOut =
                                        timeSlot.remaining_slots === 0 ||
                                        timeSlot.remaining_slots - editorialProps.ticketsSoldOutLimit <
                                            ticketData.reduce((prev, curr) => prev + curr.count, 0)
                                    return (
                                        <li
                                            key={timeSlot.no}
                                            className={classnames({
                                                "TicketTimeslotSelector__button--selected": selectedTime === timeSlot,
                                            })}
                                        >
                                            <button
                                                className={"TicketTimeslotSelector__button"}
                                                disabled={isSoldOut}
                                                onClick={() => {
                                                    setSelectedTime(timeSlot);
                                                    setShowSummary(true);
                                                }}
                                            >
                                                <span className="TicketTimeslotSelector__timeLabel">
                                                    {formatTime(timeSlot.start_utc_timestamp)}
                                                    {editorialProps.showTimeslots &&
                                                        " - " + formatTime(timeSlot.end_utc_timestamp)}
                                                </span>
                                                {(timeSlot.remaining_slots <= ticketsWarningLimit || isSoldOut) && (
                                                    <span className="TicketTimeslotSelector__label">
                                                        {isSoldOut
                                                            ? editorialProps.soldOutText
                                                            : editorialProps.fewTicketsLeftText}
                                                    </span>
                                                )}
                                            </button>
                                        </li>
                                    );
                                }
                            })}
                    </ul>
                )
            ) : (
                <ErrorMessage text={noTimeslotsError ? noTimeslotsErrorMessage : errorMessage} />
            )}
        </div>
    );
};
