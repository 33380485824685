import React, { KeyboardEvent, useState, useEffect } from "react";
import classNames from "classnames";

interface IIncrementSelector {
    count: number;
    numberLabel?: string;
    id: number;
    name: string;
    changeCount?: (id?: number, sku?: string, itemId?: number, count?: number) => void;
    first: boolean;
    reduceAriaLabel: string;
    increaseAriaLabel: string;
    min?: number;
    max?: number;
    sku?: string;
    productId?: any;
    hasNormalDesktopLayout?: boolean;
    hasNormalMobileLayout?: boolean;
    disableMinus?: boolean;
    disablePlus?: boolean;
    enableNumberInput?: Boolean;
}

export const IncrementSelector = ({
    count,
    numberLabel,
    id,
    name,
    changeCount,
    sku,
    first,
    reduceAriaLabel,
    increaseAriaLabel,
    productId,
    hasNormalDesktopLayout = true,
    hasNormalMobileLayout = true,
    disableMinus,
    disablePlus,
    min,
    max,
    enableNumberInput = false,
}: IIncrementSelector) => {
    const disabled = disableMinus && disablePlus;
    const [countValue, setCountValue] = useState(count);
    const isChosen: boolean = enableNumberInput ? countValue > 0 :   count > 0 ;

    useEffect(() => {
        setCountValue(count);
    }, [count]);

    useEffect(() => {
        if(countValue === 0){
            changeCount(id, sku, productId, countValue);
        }
    }, [countValue]);

    const parseNumber = (value, maxNumber) => {
        let number = parseFloat(value);
    
        if (!isNaN(number) || (typeof value === "string" && value.trim() === "-")) {
            if (!isNaN(maxNumber)) {
                return isNaN(number) ? 0 : Math.min(Math.max(number, 0), maxNumber);
            } else {
                return isNaN(number) ? 0 : Math.max(number, 0);
            }
        } else {
            return null;
        }
    };
    
    const parseNumberWithoutLeadingZeros = (value, maxNumber) => {
        if (typeof value === "string") {
            const trimmedValue = value.replace(/^-?0+/, '').replace(/^(-)?/, (match, p1) => p1 ? '-' : '');
            return parseNumber(trimmedValue, maxNumber);
        } else {
            return parseNumber(value, maxNumber);
        }
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLSpanElement>) => {
        const key = event.key;

        if (key === "ArrowRight" || key === "ArrowUp") {
            if (!disablePlus) changeCount(id, sku, productId, countValue + 1);
            event.preventDefault();
            event.stopPropagation();
        } else if (key === "ArrowLeft" || key === "ArrowDown") {
            if (!disableMinus) changeCount(id, sku, productId, countValue > 0 ? countValue - 1 : 0);
            event.preventDefault();
            event.stopPropagation();
        }
    };

    return (
        <div
            className={classNames("IncrementSelector", {
                top_border: first || sku || !hasNormalDesktopLayout,
                "top_border--mobile-only": !hasNormalMobileLayout,
                "IncrementSelector--chosen": isChosen && !sku,
                "IncrementSelector--disabled": disabled,
            })}
        >
            <button
                className={classNames("IncrementSelector__selector-button", {
                    "IncrementSelector__minus-button": (!isChosen && !sku) || sku,

                    "IncrementSelector__minus-button-white": isChosen && !sku,
                })}
                disabled={enableNumberInput ? countValue === 0 : count === 0}
                onClick={() => {
                    changeCount(id, sku, productId, countValue - 1);
                }}
                tabIndex={-1}
                aria-label={reduceAriaLabel}
            />
                
            {enableNumberInput ? (
                <>
                    
                    <label htmlFor={'increment-id-' + id} className="IncrementSelector__number-label">{numberLabel}</label>
                    <input
                        id={'increment-id-' + id}
                        className="IncrementSelector__number-input"
                        type="number"
                        value={countValue == null ? "" : String(countValue)}
                        onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            if (!isNaN(newValue)) {
                                setCountValue(parseNumberWithoutLeadingZeros(newValue, max));
                            } else {
                                setCountValue(0);
                            }
                        }}
                        onBlur={(e) => {
                            if (countValue !== count) {
                                changeCount(id, sku, productId, countValue);
                            }
                        }}
                        onFocus={(e) => e.target.select()}
                    />
                </>
            ) : (
                <div
                    aria-labelledby={name.replace(" ", "_")}
                    role="spinbutton"
                    aria-valuenow={count}
                    aria-valuetext={String(count)}
                    aria-valuemin={min}
                    aria-valuemax={max}
                    tabIndex={0}
                    onKeyDown={(e) => {
                        handleKeyPress(e);
                    }}
                >
                    {count}
                </div>
            )}

            <button
                className={classNames("IncrementSelector__selector-button", {
                    "IncrementSelector__plus-button": (!isChosen && !sku) || sku,
                    "IncrementSelector__plus-button-white": isChosen && !sku,
                })}
                onClick={() => {
                    changeCount(id, sku, productId, countValue + 1);
                }}
                tabIndex={-1}
                aria-label={increaseAriaLabel}
                disabled={disablePlus}
            />
        </div>
    );
};
