import * as React from "react";
import ReactPlayer from "react-player";
import {ComponentProps} from "../../ReactComponents/ComponentProps.csharp";
import {VideoFigureViewModel} from "./VideoFigureViewModel.csharp";

export class VideoFigure extends React.Component<ComponentProps<VideoFigureViewModel>> {

    render() {
        const { url, caption, copyright, width, height } = this.props.model;

        const figCaption = caption || copyright
            ? <figcaption className="FigureCaption">
                {caption && <p className="FigureCaption__description">{caption}</p>}
                {copyright && <p className="FigureCaption__copyright">{copyright}</p>}
            </figcaption>
            : "";

        const video = <ReactPlayer
            url={url}
            controls={true}
            width="100%"
            height="100%"
        />;
        
        const paddingBottom = height/width;
        
        return (
            <figure className="VideoFigure">
                <div className="VideoFigure__videoContainer" style={{paddingBottom: paddingBottom*100 + '%'}}>
                    {video}
                </div>
                {figCaption}
            </figure>
        )
    }
}
