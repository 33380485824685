import * as React from "react";
import { Collapse } from "react-collapse";
import classNames from "classnames";
import { Animations } from "../../ReactComponents/animations";
import { Card } from "../../Partials/Card/Card";
import { CardsListProps } from "./CardsListProps.csharp";
import { ICardViewModel } from "../../Partials/Card/ICardViewModel.csharp";
import { CardVariant } from "../../Partials/Card/CardVariant.csharp";
import { CardsListVariant } from "./CardsListVariant.csharp";
import { isValidElement, ReactNode } from "react";
import * as Bowser from "bowser";
import Reveal from "react-reveal/Reveal";

export class CardsList extends React.Component<CardsListProps, { isExpanded: boolean }> {
    currentExhibitionLength = this.props.model.cards.length;
    variant = this.props.variant || CardsListVariant.Grid;

    static CARDS_PER_ROW = 4;

    constructor(props: CardsListProps) {
        super(props);

        this.state = {
            isExpanded: props.model.alwaysExpanded,
        };

        this.toggleExpand = this.toggleExpand.bind(this);
    }

    render() {
        const smallCards = this.getSmallCards();

        const visibleSmallCards = this.state.isExpanded
            ? smallCards
            : smallCards.slice(0, this.getCollapsedItemsCount());

        const link =
            this.props.model.link != null ? (
                <a href={this.props.model.link.target} className = "CardList__button button--secondary button--forward" >
                    {this.props.model.link.text}
                </a>
            ) : (
                <></>
            );

        return (
            <section
                className={classNames("CardsList", this.props.className, { "CardsList--featured": this.isFeatured })}
            >
                {this.props.model.title && <h2 className="CardsList__title">{this.props.model.title}</h2>}

                <Collapse isOpened={true} springConfig={Animations.spring}>
                    {this.renderFeaturedCards()}
                    {this.renderSmallCards(visibleSmallCards)}
                </Collapse>
                <Reveal disabled={this.isAnimationDisabled} effect="cardAnimation">
                    <div className="CardsList__buttons">
                        {this.renderShowMoreButton(smallCards)}
                        {link}
                    </div>
                </Reveal>
            </section>
        );
    }

    renderShowMoreButton(smallCards: ICardViewModel[]): ReactNode {
        return !this.props.model.alwaysExpanded && smallCards.length > this.getCollapsedItemsCount() ? (
            <Reveal disabled={this.isAnimationDisabled} effect="cardAnimation">
                <button
                    className={classNames(
                        "CardList__button",
                        "button--secondary",
                        this.state.isExpanded ? "button--collapse" : "button--expand"
                    )}
                    onClick={this.toggleExpand}
                    aria-expanded={this.state.isExpanded}
                >
                    <span>
                        {this.state.isExpanded ? this.props.translations.showLess : this.props.translations.showMore}
                    </span>
                </button>
            </Reveal>
        ) : (
            <></>
        );
    }

    renderFeaturedCards(): ReactNode {
        if (!this.isFeatured()) {
            return <></>;
        }

        return (
            <>
                {this.currentExhibitionLength > 0 && (
                    <div className="CardsList__featuredCards">
                        <div>
                            <Reveal disabled={this.isAnimationDisabled} effect="cardAnimation">
                                <Card
                                    profile={this.props.featuredProfile}
                                    model={this.props.model.cards[0]}
                                    translations={this.props.translations.card}
                                    variant={CardVariant.Default}
                                    featured={true}
                                />
                            </Reveal>
                        </div>

                        {this.currentExhibitionLength > 1 && (
                            <div>
                                <Reveal
                                    style={{ transitionDelay: 100 }}
                                    disabled={this.isAnimationDisabled}
                                    effect="cardAnimation"
                                >
                                    <Card
                                        profile={this.props.featuredProfile}
                                        model={this.props.model.cards[1]}
                                        translations={this.props.translations.card}
                                        variant={CardVariant.Default}
                                        featured={true}
                                    />
                                </Reveal>
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    }

    renderSmallCards(visibleSmallCards: ICardViewModel[]): ReactNode {
        const variantModifier = this.variant === CardsListVariant.Grid ? "CardsList__grid" : "CardsList__list";
        return (
            visibleSmallCards.length > 0 && (
                <ul className={classNames(variantModifier, "CardList__grid--center")}>
                    {visibleSmallCards.map((card: ICardViewModel, i: number) => {
                        const currentVariant =
                            this.variant === CardsListVariant.List ? CardVariant.ListItem : CardVariant.Default;

                        const isGrid = this.variant === CardsListVariant.Grid;

                        return (
                            <Reveal
                                key={card.url + i}
                                style={{ transitionDelay: isGrid ? i * 100 + 10 : i * 40 + 10 }}
                                disabled={this.isAnimationDisabled}
                                effect="cardAnimation"
                            >
                                <li>
                                    <Card
                                        profile={this.props.defaultProfile}
                                        model={card}
                                        translations={this.props.translations.card}
                                        variant={currentVariant}
                                        hideLink={this.props.hideLink}
                                    />
                                </li>
                            </Reveal>
                        );
                    })}
                </ul>
            )
        );
    }

    private toggleExpand(): void {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    private getCollapsedItemsCount(): number {
        if (this.isFeatured() && this.props.collapsedItemsCount) return this.props.collapsedItemsCount - 2;

        return this.props.collapsedItemsCount || CardsList.CARDS_PER_ROW;
    }

    isFeatured(): boolean {
        return this.props.model.showFeatured && this.variant === CardsListVariant.Grid;
    }

    getSmallCards(): ICardViewModel[] {
        return this.isFeatured()
            ? this.props.model.cards.slice(2, this.props.model.cards.length)
            : this.props.model.cards;
    }

    private canUseDOM = !!(typeof window !== "undefined" && window.document && window.document.createElement);

    private isAnimationDisabled = !(this.canUseDOM
        ? window.matchMedia("(min-width: 1024px)").matches && this.isValidBrowser()
        : false);

    private isValidBrowser() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        return browser.satisfies({
            macos: {
                safari: ">10.1",
            },
            chrome: ">20.1.1432",
            windows: {
                "internet explorer": ">10",
            },
            firefox: ">31",
            opera: ">=22",
            edge: ">1",
        });
    }
}
