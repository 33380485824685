import * as React from "react";
import { CollectionSearchObjectCard } from "./CollectionSearchObjectCard";
import { CollectionObject } from "../../Collection/Models/CollectionObject.csharp";
import { View } from "./CollectionSearch";
import classNames from "classnames";
import { CollectionSearchPageTranslations } from "./CollectionSearchPageTranslations.csharp";

export interface CollectionSearchListProps {
    objects: Array<CollectionObject>;
    isLoading: boolean;
    view: View;
    url: string;
    linkToObjectPage: string;
    translations: CollectionSearchPageTranslations;
    isVirtualObjectList: boolean;
    language: string;
}

export const CollectionSearchList = (props: CollectionSearchListProps) => {
    const classView = props.view == View.Gridview ? "CollectionSearchPage__Gridview" : "CollectionSearchPage__ListView";

    if (!props.isLoading && props.objects.length == 0) {
        return (
            <div className="CollectionSearchPage__controllers">
                <h3 style={{ "fontSize": "20px" }}>{props.translations.noSearchResults}</h3>
            </div>
        );
    }

    return (
        <div
            className={
                props.isVirtualObjectList
                    ? "CollectionSearchPage__List CollectionSearchPage__List__Virtual"
                    : classNames("CollectionSearchPage__List", classView)
            }
        >
            {props.isLoading
                ? null
                : props.objects.map((object, i) => {
                      const title = getStringForLanguageWithFallback(object.title, object.titleEn, props.language);
                      const medium = getStringForLanguageWithFallback(object.medium, object.mediumEn, props.language);
                      return (
                          <CollectionSearchObjectCard
                              invNo={object.invNo.replace("&", "_")}
                              imageUrl={object.name}
                              title={title}
                              medium={medium}
                              yearLabel={object.displayDate}
                              primaryMedia={object.primaryMedia}
                              key={i}
                              view={props.view}
                              url={props.url}
                              linkToObjectPage={props.linkToObjectPage}
                              isVirtualObject={object.isVirtualObject}
                              translations={props.translations}
                              classification={object.classification}
                              classificationEn={object.classificationEn}
                              isVirtualObjectList={props.isVirtualObjectList}
                          />
                      );
                  })}
        </div>
    );

    function getStringForLanguageWithFallback(norwegianString, englishString, language) {
        if (language === "en" && englishString) return englishString;
        else return norwegianString;
    }
};
