import React, { useState, useEffect } from "react";
import { Textfit } from "react-textfit";

interface IMemberName {
    text: string;
    name: string;
}

const MemberName = ({ text, name }: IMemberName) => {
    const textLenght = text ? text.length : 0;
    const nameLenght = name ? name.length : 0;

    const [windowWidth, setWindowWidth] = useState(0);
    const [textMode, setTextMode] = useState("single");
    const [minFontSize, setMinFontSize] = useState(40);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        setWindowWidth(window.innerWidth);

        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const isMobile = windowWidth < 768;
        setTextMode(textLenght + nameLenght > 50 && isMobile ? "multi" : "single");
        setMinFontSize(isMobile ? 40 : 30);
    }, [windowWidth]);

    return (
        <div className="MemberName wrapper">
            <div className="MemberName__inner">
                <Textfit mode={textMode} forceSingleModeWidth={true} min={minFontSize} max={300}>
                    {text}
                    {name}
                </Textfit>
            </div>
        </div>
    );
};

export default MemberName;
