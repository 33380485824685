import React, { useEffect, useRef } from "react";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import moment from "moment";
import "moment/locale/nb";
import { mapCultureFromEpiServer } from "../../../../ReactComponents/DateUtil";

export const TicketCalendar = (props: {
    availableDates: Date[];
    selectedDate: Date;
    setSelectedDate: (date: Date) => void;
    today: Date;
    language: string;
}) => {
    const lastAvailableDate = new Date(props.availableDates[props.availableDates.length - 1]);
    const allDates = getDates(new Date(props.today), lastAvailableDate);
    const formatedDates = props.availableDates.map((date: Date) => moment(date).format("YYYY-MM-DD"));
    const disabledDays = allDates.filter((date: string) => !formatedDates.includes(date)).map((date) => new Date(date));

    const modifiers = {
        past: {
            before: props.today,
        },
    };

    const dayPickerRef = useRef(null);

    useEffect(() => {
        if (dayPickerRef.current) {
          dayPickerRef.current.focus();
        }
      }, []);

    useEffect(() => {
        const collection = Array.from(document.getElementsByClassName("DayPicker-Day--past"));
        collection.forEach((element) => {
            element.setAttribute("disabled", "true");
        });
    }, []);

    return (
        <div className="TicketCalendar">
            <DayPicker
                ref={dayPickerRef}
                selectedDays={props.selectedDate}
                modifiers={modifiers}
                disabledDays={[...disabledDays, { after: lastAvailableDate }]}
                onDayClick={props.setSelectedDate}
                fromMonth={props.today}
                toMonth={lastAvailableDate}
                locale={mapCultureFromEpiServer(props.language)}
                localeUtils={MomentLocaleUtils}
            />
        </div>
    );
};

const getDates = (startDate: Date, stopDate: Date) => {
    let currDate = moment(startDate);
    const allDates = [];
    while (currDate <= moment(stopDate)) {
        allDates.push(currDate.format("YYYY-MM-DD"));
        currDate = moment(currDate).add(1, "days");
    }
    return allDates;
};
