import * as React from "react";
import {ICardViewModel} from "../../../Partials/Card/ICardViewModel.csharp";
import {EventCalendarTranslations} from "./EventCalendarTranslations.csharp";
import {CardsList} from "../../../Blocks/CardsList/CardsList";
import {CardsListVariant} from "../../../Blocks/CardsList/CardsListVariant.csharp";
import {PictureProfile} from "../../../../ResizedImage/PictureProfile.csharp";

export interface EventsListProps {
    events: ICardViewModel[];
    profile: PictureProfile;
    translations: EventCalendarTranslations;
    loadMore: () => void;
    hasNextPage: boolean;
    isLoading: boolean;
    pageSize: number;
    totalCount: number;
}

export const EventsList = (props: EventsListProps) => {
    const remainingEvents = props.totalCount - props.events.length;
    const remainingEventsText = remainingEvents > 0 ? `${remainingEvents} ` : "0";
    return (
        <div className="EventsList">
            {props.events && props.events.length > 0 ? (
                <CardsList
                    className="EventCalendar__list"
                    defaultProfile={props.profile}
                    featuredProfile={props.profile}
                    translations={props.translations.cardsList}
                    variant={CardsListVariant.List}
                    animateChildren={true}
                    model={{
                        title: "",
                        cards: props.events,
                        alwaysExpanded: true,
                        showFeatured: false,
                        hideLink: false
                    }}
                />
            ) : (
                <div className="EventCalendar__noResultsInfo">{props.translations.noResults}</div>
            )}
            {props.hasNextPage && (
                <button
                    className="EventCalendar__button button--secondary"
                    onClick={props.loadMore}
                    disabled={props.isLoading}
                >
                    {props.translations.showMore} ( {remainingEventsText} )
                </button>
            )}
        </div>
    );
};