export const getData = async (endpoint: string,
    accessToken: string, setError: (setError: boolean) => void) => {

    var response = null;
    if (accessToken !== null) {

        var bearer = "Bearer " + accessToken;
        const requestOptions: RequestInit = {
            method: "GET",
            headers: {
                "Authorization": bearer
            }
        };

        const responseRaw = await (fetch(endpoint, requestOptions));
        response = await (responseRaw.json());
        if(responseRaw.status !== 200){
            setError(true)
        }
        
        } else {
        const responseRaw = await (fetch(endpoint));
        response = await (responseRaw.json());
        if(responseRaw.status !== 200){
            setError(true)
        }
        
    
    }
    return response;
};
