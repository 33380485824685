export const graphQlRequestBodyString = `
    items {
      id
      uid
      product {
        sku
        stock_status
        name
        
        small_image {
            disabled
            label
            position
            url
          }

          price_tiers {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            quantity
          }

          price_range {
            maximum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }

              regular_price {
                currency
                value
              }
            }
            minimum_price {
              discount {
                amount_off
                percent_off
              }
              final_price {
                currency
                value
              }

              regular_price {
                currency
                value
              }
            }
            
          }
      }
      ... on ConfigurableCartItem {
        configurable_options {
          configurable_product_option_uid
          option_label
          configurable_product_option_value_uid
          value_label
         
        }
        
        
      }
      quantity
      prices {
     
        row_total_including_tax {
            value
            currency
          }
      }
    
  
}`;