//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CardType {
	Invalid = 0,
	ArticleCard = 1,
	ExhibitionCard = 2,
	EventCard = 3
}
