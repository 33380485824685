import React, { useEffect, useState, KeyboardEvent } from "react";
import { LoginProps } from "./LoginProps.csharp";
import { ErrorMessage } from "../Shared/ErrorMessage";
import cn from "classnames";
import { checkEmailIsValid } from "../Shared/utils/checkEmailIsValid";
import { Loader } from "../Shared/Loader";
export const LoginPage = (props: LoginProps) => {
    const [emailInput, setEmailInput] = useState("");
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [passwordInput, setPasswordInput] = useState("");
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const disabled =
        (!emailIsValid && emailInput !== "") ||
        emailInput === "" ||
        (!passwordIsValid && passwordInput !== "") ||
        passwordInput === "";
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleEmailInputChange = (e: any) => {
        const input = e.target.value;
        setEmailInput(input);
        setServerError(false);
    };

    const handlePasswordInputChange = (e: any) => {
        const input = e.target.value;
        setPasswordInput(input);
        setServerError(false);
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const timeOut = setTimeout(() => setIsLoading(true), 300);
        const body = JSON.stringify({
            username: emailInput,
            password: passwordInput,
        });

        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            body: body,
            redirect: "follow",
        };
        fetch(props.loginEndpoint, requestOptions)
            .then((response) => {
                if (response.ok) return response.json();
                else {
                    setServerError(true);
                    setIsLoading(false);
                    clearTimeout(timeOut);
                }
            })
            .then((result) => {
                const localLoginBody = JSON.stringify({
                    reference: result,
                });
                const locaLoginRequestOptions: RequestInit = {
                    method: "POST",
                    headers: headers,
                    body: localLoginBody,
                    redirect: "follow",
                };
                if (result !== undefined) {
                    fetch(props.loginLocalEndpoint, locaLoginRequestOptions)
                        .then((result) => {
                            if (result.ok) {
                                window.location.href = props.returnUrl;
                            } else {
                                setServerError(true);
                                setIsLoading(false);
                                clearTimeout(timeOut);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            setIsLoading(false);
                            clearTimeout(timeOut);
                            setServerError(true);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                clearTimeout(timeOut);
                setServerError(true);
            });
    };

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") onSubmit(event);
    };

    useEffect(() => {
        setEmailIsValid(checkEmailIsValid(emailInput));
    }, [emailInput]);

    useEffect(() => {
        setPasswordIsValid(passwordInput.length > 3);
    }, [passwordInput]);

    return (
        <div className="LoginPage">
            <div className="LoginPage__container">
                <div className="LoginPage__content">
                    <h1 className="LoginPage__title">{props.title}</h1>
                    <form className="LoginPage__inputs-container" onSubmit={(e) => onSubmit(e)}>
                        <div className="LoginPage__inputs">
                            <div className="LoginPage__input-container">
                                <label className="LoginPage__input-label" htmlFor="email-input">
                                    {props.userNameLabel}
                                </label>
                                <input
                                    className={cn("InputField", {
                                        "InputField--error": showEmailError && !emailIsValid,
                                    })}
                                    type="email"
                                    name="email-input"
                                    value={emailInput}
                                    placeholder={props.userNamePlaceHolderText}
                                    onChange={handleEmailInputChange}
                                    onKeyDown={(e) => !disabled && onKeyDown(e)}
                                    onBlur={() => setShowEmailError(true)}
                                />
                                {showEmailError && !emailIsValid && emailInput !== "" && (
                                    <div className="InputField__validation-error">{props.userNameInvalidText}</div>
                                )}
                                {showEmailError && emailInput === "" && (
                                    <div className="InputField__validation-error">{props.requiredText}</div>
                                )}
                            </div>
                            <div className="LoginPage__input-container">
                                <label className="LoginPage__input-label" htmlFor="password-input">
                                    {props.passwordLabel}
                                </label>
                                <div className="InputPassword-wrapper">
                                    <input
                                        className={cn("InputField", {
                                            "InputField--error": showPasswordError && !passwordIsValid,
                                        })}
                                        type={showPassword ? "text" : "password"}
                                        name="password-input"
                                        value={passwordInput}
                                        placeholder={props.passwordPlaceHolderText}
                                        onChange={handlePasswordInputChange}
                                        onBlur={() => setShowPasswordError(true)}
                                        onKeyDown={(e) => !disabled && onKeyDown(e)}
                                    />
                                    {passwordInput !== "" && (
                                        <button
                                            role="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                togglePasswordVisibility();
                                            }}
                                            className="LoginPage__toggle-button"
                                        >
                                            {showPassword ? props.textLinkInsideHide : props.textLinkInsideShow}
                                        </button>
                                    )}
                                </div>
                                {showPasswordError && !passwordIsValid && passwordInput !== "" && (
                                    <div role="alert" className="InputField__validation-error">
                                        {props.passwordInvalidText}
                                    </div>
                                )}
                                {showPasswordError && passwordInput === "" && (
                                    <div role="alert" className="InputField__validation-error">
                                        {props.requiredText}
                                    </div>
                                )}
                            </div>
                        </div>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <button
                                className="button--primary LoginPage__button"
                                onClick={(e) => onSubmit(e)}
                                disabled={disabled}
                            >
                                {props.loginButtonText}
                            </button>
                        )}
                        {serverError && (
                            <div className="LoginPage__server-error">
                                <ErrorMessage text={props.loginFailedText} />
                            </div>
                        )}
                        <div className="LoginPage__link-container">
                            <a href={props.forgotPasswordLink} className="LoginPage__link">
                                {props.forgotPasswordHintText}
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
