import React from "react";
import classNames from "classnames";

interface IProductCard {
    url: string;
    imageUrl: string;
    imageAlt: string;
    title: string;
    brand: string;
    originalPrice: string;
    vatPrice: string;
    
}

export const ProductCard = ({ product, currencyFormat, className }: { product: IProductCard; currencyFormat: string; className?: string;}) => {
    return (
        <a href={product.url} className={classNames ("ProductCard", className)}>
            <div className="ProductCard__Image">
                <img
                    alt={product.imageAlt ? product.imageAlt : product.title}
                    src={product.imageUrl}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/dist/assets/images/noImage.png";
                    }}
                />
            </div>
            <p className="ProductCard__Brand">{product.brand}</p>
            <p className="ProductCard__Title">{product.title}</p>
            <div className="ProductCard__Price-container">
                {product.vatPrice !== product.originalPrice && (
                    <p className="ProductCard__Price--discount">{currencyFormat.replace("price", product.vatPrice).toString()}</p>
                )}
                <p className="ProductCard__Price">
                    {product.vatPrice !== product.originalPrice ? (
                        <s>{currencyFormat.replace("price", product.originalPrice).toString()}</s>
                    ) : (
                        currencyFormat.replace("price", product.originalPrice).toString()
                    )}
                </p>
            </div>
        </a>
    );
};
