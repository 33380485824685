import React, { useEffect, useRef, useState } from "react";
import { ITicket } from "../TicketPage";
import { ITimeSlot } from "../TicketPage";
import { formatTime } from "../utils/formatTime";
import { totalPrice } from "../utils/totalPrice";
import { postRequest } from "../../Shared/api/post";
import { Loader } from "../../Shared/Loader";
interface ITicketSummary {
    editorialProps: {
        summaryTitle: string;
        summaryDateText: string;
        entryTimeText: string;
        paymentButtonText: string;
        noPaymentButtonText: string;
        endpointCartInit: string;
        endpointCartGet: string;
        cartId: string;
        endpointReservation: string;
        endpointGetPaymentUrl: string;
        errorMessageText: string;
        showTimeslots: boolean;
    };
    ticketData: ITicket[];
    totalAmountText: string;
    currencyText: string;
    selectedDate: Date | null;
    selectedTime: ITimeSlot | null;
    accessToken: string;
    isMember: boolean;
    isGiftMembership: boolean;
    endpointCartMine: string;
    endpointValidation: string;
    validationErrorMessage: string;
    endpointCartMineClear?: string;
}

export const TicketSummary = ({
    editorialProps,
    ticketData,
    totalAmountText,
    currencyText,
    selectedDate,
    selectedTime,
    accessToken,
    isMember,
    isGiftMembership = false,
    endpointCartMine,
    endpointValidation,
    validationErrorMessage,
    endpointCartMineClear,
}: ITicketSummary) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const date = `${selectedDate.getDate()}.${selectedDate.getMonth() + 1}.${selectedDate.getFullYear()}`;
    const ref = useRef<null | HTMLDivElement>(null);
    const submitRef = useRef<null | HTMLButtonElement>(null);
    const timeslotIsNotValid = selectedTime?.remaining_slots < ticketData.reduce((prev, curr) => prev + curr.count, 0);
    const headers = new Headers();

    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    if (accessToken !== null) {
        var bearer = "Bearer " + accessToken;
        headers.append("Authorization", bearer);
    }

    useEffect(() => {
        if (ref && selectedTime !== null)
            setTimeout(() => ref.current.scrollIntoView({ behavior: "smooth", block: "start" }), 300);
        if (ref && selectedTime !== null) setTimeout(() => ref?.current?.classList.add("animateIn"), 750);

        if (submitRef?.current) {
            setTimeout(() => {
                submitRef.current.focus();
            }, 800);
        }
    }, [selectedTime]);

    const cartClear = async (goToCheckout) => {
        const timeOut = setTimeout(() => setIsLoading(true), 0);
        setErrorMessage(editorialProps.errorMessageText);
        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
        };

        if (endpointCartMineClear) {
            try {
                var cartMineClear = await await fetch(endpointCartMineClear, requestOptions);

                if (cartMineClear.ok) {
                    goToCheckout && ticketCheckout(null);
                } else {
                    setIsLoading(false);
                    setShowErrorMessage(true);
                }
            } catch (error) {
                setShowErrorMessage(true);
                setIsLoading(false);
                clearTimeout(timeOut);
                console.log(error);
            }
        } else {
            try {
                var newCartId = await (await fetch(editorialProps.endpointCartInit, requestOptions)).json();
                if (newCartId) {
                    goToCheckout && ticketCheckout(newCartId);
                } else {
                    setIsLoading(false);
                    setShowErrorMessage(true);
                }
            } catch (error) {
                setShowErrorMessage(true);
                setIsLoading(false);
                clearTimeout(timeOut);
                console.log(error);
            }
        }
    };

    const ticketValidation = async (cartId) => {
        const timeOut = setTimeout(() => setIsLoading(true), 300);
        setErrorMessage(validationErrorMessage);

        const requestOptions: RequestInit = {
            method: "POST",
            headers: headers,
            redirect: "follow",
        };

        try {
            const endpoint = cartId ? endpointValidation.replace("#cartId#", cartId) : endpointValidation;
            var validation = await await fetch(endpoint, requestOptions);
            if (validation.ok) {
                return true;
            } else {
                setShowErrorMessage(true);
                setIsLoading(false);
                cartClear(false);
                return false;
            }
        } catch (error) {
            setShowErrorMessage(true);
            setIsLoading(false);
            clearTimeout(timeOut);
            console.log(error);
            cartClear(false);
            return false;
        }
    };

    const ticketCheckout = async (emptyCartId) => {
        const timeOut = setTimeout(() => setIsLoading(true), 300);
        setErrorMessage(editorialProps.errorMessageText);

        const requestOptionsGuest: RequestInit = {
            method: "GET",
            headers: headers,
            redirect: "follow",
        };
        const requestOptionsMember: RequestInit = {
            method: "GET",
            headers: headers,
            redirect: "follow",
        };
        try {
            var activeCartId;
            var alreadyHasItemsInCart = false;

            if (emptyCartId) {
                activeCartId = emptyCartId;
                alreadyHasItemsInCart = false;
            } else if (accessToken) {
                var memberCart = await await fetch(editorialProps.endpointCartInit, requestOptionsMember);
                var memberCartItems = await memberCart.json();

                if (memberCartItems && memberCartItems.items && memberCartItems.items.length > 0) {
                    alreadyHasItemsInCart = true;
                }

                if (memberCart.status !== 200) {
                    await (await postRequest(editorialProps.endpointCartInit, "", accessToken)).json();
                }
            } else if (editorialProps.cartId) {
                activeCartId = editorialProps.cartId;
                if (!accessToken) {
                    var guestCart = await (await fetch(editorialProps.endpointCartGet, requestOptionsGuest)).json();

                    if (guestCart && guestCart.items && guestCart.items.length > 0) {
                        alreadyHasItemsInCart = true;
                    }
                }
            } else {
                activeCartId = await (await postRequest(editorialProps.endpointCartInit, "", accessToken)).json();
                if (!activeCartId) throw new Error("No cart id");
            }
            const tickets = ticketData;
            const reservationEndpoint = editorialProps.endpointReservation.replace("#cartId#", activeCartId);
            const responses = [];
            for (const ticket of tickets) {
                if (ticket.count > 0) {
                    const { id, count, package_product_id } = ticket;
                    const admission_code = selectedTime?.admission_code ?? "";
                    const no = selectedTime?.no ?? "";
                    const data = JSON.stringify({
                        packageDataJson: JSON.stringify({
                            product: package_product_id,
                            ticketing: {
                                [package_product_id]: {
                                    ticket: {
                                        [id]: {
                                            qty: count,
                                            options: {
                                                admissions:
                                                    isGiftMembership || !selectedTime
                                                        ? {}
                                                        : {
                                                            [admission_code]: no,
                                                        },
                                            },
                                        },
                                    },
                                    option: {},
                                },
                            },
                        }),
                    });
                    const response = await postRequest(reservationEndpoint, data, accessToken);
                    responses.push(response);
                }
            }

            if (!responses.find((response) => response.status !== 200)) {
                const isValid = await ticketValidation(activeCartId);
                var paymentUrl =
                    editorialProps.endpointGetPaymentUrl +
                    "&cartId=" +
                    activeCartId +
                    "&alreadyHasItemsInCart=" +
                    alreadyHasItemsInCart;

                const url = await (await fetch(paymentUrl)).json();

                // @ts-ignore
                const dataLayer = window.dataLayer || [];
                dataLayer.push({ ecommerce: null });

                const convertItems = (items) => {
                    return items.map((item) => ({
                        item_id: item.sku,
                        item_name: item.name,
                        price: item.brackets[0].price.toFixed(2),
                        quantity: item.count,
                    }));
                };

                dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        currency: "NOK",
                        value: totalPrice(ticketData),
                        items: convertItems(ticketData),
                    },
                });
                if (isValid) window.location.href = url.url;
            } else {
                throw new Error("Reservation failed");
            }
        } catch (error) {
            setShowErrorMessage(true);
            setIsLoading(false);
            clearTimeout(timeOut);
            console.log(error);
        }
    };

    return (
        <div ref={ref} className="TicketPage__card">
            <h2 className="TicketPage__panelTitle">{editorialProps.summaryTitle}</h2>
            <div className="TicketSummary">
                {!isGiftMembership && (
                    <React.Fragment>
                        <div className="TicketSummary__time">
                            {editorialProps.summaryDateText}
                            <strong>{date}</strong>
                        </div>
                        <div className="TicketSummary__time">
                            {editorialProps.entryTimeText}
                            <strong>
                                {formatTime(selectedTime.start_utc_timestamp)}
                                {editorialProps.showTimeslots && " - " + formatTime(selectedTime.end_utc_timestamp)}
                            </strong>
                        </div>
                    </React.Fragment>
                )}

                <div className="TicketSummary__tickets">
                    {ticketData &&
                        ticketData
                            .filter((ticket) => ticket.count > 0)
                            .map((ticket) => (
                                <div key={ticket.id} className="TicketSummary__ticket">
                                    <span>{ticket.count + " x " + ticket.name}</span>

                                    <span>
                                        {ticket.brackets[0].price === 0
                                            ? "Gratis"
                                            : Math.round(ticket.brackets[0].price_incl_vat) + " " + currencyText}
                                    </span>
                                </div>
                            ))}
                </div>
                <div className="TicketSummary__total">
                    <span>{totalAmountText}</span> <span>{totalPrice(ticketData)} kr</span>
                </div>
            </div>

            <div className="next-button__container">
                <div role="alert">{showErrorMessage && <p className="ErrorMessage">{errorMessage}</p>}</div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <button
                        ref={submitRef}
                        className="button--primary button--forward button--red"
                        onClick={() => cartClear(true)}
                        disabled={timeslotIsNotValid}
                    >

                        <span>
                                {totalPrice(ticketData) === 0
                                ? editorialProps.noPaymentButtonText
                                : editorialProps.paymentButtonText}
                        </span>
                    </button>
                )}
            </div>
        </div>
    );
};
