import * as React from "react";
import classNames from "classnames";
import {ResponsivePicture} from "../../ReactComponents/responsivePicture/responsivePicture";
import {CardProps} from "../Card/CardProps.csharp";
import {ArticleCardViewModel} from "./ArticleCardViewModel.csharp";

export const ArticleCard = (props: CardProps) => {
    const {title, intro, picture, url} = (props.model as ArticleCardViewModel);
    return <div className={classNames("Card ArticleCard")}>
        <a href={props.hideLink ? undefined : url} title={title}>
            {picture && <div className="CardPicture">
                <ResponsivePicture profile={props.profile} model={picture} />
            </div>}
            
            <h2 className={classNames("ArticleCard__title", {"ArticleCard__title--featured" : !!props.featured})}>{title}</h2>
            <div className="ArticleCard__intro">{intro}</div>
        </a>
    </div>
};
