import React, { useState, useEffect } from "react";
import cn from "classnames";

interface ISplitBlock {
    body: string;
    buttonColor: string;
    buttonText: string;
    buttonUrl: string;
    copyright: string;
    imagePosition: string;
    imageUrl: string;
    message: string;
    textAlignment: string;
    title: string;
}

const SplitBlock = ({
    body,
    buttonColor,
    buttonText,
    buttonUrl,
    copyright,
    imagePosition,
    imageUrl,
    message,
    textAlignment,
    title,
}: ISplitBlock) => {
    return (
        <div
            className={cn("Split wrapper", imagePosition, {
                "Split--noImage": !imageUrl,
            })}
        >
            {title && <h2 className="Split__title">{title}</h2>}
            <div className="Split__body">
                <div
                    className={cn("Split__text  scrollObserver", {
                        [`Split__text--${textAlignment}`]: textAlignment,
                    })}
                >
                    {body && <div dangerouslySetInnerHTML={{ __html: body }} />}
                    {buttonUrl && (
                        <a
                            className={cn("button--primary button--forward noindent", {
                                [`button--${buttonColor}`]: buttonColor,
                            })}
                            href={buttonUrl}
                        >
                            <span>{buttonText}</span>
                        </a>
                    )}
                </div>

                <div className="Split__image cover scrollObserver">
                    {imageUrl ? (
                        <>
                            <img className="Split__img" src={imageUrl} />
                            {copyright && <div className="FigureCaption__copyright">{copyright}</div>}
                        </>
                    ) : (
                        <>{message && <div className="Split__altMessage">{message}</div>}</>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SplitBlock;
