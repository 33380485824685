import React, { useState, useEffect } from "react";
import { CardsList } from "../CardsList/CardsList";
import { PictureProfile } from '../../../ResizedImage/PictureProfile.csharp';
import { CardsListTranslations } from '../CardsList/CardsListTranslations.csharp';
import { ICardViewModel } from '../../Partials/Card/ICardViewModel.csharp';

interface ICardsListBlock {
    defaultProfile:PictureProfile;
    featuredProfile: PictureProfile;
    translations: CardsListTranslations;
    alwaysExpanded: boolean;
    content: ICardViewModel[];
    linkText: string;
    linkUrl: string;
    showFeatured: boolean;
    title: string;
    hideLink: boolean;
}

const CardsListBlock = ({ defaultProfile, featuredProfile, translations, alwaysExpanded, content, linkText, linkUrl, showFeatured, title, hideLink }: ICardsListBlock) => {
    return (
        <div className="CardsListBlock wrapper">
            <CardsList
                defaultProfile={defaultProfile}
                featuredProfile={featuredProfile}
                translations={translations}
                model={{
                    title: title,
                    cards: content,
                    alwaysExpanded: alwaysExpanded,
                    showFeatured: showFeatured,
                    hideLink: hideLink
                }}
            />
        </div>
    );
};

export default CardsListBlock;
