import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/nb";
import TwoColumnsBlock from "../../Shared/TwoColumnsBlock/TwoColumnsBlock";

interface IMembershipRenewal {
    titleBeforeExpiryDate?: string;
    introBeforeExpiryDate?: string;
    titleAfterExpiryDate?: string;
    introAfterExpiryDate?: string;
    linkItem?: {
        text?: string;
        url?: string;
    };
    daysLimitForShowingRenewalOfMembership: number;
    expirationDate: Date;
}

const MembershipRenewal = ({
    titleBeforeExpiryDate,
    introBeforeExpiryDate,
    titleAfterExpiryDate,
    introAfterExpiryDate,
    linkItem,
    daysLimitForShowingRenewalOfMembership,
    expirationDate,
}: IMembershipRenewal) => {
    const [isSHown, setIsShown] = useState(false);
    const [hasExpired, setHasExpired] = useState(false);

    const calculateDaysUntil = (date) => {
        const futureDate = moment(date, "YYYY-MM-DD");
        const today = moment();
        const diff = futureDate.diff(today, "days");
        return diff;
    };

    useEffect(() => {
        const daysLeft = calculateDaysUntil(expirationDate);
        setIsShown(daysLeft < daysLimitForShowingRenewalOfMembership);
        setHasExpired(daysLeft < 0);
    }, [expirationDate]);

    return isSHown ? (
        <TwoColumnsBlock title={hasExpired ? titleAfterExpiryDate : titleBeforeExpiryDate} intro={hasExpired ? introAfterExpiryDate : introBeforeExpiryDate} link={linkItem} inOneCoulmn={true} />
    ) : null;
};

export default MembershipRenewal;
