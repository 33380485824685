import { ITicket, ITimeSlot } from "../TicketPage";
import { postRequest } from "../../Shared/api/post";

export const getAvailableTimeSlots = async (
    endpoint: string,
    date: Date,
    ticketData: ITicket[],
    isExpress: boolean,
    redirectUrl: string,
    accessToken: string,
    setError: (setError: boolean) => void,
    setNoTimeslotsError: (setNoTimeslotsError: boolean) => void,
) => {
    setError(false);
    setNoTimeslotsError(false);
    const admissionCodes = [...new Set(ticketData.map((ticket) => ticket.admission_codes[0]))];
    const timeslots: ITimeSlot[][] = await Promise.all(
        admissionCodes.map(async (code: string) => {
            const ticketsPerCode = ticketData.reduce((prev: number, current: ITicket) => {
                if (current.admission_codes[0] === code) return prev + current.count;
            }, 0);

            function formatDateToYYYYMMDD(date) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
              }

            const body = JSON.stringify({
                date: formatDateToYYYYMMDD(date),
                qtyMap: {
                    [code]: ticketsPerCode,
                },
                soldOut: true,
            });
            const rawResult = await postRequest(endpoint, body, accessToken);
            const result = rawResult.json();

            // if response is 400 error on express page, redirect user to regular ticketpage
            if (rawResult.status === 400 && isExpress) {
                window.location.href = redirectUrl;
            } else if (rawResult.status !== 200) {
                if(rawResult.status === 400){
                    setNoTimeslotsError(true);
                }else{
                    setError(true);
                }
            }
            return result;
        })
    );
    
    if(timeslots && timeslots[0] && !timeslots[0][0] ){
        return [];
    }

    if (timeslots.length === 1) return timeslots[0];
    else {
        // finne en håndtering hvor det er duplikater av timeslots med ulike admission codes
        const flattenArray: ITimeSlot[] = timeslots.reduce((acc, val) => acc.concat(val), []);
        return flattenArray;
    }
};

export const getAvailableDates = async (
    ticketData: ITicket[],
    endpoint: string,
    accessToken: string,
    setError: (setError: boolean) => void
) => {
    const admissionCodes = [...new Set(ticketData.map((ticket) => ticket.admission_codes[0]))];

    const dates: string[] = await Promise.all(
        admissionCodes.map(async (code: string) => {
            const ticketsPerCode = ticketData.reduce((prev: number, current: ITicket) => {
                if (current.admission_codes[0] === code) return prev + current.count;
            }, 0);
            const raw = JSON.stringify({
                qtyMap: {
                    [code]: ticketsPerCode,
                },
            });
            const response = await postRequest(endpoint, raw, accessToken);
            if (response.status !== 200) {
                setError(true);
            }
            return response.json();
        })
    );

    const flattenArray = dates.reduce((acc: string[], val: string) => acc.concat(val), []);

    return flattenArray;
};
