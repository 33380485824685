import * as React from "react";
import DayPicker from "react-day-picker";
import {mapCultureFromEpiServer} from "../DateUtil";
import {CalendarLocalization} from "./CalendarLocalization.csharp";

export interface CalendarProps {
    selectedDate: Date;
    onDateChange: (value: Date) => void;
    onBlur: () => void;
    localization: CalendarLocalization;
}

export interface CalendarState {
    selectedDate: Date;
}

export default class Calendar extends React.Component<CalendarProps, CalendarState> {

    constructor(props: CalendarProps) {
        super(props);

        this.onDayClick = this.onDayClick.bind(this);
        this.state = {
            selectedDate: this.props.selectedDate,
        };
    }
    
    componentDidMount(): void {
        const wrapper = document.querySelector("div.DayPicker-wrapper") as HTMLElement;
        
        if(wrapper) {
            wrapper.focus();
        }
    }

    render() {
        return (
            <div className="DayPickerInput-OverlayWrapper">
                <div className="DayPickerInput-Overlay">
                    <DayPicker
                        selectedDays={this.props.selectedDate}
                        onDayClick={this.onDayClick}
                        locale={mapCultureFromEpiServer(this.props.localization.cultureName)}
                        months={this.props.localization.monthNames}
                        weekdaysLong={this.props.localization.weekDayLongNames}
                        weekdaysShort={this.props.localization.weekDayShortNames}
                        firstDayOfWeek={1}
                    />
                </div>
            </div>
        );
    }

    private readonly onDayClick = (date: Date) => {
        this.props.onDateChange(date);
    };
}