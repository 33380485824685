import * as React from "react";
import { CollectionSearchBar } from "./CollectionSearchBar";
import { CollectionSearchList } from "./CollectionSearchList";
import { CollectionObject } from "../../Collection/Models/CollectionObject.csharp";
import { CollectionSearchProps } from "./CollectionSearchProps.csharp";
import Dropdown, { Option } from "react-dropdown";
import { MotifFilters } from "./MotifFilters.csharp";

export enum View {
    Gridview,
    Listview,
}

export interface CollectionSearchState {
    objects: Array<CollectionObject>;
    virtualObjects: Array<CollectionObject>;
    isLoading: boolean;
    currentPageNumber: number;
    hasNextPage: boolean;
    pagesCount: number;
    totalCount: number;
    pageSize: number;
    query: string;
    view: View;
    currentSortOrder: string;
    showFilter: boolean;
    filterSelectedClassification: string;
    filterSelectedYearRange: [number, number];
    filterSelectedMotifs: Array<string>;
    filterAllMotifs: MotifFilters;
    url: string;
    availableFilterData: Array<any>;
    linkToAdvancedSearch: string;
    linkToObjectPage: string;
    filtersFromAdvancedSearch: Array<any>;
    isMainCollectionSearch: boolean;
    isDefaultEditorControlledSearch: boolean;
    language: string;
}

var params = {
    query: "",
    sortOrder: "default",
    classification: "",
    pageNumber: 1,
    filterYearRange: [1863, 1944],
    motifs: Array<string>(),
    filtersFromAdvancedQuerystring: Array<any>(),
};

export class CollectionSearch extends React.Component<CollectionSearchProps, CollectionSearchState> {
    constructor(props: CollectionSearchProps) {
        super(props);

        const { collectionSearchResults, language } = props;

        var params2 = this.splitQueryStringIntoStateValues(collectionSearchResults.query);

        // If true (url has parameter 'd'), ignore current filters if user does a new search
        const isDefaultEditorControlledSearch = collectionSearchResults.query.indexOf("&d=") >= 0;

        var virtualObjects = collectionSearchResults.results.items.filter((object) => {
            return object.isVirtualObject == true;
        });

        this.state = {
            objects: collectionSearchResults.results.items, //objects.length > 0 ? objects : Array<CollectionObject>(),
            virtualObjects: virtualObjects.length > 0 ? virtualObjects : Array<CollectionObject>(),
            isLoading: false,
            hasNextPage: collectionSearchResults.results.hasNext,
            currentPageNumber: collectionSearchResults.results.pageNumber,
            pagesCount: collectionSearchResults.results.pagesCount,
            totalCount: collectionSearchResults.results.totalCount,
            pageSize: collectionSearchResults.results.pageSize,
            view: View.Gridview,
            query: params2.query,
            currentSortOrder: params2.sortOrder,
            showFilter: false,
            filterSelectedClassification: params2.classification,
            filterSelectedYearRange: [params2.filterYearRange[0], params2.filterYearRange[1]],
            filterSelectedMotifs: params2.motifs.length > 0 ? params2.motifs : Array<string>(),
            filtersFromAdvancedSearch:
                params2.filtersFromAdvancedQuerystring.length > 0
                    ? params2.filtersFromAdvancedQuerystring
                    : Array<any>(),
            url: "",
            availableFilterData: Array<any>(),
            filterAllMotifs: null,
            linkToAdvancedSearch: collectionSearchResults.linkToAdvancedSearch,
            linkToObjectPage: collectionSearchResults.linkToObjectPage,
            isMainCollectionSearch: this.props.isMainCollectionSearch,
            isDefaultEditorControlledSearch: isDefaultEditorControlledSearch,
            language: language,
        };

        //bind handlers
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.loadPreviousPage = this.loadPreviousPage.bind(this);
        this.loadNextPage = this.loadNextPage.bind(this);
        this.loadLastPage = this.loadLastPage.bind(this);
        this.loadFirstPage = this.loadFirstPage.bind(this);
        this.handleChangeView = this.handleChangeView.bind(this);
        this.onSortingOptionChange = this.onSortingOptionChange.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.handleClassificationChange = this.handleClassificationChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleSelectedMotifsChange = this.handleSelectedMotifsChange.bind(this);
        this.handleDownloadExcelReportClick = this.handleDownloadExcelReportClick.bind(this);
        this.handlefiltersFromAdvancedSearchChange = this.handlefiltersFromAdvancedSearchChange.bind(this);
    }

    componentDidMount() {
        var url = window.location.href.split("?")[0];
        this.setState({ url: url });
        this.getFilterData(url);
    }

    splitQueryStringIntoStateValues(queryString: string) {
        var queryOptions = queryString.split("&");
        for (var i = 0; i < queryOptions.length; i++) {
            var pair = queryOptions[i].split("=");

            if (pair.length == 0) {
                continue;
            }

            var key = decodeURIComponent(pair[0]);
            var value = decodeURIComponent(pair[1]);

            if (value == null) {
                continue;
            }

            switch (key) {
                case "query":
                    if (value.includes("+")) {
                        value = value.replace(new RegExp("\\+", "g"), " ");
                    }
                    params.query = value;
                    break;
                case "sortOrder":
                    params.sortOrder = value;
                    break;
                case "classification":
                    params.classification = value;
                    break;
                case "page":
                    params.pageNumber = +value;
                    break;
                case "years":
                    var years = value.split("|");
                    if (years != null && years.length > 0) {
                        params.filterYearRange = [+years[0], +years[1]];
                    }
                    break;
                case "motif":
                    if (value.includes("+")) {
                        value = value.replace(new RegExp("\\+", "g"), " ");
                    }
                    params.motifs.push(value);
                    break;
                case "creditline":
                case "bibliography":
                case "inscribed":
                case "invno":
                case "medium":
                case "catrais":
                case "portrayednationality":
                case "people":
                    params.filtersFromAdvancedQuerystring.push({ key: key, value: value });
                    break;
                default:
                    break;
            }
        }
        return params;
    }

    render() {
        const {
            objects,
            virtualObjects,
            isLoading,
            view,
            query,
            showFilter,
            filterSelectedClassification,
            url,
            filterSelectedYearRange,
            filterAllMotifs,
            filterSelectedMotifs,
            filtersFromAdvancedSearch,
            linkToAdvancedSearch,
            linkToObjectPage,
            currentPageNumber,
            isDefaultEditorControlledSearch,
            language,
        } = this.state;

        const { translations } = this.props;

        const sortingOptions = [
            { value: "default", label: translations.sortingRelevant },
            { value: "invNo", label: translations.sortingInventoryNumber },
            { value: "descDate", label: translations.sortingDescending },
            { value: "ascDate", label: translations.sortingAscending },
        ];

        return (
            <div className="CollectionSearchPage">
                <div className="CollectionSearchPage__container">
                    <CollectionSearchBar
                        isLoading={isLoading}
                        query={query}
                        onChange={this.handleQueryChange}
                        onClick={this.handleSearchClick}
                        filterAllMotifs={filterAllMotifs}
                        filterClick={this.handleFilterClick}
                        showFilter={showFilter}
                        classificationClick={this.handleClassificationChange}
                        filterCurrentClassification={filterSelectedClassification}
                        handleYearChange={this.handleYearChange}
                        handleSelectedMotifsChange={this.handleSelectedMotifsChange}
                        filterSelectedMotifs={filterSelectedMotifs}
                        filterYearRange={filterSelectedYearRange}
                        filtersFromAdvancedSearch={filtersFromAdvancedSearch}
                        handlefiltersFromAdvancedSearchChange={this.handlefiltersFromAdvancedSearchChange}
                        translations={translations}
                        linkToAdvancedSearch={linkToAdvancedSearch}
                        heading={this.props.heading}
                        isDefaultEditorControlledSearch={isDefaultEditorControlledSearch}
                    />
                    <div className="CollectionSearchPage__content">
                        {/* {virtualObjects.length == 0 || currentPageNumber > 1 ? null :
                        <>
                            <h3 className="CollectionSearchPage__list__header">Samleside for grafikkmotiv / Skissebok</h3>
                            <CollectionSearchList
                                objects={virtualObjects}
                                isLoading={isLoading}
                                view={view}
                                url={url}
                                linkToObjectPage={linkToObjectPage}
                                translations={translations}
                                isVirtualObjectList={true}
                            />
                        </>
                    } */}
                        <h3 className="CollectionSearchPage__list__header">{translations.objects}</h3>
                        {this.topControllers(sortingOptions)}
                        <CollectionSearchList
                            objects={objects}
                            isLoading={isLoading}
                            view={view}
                            url={url}
                            linkToObjectPage={linkToObjectPage}
                            translations={translations}
                            isVirtualObjectList={false}
                            language={language}
                        />
                        <div className="CollectionSearchPage__controllers CollectionSearchPage__bottom__controller">
                            {this.pagingControllers()}
                        </div>
                        {this.state.totalCount > 0 ? (
                            <div>
                                <div className="CollectionSearchPage_bottom_export">
                                    <div className="collectionitempage_bottom_export_buttons">
                                        <button
                                            className="button--secondary"
                                            onClick={this.handleDownloadExcelReportClick}
                                        >
                                            {translations.excelExport}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    topControllers(sortingOptions) {
        const { translations } = this.props;
        const { isLoading, objects, currentSortOrder, view } = this.state;
        if (!isLoading && objects.length == 0) {
            return <></>;
        }
        return (
            <div className="CollectionSearchPage__controllers">
                {this.pagingControllers()}
                <Dropdown options={sortingOptions} onChange={this.onSortingOptionChange} value={currentSortOrder} />
                <div className="CollectionSearchPage__sorting_view">
                    <button
                        title={translations.gridView}
                        className={
                            view == View.Gridview
                                ? "CollectionSearchPage__button_view__grid button_view__selected"
                                : "CollectionSearchPage__button_view__grid "
                        }
                        onClick={() => {
                            this.handleChangeView(View.Gridview);
                        }}
                    ></button>
                    <button
                        title={translations.listView}
                        className={
                            view == View.Listview
                                ? "CollectionSearchPage__button_view__list button_view__selected"
                                : "CollectionSearchPage__button_view__list "
                        }
                        onClick={() => {
                            this.handleChangeView(View.Listview);
                        }}
                    ></button>
                </div>
            </div>
        );
    }

    pagingControllers() {
        const { translations } = this.props;
        const { currentPageNumber, pagesCount, isLoading, objects } = this.state;

        if (!isLoading && objects.length == 0) {
            return <></>;
        }

        return (
            <div className="CollectionSearchPage__paging_controls" style={{ display: "flex" }}>
                <button
                    title={translations.pagingFirst}
                    className="CollectionSearchPage__paging__first"
                    disabled={currentPageNumber <= 1}
                    onClick={this.loadFirstPage}
                ></button>
                <button
                    title={translations.pagingPrevious}
                    className="CollectionSearchPage__paging__previous"
                    disabled={currentPageNumber <= 1}
                    onClick={this.loadPreviousPage}
                ></button>
                <p>
                    {currentPageNumber} / {pagesCount}
                </p>
                <button
                    title={translations.pagingNext}
                    className="CollectionSearchPage__paging__next"
                    disabled={currentPageNumber >= pagesCount}
                    onClick={this.loadNextPage}
                ></button>
                <button
                    title={translations.pagingLast}
                    className="CollectionSearchPage__paging__last"
                    disabled={currentPageNumber >= pagesCount}
                    onClick={this.loadLastPage}
                ></button>
            </div>
        );
    }

    //Handlers
    private handleSelectedMotifsChange(event: React.FormEvent<HTMLInputElement>): void {
        let selectedMotifs = [...this.state.filterSelectedMotifs];

        let { filterSelectedClassification, filterSelectedYearRange } = this.state;

        if (this.state.isDefaultEditorControlledSearch) {
            filterSelectedClassification = "";
            filterSelectedYearRange = [1863, 1944];
            selectedMotifs = [];

            this.setState({
                filterSelectedClassification: filterSelectedClassification,
                filterSelectedYearRange: filterSelectedYearRange,
                isDefaultEditorControlledSearch: false,
                query: "",
            });
        }

        event.preventDefault();
        event.stopPropagation();

        const motif = event.currentTarget.id;

        const index = selectedMotifs.indexOf(motif);
        if (index !== -1) {
            selectedMotifs.splice(index, 1);
            this.setState({ filterSelectedMotifs: selectedMotifs });
        } else {
            this.setState({
                filterSelectedMotifs: [...selectedMotifs, motif],
            });
            selectedMotifs.push(motif);
        }

        // Check to see if parent is selected - if so then remove from list.
        const datasetParents = event.currentTarget.dataset.parents;
        const motifParents = datasetParents ? datasetParents.split(",") : [];
        motifParents.forEach((parent) => {
            const parentIndex = selectedMotifs.indexOf(parent);
            if (parentIndex !== -1) {
                selectedMotifs.splice(parentIndex, 1);
                this.setState({ filterSelectedMotifs: selectedMotifs });
            }
        });

        this.loadResults(
            this.state.query,
            1,
            this.state.currentSortOrder,
            filterSelectedClassification,
            filterSelectedYearRange,
            selectedMotifs,
            this.state.filtersFromAdvancedSearch
        );
    }

    private handlefiltersFromAdvancedSearchChange(event): void {
        var key = event.currentTarget.id;
        var selectedAdvancedSearch = [...this.state.filtersFromAdvancedSearch];

        var index = selectedAdvancedSearch.findIndex((filter) => filter.key == key);
        if (index !== -1) {
            selectedAdvancedSearch.splice(index, 1);
            this.setState({ filtersFromAdvancedSearch: selectedAdvancedSearch });
        }

        this.loadResults(
            this.state.query,
            1,
            this.state.currentSortOrder,
            this.state.filterSelectedClassification,
            this.state.filterSelectedYearRange,
            this.state.filterSelectedMotifs,
            selectedAdvancedSearch
        );
    }

    private handleYearChange(yearRange: [number, number]): void {
        if (this.state.isDefaultEditorControlledSearch) {
            this.setState({
                filterSelectedClassification: "",
                filterSelectedYearRange: yearRange,
                filterSelectedMotifs: [],
                isDefaultEditorControlledSearch: false,
                query: "",
            });

            this.loadResults(
                "",
                1,
                this.state.currentSortOrder,
                "",
                yearRange,
                [],
                this.state.filtersFromAdvancedSearch
            );
        } else {
            this.setState({ filterSelectedYearRange: yearRange });
            this.loadResults(
                this.state.query,
                1,
                this.state.currentSortOrder,
                this.state.filterSelectedClassification,
                yearRange,
                this.state.filterSelectedMotifs,
                this.state.filtersFromAdvancedSearch
            );
        }
    }

    private handleClassificationChange(event): void {
        if (this.state.isDefaultEditorControlledSearch) {
            this.setState({
                filterSelectedClassification: event.target.id,
                filterSelectedMotifs: [],
                filterSelectedYearRange: [1863, 1944],
                isDefaultEditorControlledSearch: false,
                query: "",
            });

            this.loadResults(
                "",
                1,
                this.state.currentSortOrder,
                event.target.id,
                [1863, 1944],
                [],
                this.state.filtersFromAdvancedSearch
            );
        } else {
            this.setState({ filterSelectedClassification: event.target.id });

            this.loadResults(
                this.state.query,
                1,
                this.state.currentSortOrder,
                event.target.id,
                this.state.filterSelectedYearRange,
                this.state.filterSelectedMotifs,
                this.state.filtersFromAdvancedSearch
            );
        }
    }

    private handleFilterClick() {
        this.state.showFilter ? this.setState({ showFilter: false }) : this.setState({ showFilter: true });
    }

    private handleSearchClick() {
        if (this.state.isDefaultEditorControlledSearch) {
            this.setState({
                filterSelectedClassification: "",
                filterSelectedMotifs: [],
                filterSelectedYearRange: [1863, 1944],
                isDefaultEditorControlledSearch: false,
            });

            this.loadResults(
                this.state.query,
                1,
                this.state.currentSortOrder,
                "",
                [1863, 1944],
                [],
                this.state.filtersFromAdvancedSearch
            );
        } else {
            this.loadResults(
                this.state.query,
                1,
                this.state.currentSortOrder,
                this.state.filterSelectedClassification,
                this.state.filterSelectedYearRange,
                this.state.filterSelectedMotifs,
                this.state.filtersFromAdvancedSearch
            );
        }
    }

    private handleDownloadExcelReportClick() {
        this.downloadExcelReport(
            this.state.query,
            this.state.currentPageNumber,
            this.state.currentSortOrder,
            this.state.filterSelectedClassification,
            this.state.filterSelectedYearRange,
            this.state.filterSelectedMotifs,
            this.state.filtersFromAdvancedSearch
        );
    }

    private handleQueryChange(event): void {
        this.setState({
            query: event.target.value,
        });
    }

    private loadPreviousPage() {
        this.loadResults(
            this.state.query,
            this.state.currentPageNumber - 1,
            this.state.currentSortOrder,
            this.state.filterSelectedClassification,
            this.state.filterSelectedYearRange,
            this.state.filterSelectedMotifs,
            this.state.filtersFromAdvancedSearch
        );
        window.scrollTo(0, 0);
    }

    private loadNextPage() {
        this.loadResults(
            this.state.query,
            this.state.currentPageNumber + 1,
            this.state.currentSortOrder,
            this.state.filterSelectedClassification,
            this.state.filterSelectedYearRange,
            this.state.filterSelectedMotifs,
            this.state.filtersFromAdvancedSearch
        );
        window.scrollTo(0, 0);
    }

    private loadLastPage() {
        this.loadResults(
            this.state.query,
            this.state.pagesCount,
            this.state.currentSortOrder,
            this.state.filterSelectedClassification,
            this.state.filterSelectedYearRange,
            this.state.filterSelectedMotifs,
            this.state.filtersFromAdvancedSearch
        );
        window.scrollTo(0, 0);
    }

    private loadFirstPage() {
        this.loadResults(
            this.state.query,
            1,
            this.state.currentSortOrder,
            this.state.filterSelectedClassification,
            this.state.filterSelectedYearRange,
            this.state.filterSelectedMotifs,
            this.state.filtersFromAdvancedSearch
        );
        window.scrollTo(0, 0);
    }

    private handleChangeView(view: View) {
        this.setState({
            view: view,
        });
    }

    private onSortingOptionChange(selectedOption: Option) {
        this.setState({
            currentSortOrder: selectedOption.value,
            currentPageNumber: 1,
        });
        this.loadResults(
            this.state.query,
            1,
            selectedOption.value,
            this.state.filterSelectedClassification,
            this.state.filterSelectedYearRange,
            this.state.filterSelectedMotifs,
            this.state.filtersFromAdvancedSearch
        );
    }

    private loadResults(
        query: string,
        pageNumber: number,
        currentSortingOption: string,
        filterCurrentClassification: string,
        filterYearRange: [number, number],
        filterMotifs: Array<string>,
        selectedAdvancedSearch: Array<any>
    ) {
        this.setState({
            isLoading: true,
        });

        var queryString = this.buildQueryString(
            query,
            pageNumber,
            currentSortingOption,
            filterCurrentClassification,
            filterYearRange,
            filterMotifs,
            selectedAdvancedSearch
        );

        //Update URL with querystring options
        var pageUrl = `${this.state.url}${queryString}`;
        history.pushState("", "", pageUrl);

        const apiUrl = `${this.state.url}/Search${queryString}`;

        fetch(apiUrl, { credentials: "same-origin" })
            .then(
                (response) => response.json(),
                (error) => {
                    this.setState({ isLoading: false });
                }
            )
            .then((response) =>
                this.setState({
                    objects: response.results.items,
                    /*objects: response.results.items.filter(object => {
                        return object.isVirtualObject == false;
                    }),*/
                    virtualObjects: response.results.items.filter((object) => {
                        return object.isVirtualObject == true;
                    }),
                    currentPageNumber: response.results.pageNumber,
                    hasNextPage: response.results.hasNext,
                    pageSize: response.results.pageSize,
                    pagesCount: response.results.pagesCount,
                    query: query,
                    isLoading: false,
                    totalCount: response.results.totalCount,
                })
            );
    }

    private downloadExcelReport(
        query: string,
        pageNumber: number,
        currentSortingOption: string,
        filterCurrentClassification: string,
        filterYearRange: [number, number],
        filterMotifs: Array<string>,
        selectedAdvancedSearch: Array<any>
    ) {
        const queryString = this.buildQueryString(
            query,
            pageNumber,
            currentSortingOption,
            filterCurrentClassification,
            filterYearRange,
            filterMotifs,
            selectedAdvancedSearch
        );
        let apiUrl = `${this.state.url}/ExportToExcel${queryString}`;
        if (this.state.isMainCollectionSearch) {
            apiUrl += "&munchSamlingen=true";
        }
        const a = document.createElement("a");
        a.href = apiUrl;
        a.click();
    }

    private buildQueryString(
        query: string,
        pageNumber: number,
        currentSortingOption: string,
        filterCurrentClassification: string,
        filterYearRange: [number, number],
        filterMotifs: Array<string>,
        selectedAdvancedSearch: Array<any>
    ) {
        var queryStringUrl = `&query=${query}`;
        if (pageNumber) {
            queryStringUrl += `&page=${pageNumber.toString()}`;
        }
        if (currentSortingOption != "") {
            queryStringUrl += `&sortOrder=${currentSortingOption}`;
        }
        if (filterCurrentClassification != "") {
            queryStringUrl += `&classification=${filterCurrentClassification}`;
        }
        if (filterYearRange.length > 0) {
            queryStringUrl += `&years=${filterYearRange[0]}|${filterYearRange[1]}`;
        }
        if (filterMotifs.length > 0) {
            filterMotifs.map((motif, i) => {
                queryStringUrl += `&motif=${motif}`;
            });
        }

        if (queryStringUrl.charAt(0) == "&") {
            queryStringUrl = queryStringUrl.replace("&", "?");
        }

        if (selectedAdvancedSearch.length > 0) {
            selectedAdvancedSearch.forEach(function (filter) {
                queryStringUrl += `&${filter.key}=${filter.value}`;
            });
        }

        if (this.state.isMainCollectionSearch) {
            queryStringUrl += "&munchSamlingen=true";
        }

        return queryStringUrl;
    }

    private getFilterData(url: string) {
        const apiUrl = `${url}/GetFilterData?query=`;
        fetch(apiUrl, { credentials: "same-origin" })
            .then(
                (response) => response.json(),
                (error) => {
                    this.setState({ isLoading: false });
                }
            )
            .then((response) => {
                this.setState({
                    availableFilterData: response.facets,
                    filterAllMotifs: response.motifFilters,
                });
            });
    }
}
