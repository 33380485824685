import React, { useState, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import ReactModal from "react-modal";

interface IModal {
    isOpen: boolean;
    contentLabel?: string;
    closeButtonLabel?: string;
    children?: React.ReactNode;
    onClose?: (val: any) => any;
}
const Modal = ({ isOpen, closeButtonLabel, contentLabel, children, onClose = () => {} }: IModal) => {
    const closeModal = () => {
        onClose(false);
    };

    useEffect(() => {
        ReactModal.setAppElement('body');
    }, []);
    return (
        <ReactModal
            isOpen={isOpen}
            contentLabel={contentLabel}
            className="Modal"
            overlayClassName="Modal__overlay"
            onRequestClose={closeModal}
        >
            <button className="Modal__close-button" onClick={closeModal} aria-expanded={isOpen} aria-label={closeButtonLabel}></button>
            {children}
        </ReactModal>
    );
};

export default Modal;
