import {ExhibitionsOverviewProps} from "./ExhibitionsOverviewProps.csharp";
import * as React from "react";
import {CardsList} from "../../../Blocks/CardsList/CardsList";
import {CardsListVariant} from "../../../Blocks/CardsList/CardsListVariant.csharp";

export class ExhibitionsOverview extends React.Component<ExhibitionsOverviewProps> {
    static FEATURED_CARDS_COUNT = 2;
    currentExhibitionLength = this.props.model.currentExhibitions.length;
    
    constructor(props: ExhibitionsOverviewProps){
        super(props);

    }
    
    render() {
        const cardsListTranslations = {
            showMore: this.props.translations.showAllUpcoming, 
            showLess:this.props.translations.showLess,
            card:this.props.translations.card
        };
        
        const {translations, featuredProfile, defaultProfile, model}  = this.props;
        
        
        const exhibitionCards = <CardsList defaultProfile={defaultProfile}
                                           featuredProfile={featuredProfile}
                                           translations={cardsListTranslations}
                                           collapsedItemsCount={model.shownExhibitionsCount}
                                           className=''
                                           animateChildren={true}
                                           model={{
                                                alwaysExpanded: false,
                                                cards: model.currentExhibitions,
                                                title: '',
                                               showFeatured: true,
                                               hideLink: false
                                           }}
        />;
        
        const pastExhibitionCards = <CardsList defaultProfile={defaultProfile}
                                               featuredProfile={featuredProfile}
                                               translations={cardsListTranslations}
                                               variant={CardsListVariant.List}
                                               className='ExhibitionOverview__pastExhibitionList'
                                               animateChildren={true}
                                               model={{
                                                   alwaysExpanded: true,
                                                   cards: model.pastExhibitions,
                                                   title: translations.pastExhibitions,
                                                   showFeatured: false,
                                                   hideLink: false
                                               }}/>;
        return (<>
            {exhibitionCards}
            <div className="ExhibitionOverview__pastExhibitions">
                <div>
                    {pastExhibitionCards}
                    {model.pastExhibitions.length !== 0 &&
                    <div className="ExhibitionOverview__buttonContainer scrollObserver">
                        <a className="button--secondary " href={model.archiveUrl}>
                            {translations.archiveLinkText}
                        </a>
                    </div>}
                </div>
            </div>
        </>);
    }
}
