import React from "react";
import { useEffect, useRef, useState } from "react";
import { Collapse } from "react-collapse";
import cn from "classnames";

interface AccordionProps {
    accordionList: AccordionItemProps[];
    accordionTitle: string;
}

export interface AccordionItemProps {
    title: string;
    body: string;
    isDefaultExpanded: boolean;
}

interface AccordionElementProps {
    accordionItem: AccordionItemProps;
    index: number;
}

export const EcomAccordion = ({ accordionList, accordionTitle }: AccordionProps) => {
    return (
        <div className="EcomAccordion">
            <h2 className="EcomAccordion__title">{accordionTitle}</h2>
            <hr aria-hidden="true" className="EcomAccordion__line" />
            {accordionList.map((accordionItem, i) => (
                <AccordionItem key={i} accordionItem={accordionItem} index={i} />
            ))}
        </div>
    );
};

const AccordionItem = ({ accordionItem, index }: AccordionElementProps) => {
    const [isOpen, setIsOpen] = useState(accordionItem.isDefaultExpanded);

    const collapseRef = useRef<HTMLDivElement>(null);
    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="EcomAccordion__item">
            <button
                onClick={handleClick}
                className={cn("EcomAccordion__item__header", { "EcomAccordion__item__header--open": isOpen })}
                aria-expanded={isOpen}
                aria-controls={"accordion-section-" + accordionItem.title.replace(/ /g, "-") + index}
                id={"accordion-id-" + accordionItem.title.replace(/ /g, "-") + index}
            >
                <div className="EcomAccordion__item__title">{accordionItem.title}</div>
                <span className="EcomAccordion__item__icon"></span>
            </button>
            <Collapse isOpened={isOpen}>
                <div
                    id={"accordion-section-" + accordionItem.title.replace(/ /g, "-") + index}
                    aria-labelledby={"accordion-id-" + accordionItem.title.replace(/ /g, "-") + index}
                    className={cn("EcomAccordion__item__child", { "EcomAccordion__item__child--closed": !isOpen })}
                    ref={collapseRef}
                    dangerouslySetInnerHTML={{ __html: accordionItem.body }}
                ></div>
            </Collapse>
            <hr aria-hidden="true" className="EcomAccordion__line" />
        </div>
    );
};
