import useEvent from './useEvent.hook';

const isEscape = (event: KeyboardEvent | undefined) =>
  event && (event.key === 'Escape' || event.which === 27);

const useEscape = (callback: () => void) => {
  useEvent('keydown', (e: KeyboardEvent) => {
    if (isEscape(e)) {
      callback();
    }
  });
};

export default useEscape;