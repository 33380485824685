import * as React from "react";
import { EventCalendarProps } from "./EventCalendarProps.csharp";
import { ICardViewModel } from "../../../Partials/Card/ICardViewModel.csharp";
import { HappeningsPaginationModel } from "./HappeningsPaginationModel.csharp";
import { EventCalendarFilters, defaultSelectValue } from "./EventCalendarFilters";
import { EventsList } from "./EventsList";
import { ExhibitionsList } from "./ExhibitionsList";
import { Option } from "react-dropdown";

export interface EventCalendarState {
    currentCategory: string;
    isLoading: boolean;
    events: ICardViewModel[];
    exhibitions: ICardViewModel[];
    currentPageNumber: number;
    hasNextPage: boolean;
    pageSize: number;
    currentDate: Date;
    totalCount: number;
    isSearching: boolean;
    categoryLabel: string
}

export class EventCalendar extends React.Component<EventCalendarProps, EventCalendarState> {
    constructor(props: EventCalendarProps) {
        super(props);

        this.state = {
            currentCategory: "",
            isLoading: false,
            events: props.model.happenings.events.items,
            exhibitions: props.model.happenings.exhibitions.items,
            hasNextPage: props.model.happenings.events.hasNext,
            currentPageNumber: props.model.happenings.events.pageNumber,
            pageSize: props.model.happenings.events.pageSize,
            currentDate: new Date(),
            totalCount: props.model.happenings.events.totalCount,
            isSearching: false,
            categoryLabel: ""
        };

        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        let selectedCategory = urlParams.get("category");
        const {
            model: { categories },
        } = this.props;

        const categoryExists = categories.some((c) => c.value === selectedCategory);
        if (categoryExists) {
            selectedCategory = selectedCategory.charAt(0).toUpperCase() + selectedCategory.substr(1);
        } else {
            selectedCategory = "";
        }
        this.setState({ currentCategory: selectedCategory });
    }

    render() {
        const {
            translations,
            model: { categories, allExhibitionsUrl, title },
            profile,
        } = this.props;
        const { events, currentCategory, currentDate, isLoading, exhibitions, hasNextPage, totalCount, isSearching, categoryLabel } = this.state;
        return (
            <div className="EventCalendar">
                <EventCalendarFilters
                    translations={translations}
                    categories={categories}
                    currentCategory={currentCategory}
                    currentDate={currentDate}
                    onCategoryChange={this.handleCategoryChange}
                    onDateChange={this.handleDateChange}
                    isLoading={isLoading}
                    title={title}
                    isSearching={isSearching}
                    localization={this.props.calendarLocalization}
                    totalResults={totalCount}
                    categoryLabel={categoryLabel}
                />
                <div className="EventCalendar__container">
                    <div className="EventCalendar__content">
                        <h2 className="EventCalendar__contentTitle">{translations.events}</h2>
                        <EventsList
                            events={events}
                            translations={translations}
                            profile={profile}
                            isLoading={isLoading}
                            hasNextPage={hasNextPage}
                            loadMore={this.loadMore}
                            pageSize={this.state.pageSize}
                            totalCount={totalCount}
                        />
                    </div>
                    <div className="EventCalendar__side">
                        <h3 className="EventCalendar__contentTitle">{translations.exhibitions}</h3>
                        <ExhibitionsList
                            profile={profile}
                            translations={translations}
                            exhibitions={exhibitions}
                            allExhibitionsUrl={allExhibitionsUrl}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private handleCategoryChange(selectedOption: Option): void {
        this.setState({ currentCategory: selectedOption.value });
        this.setState({ categoryLabel: selectedOption.label.toString() })
        console.log(selectedOption.label.toString())
        this.setState({ isSearching: true })
        this.loadResults(selectedOption.value, this.state.currentDate, 1);
    }

    private loadMore() {
        this.loadResults(this.state.currentCategory, this.state.currentDate, this.state.currentPageNumber + 1);
    }

    private handleDateChange(date: Date) {
        this.setState({ currentDate: date });
        this.setState({ isSearching: true });
        this.loadResults(this.state.currentCategory, date, 1);
    }

    private loadResults(category: string, date: Date, pageNumber: number) {
        this.setState({
            isLoading: true,
        });

        const fetchCategory = category == defaultSelectValue ? "" : category;

        const url = this.buildUrl(fetchCategory, date, pageNumber);

        fetch(url, { credentials: "same-origin" })
            .then(
                (response) => response.json(),
                (error) => {
                    this.setState({ isLoading: false });
                }
            )
            .then((result) => result as HappeningsPaginationModel)
            .then((result) =>
                this.setState({
                    events: pageNumber === 1 ? result.events.items : [...this.state.events, ...result.events.items],
                    exhibitions:
                        pageNumber === 1
                            ? result.exhibitions.items
                            : [...this.state.exhibitions, ...result.exhibitions.items],
                    currentPageNumber: result.events.pageNumber,
                    hasNextPage: result.events.hasNext,
                    currentCategory: category,
                    currentDate: date,
                    isLoading: false,
                    totalCount: result.events.totalCount
                })
            );

        // Update URL with querystring parameters
        if (category !== "all") {
            const baseUrl = window.location.href.split("?")[0];
            const pageUrl = `${baseUrl}?category=${category}`;
            history.pushState("", "", pageUrl);
        } else {
            const baseUrl = window.location.href.split("?")[0];
            history.pushState("", "", baseUrl);
        }
    }

    private buildUrl(category: string, date: Date, pageNumber: number) {
        return (
            this.props.endpointUrl +
            `?eventCalendarId=${this.props.model.id}&category=${category}&pageSize=${this.state.pageSize
            }&pageNumber=${pageNumber}&date=${date.toISOString().split("T")[0]}`
        );
    }
}
