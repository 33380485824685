import * as React from "react";
import { usePopperTooltip } from "react-popper-tooltip";

export interface AudioGuideProps {
    html: string;
    audioGuideLeadText: string;
    hideAudioGuideIcon: boolean;
}

export const AudioGuide: React.FC<AudioGuideProps> = ({ html, audioGuideLeadText, hideAudioGuideIcon }) => {
    const [controlledVisible, setControlledVisible] = React.useState(false);
    const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
        trigger: "click",
        closeOnOutsideClick: true,
        visible: controlledVisible,
        onVisibleChange: setControlledVisible,
    });
    
    return (
        <div className="AG">
            <div className="AudioGuide__container" ref={setTriggerRef}>
                {hideAudioGuideIcon ? <div aria-hidden="true"></div> : <div className="AudioGuide-icon"></div>}
                {audioGuideLeadText}
                <div
                    className="AudioGuide__trigger"
                    onClick={(event) => {
                        event.preventDefault();
                    }}
                ></div>
            </div>

            {visible && (
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({
                        className:
                            "tooltip-container AudioGuide-tooltip-container AudioGuide popover-arrow-container AudioGuide__popover",
                    })}
                >
                    <div {...getArrowProps({ className: "AudioGuide-tooltip-arrow" })} />
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                    <div
                        className="close-icon"
                        onClick={() => {
                            setControlledVisible(!controlledVisible);
                            event.preventDefault();
                        }}
                    ></div>
                </div>
            )}
        </div>
    );
};
