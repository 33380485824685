import React from "react";
import { useState, useEffect } from "react";
import { Collapse } from "react-collapse";
import cn from "classnames";
import { Input } from "../../Shared/Input/Input";
import { Loader } from "../../Shared/Loader";
import { CartTotals } from "../CartTotals/CartTotals";

interface AddDiscountsProps {
    subTotal?: string | number;
    grandTotal?: string | number;
    props: {
        linkText: string;
        endpointDiscountCodeGuest: string;
        endpointDiscountCodeMember: string;
        endpointVoucherCodeGuest: string;
        endpointVoucherCodeMember: string;
        discountCodePlaceholder: string;
        addDiscountCodeButtonText: string;
        removeDiscountCodeButtonText: string;
        discountCodeEmptyFieldValidationText: string;
        discountCodeErrorMessage: string;
        endpointVoucherClearGuest: string;
        endpointVoucherClearMember: string;
        endpointClearDiscountCouponsGuest: string;
        endpointClearDiscountCouponsMember: string;
    };
    setCurrentDiscount: React.Dispatch<React.SetStateAction<string>>;
    currentDiscount: string;
    setDiscount: React.Dispatch<React.SetStateAction<{ label: string; value: string }>>;
    discount: {
        label: string;
        value: string;
    };
    setSubTotal: React.Dispatch<React.SetStateAction<string | number>>;
    paymentInfoGuestEndpoint: string;
    paymentInfoMemberEndpoint: string;
    options: any;
    memberHeaders: any;
    guestHeaders: any;
    accessToken: string;
    setCurrentVoucher: React.Dispatch<React.SetStateAction<string>>;
    currentVoucher: string;
    setVoucher: React.Dispatch<React.SetStateAction<{ label: string; value: string }>>;
    voucher: {
        label: string;
        value: string;
    };
    setCurrentDiscountCoupon: React.Dispatch<React.SetStateAction<string>>;
    currentDiscountCoupon: string;
    setDiscountCoupon: React.Dispatch<React.SetStateAction<{ label: string; value: string }>>;
    discountCoupon: {
        label: string;
        value: string;
    };
    grandTotalWithoutShipping: (data: any) => void;
    currencyFormat?: string;
}

export const AddDiscounts = ({
    props,
    setCurrentDiscount,
    currentDiscount,
    setDiscount,
    discount,
    setSubTotal,
    paymentInfoGuestEndpoint,
    paymentInfoMemberEndpoint,
    options,
    memberHeaders,
    guestHeaders,
    accessToken,
    setCurrentVoucher,
    currentVoucher,
    setVoucher,
    voucher,
    grandTotalWithoutShipping,
    subTotal,
    grandTotal,
    currencyFormat,
    setCurrentDiscountCoupon,
    currentDiscountCoupon,
    setDiscountCoupon,
    discountCoupon,
}: AddDiscountsProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [input, setInput] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const getSubTotal = (data) => {
        setSubTotal(data.totals.total_segments.find((item) => item.code === "subtotal").value);
    };

    const collapseRef = React.useRef<HTMLDivElement>(null);
    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (newValue: string) => {
        setInput(newValue);
        setError("");
    };
    const discountGuestEndpoint = props.endpointDiscountCodeGuest;
    const discountMemberEndpoint = props.endpointDiscountCodeMember;
    const voucherGuestEndpoint = props.endpointVoucherCodeGuest;
    const voucherMemberEndpoint = props.endpointVoucherCodeMember;
    const clearVoucherEndpointGuest = props.endpointVoucherClearGuest;
    const clearVoucherEndpointMember = props.endpointVoucherClearMember;

    const addDiscount = async (input: string) => {
        setIsLoading(true);

        const body = {
            no: input,
        };

        const addVoucherOptions = {
            method: "POST",
            headers: accessToken ? memberHeaders : guestHeaders,
            body: JSON.stringify(body),
        };

        const obj = { code: input };

        const addDiscountOptions = {
            method: "PUT",
            headers: accessToken ? memberHeaders : guestHeaders,
        };

        const isVoucher = /^\d+$/.test(input);

        if (isVoucher) {
            const responseVoucher = await fetch(
                accessToken ? voucherMemberEndpoint : voucherGuestEndpoint,
                addVoucherOptions
            );

            if (responseVoucher.ok) {
                const response = await fetch(
                    `${accessToken ? paymentInfoMemberEndpoint : paymentInfoGuestEndpoint}`,
                    options
                );
                const data = await response.json();

                getSubTotal(data);
                grandTotalWithoutShipping(data);
                setCurrentVoucher(data.totals.total_segments.find((item) => item.code === "voucher").title);
                setVoucher({
                    ...voucher,
                    label: data.totals.total_segments.find((item) => item.code === "voucher").title,
                    value: data.totals.total_segments.find((item) => item.code === "voucher").value,
                });
                setInput("");
            } else if (obj.code === "") {
                setError(props.discountCodeEmptyFieldValidationText);
            } else {
                setError(props.discountCodeErrorMessage);
            }
        } else {
            const response = await fetch(
                `${accessToken ? discountMemberEndpoint : discountGuestEndpoint}${obj.code}`,
                addDiscountOptions
            );

            if (response.ok) {
                const response = await fetch(
                    `${accessToken ? paymentInfoMemberEndpoint : paymentInfoGuestEndpoint}`,
                    options
                );
                const data = await response.json();
                const discountTitle = data.totals.coupon_code
                    ? data.totals.coupon_code
                    : data.totals.total_segments.find((item) => item.code === "discount")?.title;
                const discountCouponTitle = data.totals.total_segments.find(
                    (item) => item.code === "discount_coupon"
                )?.title;

                getSubTotal(data);
                grandTotalWithoutShipping(data);

                const hasDiscounts = data.totals.total_segments.find((item) => item.code === "discount") !== undefined;
                const hasDiscountCoupon =
                    data.totals.total_segments.find((item) => item.code === "discount_coupon") !== undefined;
                if (hasDiscounts || hasDiscountCoupon) {
                    setDiscount({
                        ...discount,
                        label: discountTitle,
                        value: data.totals.total_segments.find((item) => item.code === "discount")?.value,
                    });
                    setCurrentDiscount(discountTitle);
                    setDiscountCoupon({
                        ...discountCoupon,
                        label: discountCouponTitle,
                        value: data.totals.total_segments.find((item) => item.code === "discount_coupon")?.value,
                    });
                    setCurrentDiscountCoupon(discountCouponTitle);
                } else {
                    setDiscount({
                        ...discount,
                        label: "",
                        value: "",
                    });
                    setDiscountCoupon({
                        ...discountCoupon,
                        label: "",
                        value: "",
                    });
                }
                setInput("");
            } else if (obj.code === "") {
                setError(props.discountCodeEmptyFieldValidationText);
            } else {
                setError(props.discountCodeErrorMessage);
            }
        }

        setIsLoading(false);
    };

    const deleteDiscount = async (isVoucher: boolean, isDiscountCoupon: boolean) => {
        setIsLoading(true);
        const deleteOptions = {
            method: isVoucher || isDiscountCoupon ? "POST" : "DELETE",
            headers: accessToken ? memberHeaders : guestHeaders,
        };

        const response = await fetch(
            `${
                !isVoucher && !isDiscountCoupon && accessToken
                    ? discountMemberEndpoint
                    : !isVoucher && !isDiscountCoupon && !accessToken
                    ? discountGuestEndpoint
                    : isVoucher && accessToken
                    ? clearVoucherEndpointMember
                    : isVoucher && !accessToken
                    ? clearVoucherEndpointGuest
                    : !isVoucher && isDiscountCoupon && !accessToken
                    ? props.endpointClearDiscountCouponsGuest
                    : !isVoucher && isDiscountCoupon && accessToken
                    ? props.endpointClearDiscountCouponsMember
                    : null
            }`,
            deleteOptions
        );

        if (response.ok) {
            const response = await fetch(
                `${accessToken ? paymentInfoMemberEndpoint : paymentInfoGuestEndpoint}`,
                options
            );
            const data = await response.json();

            getSubTotal(data);
            grandTotalWithoutShipping(data);

            if (!isVoucher && !isDiscountCoupon) {
                const hasDiscounts = data.totals.total_segments.find((item) => item.code === "discount") !== undefined;
                if (hasDiscounts) {
                    setDiscount({
                        ...discount,
                        label: data.totals.total_segments.find((item) => item.code === "discount").title,
                        value: data.totals.total_segments.find((item) => item.code === "discount").value,
                    });
                    setCurrentDiscount("");
                } else {
                    setDiscount({
                        ...discount,
                        label: "",
                        value: "",
                    });
                    setCurrentDiscount("");
                }
            } else if (isDiscountCoupon) {
                const hasCouponDiscounts =
                    data.totals.total_segments.find((item) => item.code === "discount_coupon") !== undefined;
                if (hasCouponDiscounts) {
                    setDiscountCoupon({
                        ...discountCoupon,
                        label: data.totals.total_segments.find((item) => item.code === "discount_coupon").title,
                        value: data.totals.total_segments.find((item) => item.code === "discount_coupon").value,
                    });
                    setCurrentDiscountCoupon("");
                } else {
                    setDiscountCoupon({
                        ...discountCoupon,
                        label: "",
                        value: "",
                    });
                    setCurrentDiscountCoupon("");
                }
            } else {
                const hasVoucher = data.totals.total_segments.find((item) => item.code === "voucher") !== undefined;
                if (hasVoucher && !isVoucher) {
                    setVoucher({
                        ...voucher,
                        label: data.totals.total_segments.find((item) => item.code === "voucher").title,
                        value: data.totals.total_segments.find((item) => item.code === "voucher").value,
                    });
                } else {
                    setVoucher({
                        ...voucher,
                        label: "",
                        value: "",
                    });
                    setCurrentVoucher("");
                }
            }

            setInput("");
        } else {
            setError(props.discountCodeErrorMessage);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (subTotal && discount.value && discount.label !== "") {
            setIsOpen(true);
        }
    }, [discount.value, subTotal, grandTotal, discountCoupon]);

    return (
        <div className="AddDiscounts">
            <button
                onClick={handleClick}
                className={cn("AddDiscounts__header", { "AddDiscounts__header--open": isOpen })}
                aria-expanded={isOpen}
                aria-controls={"discounts-accordion-section-" + props.linkText.replace(/ /g, "-")}
                id={"discounts-accordion-id-" + props.linkText.replace(/ /g, "-")}
            >
                <div className="AddDiscounts__title">{props.linkText}</div>
                <span className="AddDiscounts__icon"></span>
            </button>
            <Collapse isOpened={isOpen}>
                <div
                    id={"discounts-accordion-section-" + props.linkText.replace(/ /g, "-")}
                    aria-labelledby={"discounts-accordion-id-" + props.linkText.replace(/ /g, "-")}
                    className={cn("AddDiscounts__child", { "AddDiscounts__child--closed": !isOpen })}
                    ref={collapseRef}
                >
                    <form className="AddDiscounts__input">
                        <Input
                            type="text"
                            isRequired={true}
                            onChange={handleChange}
                            value={input}
                            isBig={false}
                            placeholder={props.discountCodePlaceholder}
                            autoComplete=""
                            showError={error !== ""}
                            errorValidation={error !== ""}
                            errorValidationText={error}
                        ></Input>
                        <button
                            className="AddDiscounts__add-button button--primary"
                            onClick={(e) => {
                                e.preventDefault();
                                addDiscount(input);
                            }}
                        >
                            {props.addDiscountCodeButtonText}
                        </button>
                    </form>

                    {!isLoading ? (
                        <>
                            {currentDiscount && (
                                <button
                                    className="AddDiscounts__delete-button button--primary"
                                    onClick={() => {
                                        deleteDiscount(false, false);
                                    }}
                                    aria-label={props.removeDiscountCodeButtonText}
                                >
                                    {currentDiscount}
                                    <span className="AddDiscounts__delete-button-icon"></span>
                                </button>
                            )}
                            {currentDiscountCoupon && (
                                <button
                                    className="AddDiscounts__delete-button button--primary"
                                    onClick={() => {
                                        deleteDiscount(false, true);
                                    }}
                                    aria-label={props.removeDiscountCodeButtonText}
                                >
                                    {currentDiscountCoupon}
                                    <span className="AddDiscounts__delete-button-icon"></span>
                                </button>
                            )}
                            {currentVoucher && (
                                <button
                                    className="AddDiscounts__delete-button button--primary"
                                    onClick={() => {
                                        deleteDiscount(true, false);
                                    }}
                                    aria-label={props.removeDiscountCodeButtonText}
                                >
                                    {currentVoucher}
                                    <span className="AddDiscounts__delete-button-icon"></span>
                                </button>
                            )}
                        </>
                    ) : (
                        <Loader isSmall />
                    )}
                    {subTotal && (
                        <CartTotals
                            discount={discount}
                            voucher={voucher}
                            discountCoupon={discountCoupon}
                            subTotal={subTotal}
                            grandTotal={grandTotal}
                            subTotalLabel="Subtotal"
                            totalLabel="Total"
                            currencyFormat={currencyFormat && currencyFormat}
                        />
                    )}
                </div>
            </Collapse>
        </div>
    );
};
