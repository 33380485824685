import * as React from "react";
import {formatDate} from "../DateUtil";
import Calendar from "./Calendar";
import {CalendarLocalization} from "./CalendarLocalization.csharp";

export interface CalendarPickerProps {
    selectedValue: Date;
    onDateChange: (value: Date) => void;
    disabled?: boolean
    localization: CalendarLocalization;
    dateInputPlaceholder: string;
}

export interface CalendarPickerState {
    showOverlay: boolean;
    selectedDayInputValue: string;
}

export default class CalendarPicker extends React.Component<CalendarPickerProps, CalendarPickerState> {
    private readonly wrapperRef = React.createRef<HTMLDivElement>();

    constructor(props: CalendarPickerProps) {
        super(props);

        this.onInputFocus = this.onInputFocus.bind(this);
        this.onCalendarBlur = this.onCalendarBlur.bind(this);
        this.wrapperRef = React.createRef();
        this.onClickOutside = this.onClickOutside.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onDateChange = this.onDateChange.bind(this);

        this.state = {
            showOverlay: false,
            selectedDayInputValue: ''
        };
    }

    componentDidMount() {
        document.addEventListener('click', this.onClickOutside);
        document.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickOutside);
        document.removeEventListener('keydown', this.onKeyDown);
    }
    
    render() {
        return (
            <div className="CalendarPicker" ref={this.wrapperRef}>
                <div className="CalendarPicker__inputContainer">
                    <input className="CalendarPicker__input"
                           value={this.state.selectedDayInputValue}
                           readOnly
                           disabled={this.props.disabled}
                           onClick={this.onInputFocus}
                           onKeyDown={this.onKeyDown}
                           placeholder={this.props.dateInputPlaceholder}
                    />
                </div>
                {this.state.showOverlay && <Calendar
                    selectedDate={this.props.selectedValue}
                    onDateChange={this.onDateChange}
                    onBlur={this.onCalendarBlur}
                    localization={this.props.localization}                    
                />}
            </div>
        );
    }

    private readonly onDateChange = (date: Date) => {
        this.props.onDateChange(date);
        
        this.setState({
            showOverlay: false,
            selectedDayInputValue: `${formatDate(date, this.props.localization.cultureName)}`
        })
    };

    private readonly onInputFocus = () => {
        this.setState({
            showOverlay: true
        })
    };

    private readonly onCalendarBlur = () => {
        this.setState({
            showOverlay: false
        })
    };

    private readonly onClickOutside = (event: any) => {
        const node = this.wrapperRef.current;

        if (node && !node.contains(event.target as HTMLElement)) {
            this.setState({
                showOverlay: false
            })
        }
    };

    private readonly onKeyDown = (event: any) => {
        if (event.key === 'Escape') {
            this.setState({
                showOverlay: false
            })
        }
        
        if (event.key === 'Enter') {
            this.setState({
                showOverlay: true
            })
        }
    }
}