import React from "react";
import { ICategory } from "../CategoryPage";

interface IHeader {
    name: string;
    subCategories: ICategory[];
    urlPath: string;
    ecomPageBaseUrl: string;
    ecomPageBaseText: string;
}

export const Header = ({ name, subCategories, urlPath, ecomPageBaseUrl, ecomPageBaseText  }: IHeader) => {
    const parents = urlPath && urlPath.split("/").slice(0, -1);
    return (
        <section className="Header__Container">
            <div className="Header__Background"></div>
            <div className="Header__Wrapper">
                <ul className="Header__BreadCrumb">
                    <li>
                        {ecomPageBaseUrl && (
                            <a href={ecomPageBaseUrl}>
                                {parents.length === 0 && <span className="Header__BreadCrumb--single" />}
                                {ecomPageBaseText}
                            </a>
                        )}
                    </li>
                    {parents.length !== 0 &&
                        parents.map((parent, i) => {
                            const url = parents.slice(0, i + 1).join("/");
                            <li key={parent}>
                                <a href={url}>{parent}</a>
                            </li>
                        })}
                </ul>
                <h1 className="Header__Title">{name}</h1>
                {subCategories && subCategories.length > 0 && (
                    <ul className="Header__SubCategories__container">
                        {subCategories.map((child) => (
                            <li key={child.id}>
                                <a href={child.urlPath}>{child.name}</a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </section>
    );
};
