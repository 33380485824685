import React, { useState, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import Modal from "../../../../Partials/Modal/Modal";
import { EditPersonalInfo } from "../../Shared/EditPersonalInfo/EditPersonalInfo";
import { IEditPersonalInfo } from "../../Shared/EditPersonalInfo/EditPersonalInfo";
import { postRequest } from "../../Shared/api/post";

export interface IPersonalInfo {
    token: string;
    title?: string;
    firstName: string;
    lastName: string;
    changeButtonText?: string;
    emailLabel?: string;
    email: string;
    nameLabel?: string;
    phoneNumberLabel?: string;
    phoneNumber: string;
    postalCodeAndCityLabel?: string;
    streetAddressLabel?: string;
    streetAddress: string;
    postalCode: string;
    city: string;
    modalCloseButtonText?: string;
    modalContentLabel?: string;
    changeCustomerInformation: IEditPersonalInfo;
    graphQlUrl: string;
    disableEditButton: boolean;
    id?: string;
    countryCode: string;
}

const PersonalInfo = ({
    token,
    title,
    changeButtonText,
    emailLabel,
    email,
    nameLabel,
    firstName,
    lastName,
    phoneNumberLabel,
    phoneNumber,
    postalCodeAndCityLabel,
    postalCode,
    streetAddressLabel,
    streetAddress,
    city,
    modalCloseButtonText,
    modalContentLabel,
    changeCustomerInformation,
    graphQlUrl,
    disableEditButton,
    id,
    countryCode
}: IPersonalInfo) => {
    const [memberInfo, setMemberInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        streetAddress: "",
        postalCode: "",
        city: "",
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const handleClose = () => {
        setIsEditing(false);
        setIsLoading(false);
    };

    useEffect(() => {
        setMemberInfo({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            streetAddress: streetAddress,
            postalCode: postalCode,
            city: city,
        });
    }, [firstName, lastName, email, phoneNumber, streetAddress, postalCode]);
    const updatePersonalInfo = async (data) => {
        
        const dataPersonalInfo = JSON.stringify({
            address: {
                address: data.streetAddress,
                city: data.city,
                postcode: data.postalCode,
                country_code: countryCode,
                email: data.email,
                telephone: data.phoneNumber,
                person: {
                    name: data.firstName + " " + data.lastName
                }
            }
        });
        setIsLoading(true);
        try {
            const endpoint = changeCustomerInformation.endpointSetAddress + id;
            const responseUpdatePersonalInfo = await postRequest(endpoint, dataPersonalInfo, token);
            const dataUpdatePersonalInfo = await responseUpdatePersonalInfo.json();

            if(JSON.stringify(dataUpdatePersonalInfo) === JSON.stringify(['success'])){
            setMemberInfo({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                streetAddress: data.streetAddress,
                postalCode: data.postalCode,
                city:  data.city,
            });}
            setIsLoading(false);
            setIsEditing(false);

        } catch (error) {
            setIsLoading(false);
            setServerError(true);
            console.log(error);
        }
    };

    const handleFormSubmit = (data) => {
        if (JSON.stringify(data) !== JSON.stringify(memberInfo)) {
            updatePersonalInfo(data);
        }else{
            setIsEditing(false);
        }
    };
    return (
        <div className="PersonalInfo">
            <div className="PersonalInfo__inner">
                <div className="PersonalInfo__column">{title && <h2 className="PersonalInfo__title">{title}</h2>}</div>
                <div className="PersonalInfo__column">
                    <dl className="PersonalInfo__items">
                        <dt className="PersonalInfo__item">{nameLabel}</dt>
                        <dd className="PersonalInfo__value">
                            {memberInfo.firstName} {memberInfo.lastName}
                        </dd>

                        <dt className="PersonalInfo__item">{emailLabel}</dt>
                        <dd className="PersonalInfo__value">{memberInfo.email}</dd>

                        <dt className="PersonalInfo__item">{phoneNumberLabel}</dt>
                        <dd className="PersonalInfo__value">{memberInfo.phoneNumber}</dd>

                        <dt className="PersonalInfo__item">{streetAddressLabel}</dt>
                        <dd className="PersonalInfo__value">{memberInfo.streetAddress}</dd>

                        <dt className="PersonalInfo__item">{postalCodeAndCityLabel}</dt>
                        <dd className="PersonalInfo__value">
                            {memberInfo.postalCode} {memberInfo.city}
                        </dd>
                    </dl>

                    <button
                        className="PersonalInfo__button button--secondary"
                        onClick={() => {
                            setIsEditing(true);
                        }}
                        disabled={disableEditButton}
                    >
                        {changeButtonText}
                    </button>
                </div>

                <Modal
                    isOpen={isEditing}
                    contentLabel={modalContentLabel}
                    closeButtonLabel={modalCloseButtonText}
                    onClose={handleClose}
                >
                    <EditPersonalInfo
                        {...changeCustomerInformation}
                        memberData={memberInfo}
                        isLoading={isLoading}
                        showErrorMessage={serverError}
                        onFormSubmit={handleFormSubmit}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default PersonalInfo;
