import classNames from "classnames";
import React from "react";

export const MessageBox = ({ text, red }: { text: string | string[]; red?: boolean }) => {
    return <div className={classNames("MessageBox", { "MessageBox--red": red })}>
        {Array.isArray(text)
            ? text.map((paragraph, index) => <p key={index}>{paragraph}</p>)
            : text && <p>{text}</p>}
    </div>;
};
