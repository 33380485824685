import React, { useState, useRef, ReactNode, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import classNames from "classnames";

interface ICarousel {
    displayData: { key: string; data: ReactNode }[];
    previousSlideAriaLabel: string;
    nextSlideAriaLabel: string;
    spaceBetweenSlides?: number;
    isOrangeButtons?: boolean
    isMemberTickets?: boolean
}

export const Carousel = ({
    displayData,
    previousSlideAriaLabel,
    nextSlideAriaLabel,
    spaceBetweenSlides = 20,
    isOrangeButtons = false,
    isMemberTickets = false
}: ICarousel) => {
    const swiperRef = useRef<SwiperType>();
    const [hideLeftButton, setHideLeftButton] = useState(true);
    const [hideRightButton, setHideRightButton] = useState(false);
    const [centerSlides, setCenterSlides] = useState(false);

    const hideButtons = () => {
        if (swiperRef.current) {
            setHideLeftButton(swiperRef.current.isBeginning);
            setHideRightButton(swiperRef.current.isEnd);
        }
    };
    useEffect(() => {
        if (displayData.length < 4){
            setHideRightButton(true);
            setCenterSlides(true);
        }
    }, []);

    if (displayData.length === 0) return null;
    return (
        <div className="Carousel">
            <button
                className={classNames("Carousel__Swiper__Button Carousel__Swiper__Button--left", {
                    "Carousel__Swiper__Button--hidden": hideLeftButton,
                    "Carousel__Swiper__Button--orange": isOrangeButtons
                })}
                onClick={() => {
                    swiperRef.current?.slidePrev();
                    hideButtons();
                }}
                aria-label={previousSlideAriaLabel}
            />

            <button
                className={classNames("Carousel__Swiper__Button Carousel__Swiper__Button--right", {
                    "Carousel__Swiper__Button--hidden": hideRightButton,
                    "Carousel__Swiper__Button--orange": isOrangeButtons
                })}
                onClick={() => {
                    swiperRef.current?.slideNext();
                    hideButtons();
                }}
                aria-label={nextSlideAriaLabel}
            />

            <div className={classNames("Carousel__Items", {"Carousel__Items--center-slides": centerSlides && isMemberTickets})}>
                <Swiper
                    slidesPerView={"auto"}
                    breakpoints={{
                        1023: { spaceBetween: spaceBetweenSlides, allowTouchMove: false},
                    }}
                    allowTouchMove={true}
                    spaceBetween={16}
                    className="mySwiper"
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                >
                    {displayData &&
                        displayData.length > 0 &&
                        displayData.map((item, index) => <SwiperSlide className={classNames("Carousel__Slide", {"Carousel__Slide--no-margin": centerSlides && index === displayData.length - 1 && isMemberTickets})} key={item.key}>{item.data}</SwiperSlide>)}
                </Swiper>
            </div>
        </div>
    );
};
