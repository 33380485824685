import * as React from "react";

interface CollectionSearchRangeInputProps {
    left: boolean;
    minYear: number;
    maxYear: number;
    years: [number, number];
    onChange: (years: [number, number], fromInput: boolean) => void;
}

export const CollectionSearchRangeInput = (props: CollectionSearchRangeInputProps) => {
    const initialValue = props.left ? props.years[0] : props.years[1];
    const [value, setValue] = React.useState(initialValue.toString());
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setValue((props.left ? props.years[0] : props.years[1]).toString());
    }, [props.years]);

    const validate = (val: string): string => {
        const regex = /^[-0-9]+$/;
        const n = parseInt(val);

        if (!regex.test(val) || isNaN(n)) {
            return "skriv inn et gyldig årstall";
        }

        if (n < props.minYear) {
            return `${n} < ${props.minYear}`;
        }

        if (n > props.maxYear) {
            return `${n} > ${props.maxYear}`;
        }

        const [from, to] = props.years;

        if (props.left && n > to) {
            return `${n} > ${to} (left > right)`;
        }

        if (!props.left && n < from) {
            return `${n} < ${from} (right < left)`;
        }

        return null;
    };

    const onYearsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        const err = validate(val);
        setValue(val);
        setError(err);
        if (!err) {
            const n = parseInt(val);
            const [from, to] = props.years;
            const years: [number, number] = props.left ? [n, to] : [from, n];
            props.onChange(years, true);
        }
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (error) {
            const [from, to] = props.years;
            const restored = props.left ? from : to;
            setValue(restored.toString());
            setError(null);
        }
    };

    return (
        <>
            <input style={{ textAlign: "center" }} type="text" value={value} onChange={onYearsChange} onBlur={onBlur} />
            {error && <p style={{ color: "red", fontSize: "15px" }}>{error}</p>}
        </>
    );
};
