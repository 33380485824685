import React, { useState } from "react";
import { IProduct } from "../../Product/ProductPage";
import { ProductCard } from "../../Shared/ProductCard";
interface IProductList {
    changeSort: (sortValue: string) => void;
    editorialProps: {
        currencyFormat: string;
        sortingOptions: { displayText: string; keyword: string }[];
        productPageLinkUrl: string;
        productList: IProduct[];
        sortingButtonText: string;
        showProductsText: string;
    };
}

export const ProductList = ({ changeSort, editorialProps }: IProductList) => {
    const [selectedSort, setSelectedSort] = useState<string>(editorialProps.sortingButtonText);
    const numberOfProductsArray = editorialProps.showProductsText.split("{0}");
    return (
        <section className="ProductList__Container">
            <div className="ProductList__header">
                {numberOfProductsArray.length > 0 && (
                    <p>
                        {numberOfProductsArray[0]}
                        <strong>{editorialProps?.productList.length ?? 0}</strong>
                        {numberOfProductsArray[1]}
                    </p>
                )}

                {editorialProps.sortingOptions && (
                    <div className="ProductList__Sort__container">
                        <select
                            className="ProductList__Sort"
                            onChange={(e) => {
                                setSelectedSort(e.target.value);
                                changeSort(e.target.value);
                            }}
                            defaultValue=""
                        >
                            <option value="" disabled hidden>
                                {editorialProps.sortingButtonText}
                            </option>
                            {editorialProps.sortingOptions.map((option) => (
                                <option
                                    key={option.keyword}
                                    className="ProductList__Sort__option"
                                    value={option.keyword}
                                >
                                    {option.displayText}
                                </option>
                            ))}
                        </select>
                        <div className="fake-select" aria-hidden={true}>
                            {editorialProps.sortingOptions.find((obj) => obj.keyword === selectedSort)?.displayText ??
                                selectedSort}
                        </div>
                    </div>
                )}
            </div>
            <ul className="ProductList__ItemList">
                {editorialProps.productList &&
                    editorialProps.productList.length > 0 &&
                    editorialProps.productList.map((product, i) => {
                        return (
                            <li key={product.productName + i}>
                                <ProductCard
                                    product={{
                                        url: editorialProps.productPageLinkUrl + product.urlKey,
                                        imageAlt: product?.images[0]?.altText,
                                        imageUrl: product?.images[0]?.url,
                                        title: product.productName,
                                        brand: product.brand,
                                        originalPrice: product.price.originalPrice,
                                        vatPrice: product.price.vatPrice,
                                    }}
                                    currencyFormat={editorialProps.currencyFormat}
                                />
                            </li>
                        );
                    })}
            </ul>
        </section>
    );
};
