export const postRequest = async (url: string, data: string, accessToken: string) => {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    if (accessToken !== null) {
        var bearer = "Bearer " + accessToken;
        headers.append("Authorization", bearer);
    }

    const requestOptions: RequestInit = {
        method: "POST",
        headers: headers,
        body: data,
        redirect: "follow",
    };

    const response = await fetch(url, requestOptions);
    return response;
};

